const reactStateDiff = (obj1, obj2) => {
  const diff = Object.keys(obj2).reduce((diff, key) => {
    if (obj1[key] !== obj2[key]) {
      diff[key] = obj2[key];
    }

    return diff;
  }, {});

  return diff;
};

const tryOrDefault = (val, defaultVal) => {
  return "undefined" === typeof val ?
    defaultVal :
    val;
};

export default {
  reactStateDiff,
  tryOrDefault
};