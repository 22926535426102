import uuidV4 from 'uuid/v4';
import ReactYoutube from 'react-youtube';

import {RoomStatics} from "../containers/room/component.statics";
import {ROOM_CLEAR_CURRENT} from '../actions/types';
import {ROOM_RESET_CURRENT} from '../actions/types';
import {ROOM_FETCH_SUGGESTIONS} from '../actions/types';
import {ROOM_UPDATE_STORE} from '../actions/types';
import {SOCKET_STATUS_CONNECTED} from '../actions/types';
import {SOCKET_STATUS_DISCONNECTED} from '../actions/types';
import {ROOM_QUEUE_TRACK} from '../actions/types';
import {ROOM_QUEUE_NEXT_TRACK} from '../actions/types';
import {ROOM_QUEUE_NEXT_TRACK_AND_PLAY} from '../actions/types';
import {ROOM_PLAY_NEXT_TRACK} from '../actions/types';
import {ROOM_REMOVE_TRACK} from '../actions/types';
import {ROOM_REMOVE_TRACK_SUGGESTION_RESULTS} from '../actions/types';
import {ROOM_UPVOTE_TRACK} from '../actions/types';
import {ROOM_DOWNVOTE_TRACK} from '../actions/types';
import {ROOM_CLEAR_TRACKS} from '../actions/types';
import {ROOM_LOGIN_SUCCESS} from '../actions/types';
import {ROOM_LOGIN_FAILURE} from '../actions/types';
import {ROOM_LOGIN_IN_PROGRESS} from '../actions/types';
import {ROOM_INVITE_GENERATE_SUCCESS} from '../actions/types';
import {ROOM_INVITE_GENERATE_FAILURE} from '../actions/types';
import {ROOM_INVITE_GENERATE_IN_PROGRESS} from '../actions/types';
import {
  ROOM_CLOSE, ROOM_EXPORT_MIX_ERROR, ROOM_EXPORT_MIX_IN_PROGRESS, ROOM_EXPORT_MIX_SUCCESS,
  ROOM_GET_TRACK_REACTIONS_INFO_CLEAR,
  ROOM_GET_TRACK_REACTIONS_INFO_FAILURE,
  ROOM_GET_TRACK_REACTIONS_INFO_IN_PROGRESS,
  ROOM_GET_TRACK_REACTIONS_INFO_SUCCESS, ROOM_SEEK_AT,
  ROOM_SET_VOLUME,
  ROOM_UPDATE_CURRENT_TRACK_PLAYBACK
} from '../actions/room.types';
import {ROOM_USER_OFFLINE} from '../actions/room.types';
import {ROOM_USER_ONLINE} from '../actions/room.types';
import {ROOM_CLOSED_BY_EVENT} from '../actions/room.types';
import {ROOM_UPDATE_CURRENT_TRACK_INDEX} from '../actions/room.types';
import {ROOM_UPDATE_IS_SYNCED_PROP} from '../actions/room.types';
import {ROOM_UPDATE_ONLY_ONE_PLAYER_PROP} from '../actions/room.types';
import {ROOM_PAUSE_PLAYBACK} from '../actions/room.types';
import {ROOM_START_PLAYBACK} from '../actions/room.types';
import {ROOM_ADD_REACTION} from '../actions/room.types';
import {ROOM_REMOVE_REACTION} from '../actions/room.types';

import RoomUtil from '../containers/room/component.util';
import {AuthConstants} from '../constants/auth';
import {RoomConstants} from '../constants/room.constants';
import JObjectUtils from '../utils/JObjectUtils';
import {NO_SEEK} from "../containers/room/component.state";

export const INVITE_CODE_LOADING = Symbol('invite-code-loading');
export const INVITE_CODE_FAILURE = Symbol('invite-code-failure');
export const TRACK_REACTION_USER_INFO_LOADING = Symbol('track-reaction-user-info-loading');
export const TRACK_REACTION_USER_INFO_FAILURE = Symbol('track-reaction-user-info-failure');
export const EMPTY_SILENT_VIDEO_ID = '-8D-dJrS1t4';

const YTPlayerStates = ReactYoutube.PlayerState;

export const PLAYLIST_NOT_LOADED = [];
const roomInitialState = {
  // Stuff that we need from REST call
  roomName: '',
  uid: '',
  type: '',
  visibility: '',
  owner: '',
  ownerName: '',
  accessLevel: AuthConstants.DFAccess,

  // Stuff that will update from websockets
  memberCount: '',
  playlist: PLAYLIST_NOT_LOADED,
  seekAt: NO_SEEK,
  volume: 100,
  currentTrackIndex: -1,
  currentTrackPlaybackInfo: {
    status: YTPlayerStates.PAUSED,
    trackElapsed: 0,
    trackDuration: 0,
    lastUpdatedAt: Date.now()
  },
  nextTrackIndex: -1,
  currentPlayerIndex: 0,
  roomState: RoomStatics.states.PLAYERS_NOT_READY,
  showClearConfirmDialog: false,
  suggestions: [],
  userSuggestions: [],
  currentOnlineUsers: [],
  socketConnState: RoomStatics.socketStates.DISCONNECTED,
  isSynced: false,
  onlyOnePlayingDevice: {
    status: RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE,
    device: '',
    username: ''
  },
  messages: {
    socketStatus: {
      persistent: false,
      error: false,
      val: '',
      triggeredBy: {
        id: '',
        name: ''
      },
      messageId: uuidV4()
    },
    playbackEvent: {
      persistent: false,
      error: false,
      val: '',
      triggeredBy: {
        id: '',
        name: ''
      },
      messageId: uuidV4()
    }
  },
  
  // API Requests
  inviteCode: INVITE_CODE_LOADING,
  suggesterCode: INVITE_CODE_LOADING,
  trackReactionsUserListRequest: TRACK_REACTION_USER_INFO_LOADING,
  exportMixRequestStatus: null
};

const initialState = roomInitialState;

const getUpdatedCurrentRoomState = (currentRoomState, payload) => {
  const playlist = JObjectUtils.tryOrDefault(payload.playlist, currentRoomState.playlist);
  const currentTrackIndex = JObjectUtils.tryOrDefault(payload.currentTrackIndex, currentRoomState.currentTrackIndex);
  const nextTrackIndex = JObjectUtils.tryOrDefault(payload.nextTrackIndex, currentRoomState.nextTrackIndex);
  const currentPlayerIndex = JObjectUtils.tryOrDefault(payload.currentPlayerIndex, currentRoomState.currentPlayerIndex);
  const roomState = JObjectUtils.tryOrDefault(payload.roomState, currentRoomState.roomState);
  const showClearConfirmDialog = JObjectUtils.tryOrDefault(payload.showClearConfirmDialog, currentRoomState.showClearConfirmDialog);
  const currentOnlineUsers = JObjectUtils.tryOrDefault(payload.currentOnlineUsers, currentRoomState.currentOnlineUsers);
  const suggestions = JObjectUtils.tryOrDefault(payload.suggestions, currentRoomState.suggestions);
  const userSuggestions = JObjectUtils.tryOrDefault(payload.userSuggestions, currentRoomState.userSuggestions);
  const socketConnState = JObjectUtils.tryOrDefault(payload.socketConnState, currentRoomState.socketConnState);
  const messages = JObjectUtils.tryOrDefault(payload.messages, currentRoomState.messages);
  const accessLevel = JObjectUtils.tryOrDefault(payload.accessLevel, currentRoomState.accessLevel);
  const roomName = JObjectUtils.tryOrDefault(payload.roomName, currentRoomState.roomName);
  const owner = JObjectUtils.tryOrDefault(payload.owner, currentRoomState.owner);
  const ownerName = JObjectUtils.tryOrDefault(payload.ownerName, currentRoomState.ownerName);
  const memberCount = JObjectUtils.tryOrDefault(payload.memberCount, currentRoomState.memberCount);
  const uid = JObjectUtils.tryOrDefault(payload.uid, currentRoomState.uid);
  const visibility = JObjectUtils.tryOrDefault(payload.visibility, currentRoomState.visibility);
  const type = JObjectUtils.tryOrDefault(payload.type, currentRoomState.type);
  const isSynced = JObjectUtils.tryOrDefault(payload.isSynced, currentRoomState.isSynced);
  const onlyOnePlayingDevice = JObjectUtils.tryOrDefault(payload.onlyOnePlayingDevice, currentRoomState.onlyOnePlayingDevice);
  const showSearchDialog = JObjectUtils.tryOrDefault(payload.showSearchDialog, currentRoomState.showSearchDialog);
  const currentTrackPlaybackInfo = JObjectUtils.tryOrDefault(payload.currentTrackPlaybackInfo, currentRoomState.currentTrackPlaybackInfo);
  const seekAt = JObjectUtils.tryOrDefault(payload.seekAt, currentRoomState.seekAt);
  const volume = JObjectUtils.tryOrDefault(payload.volume, currentRoomState.volume);

  // API Requests
  const inviteCode = JObjectUtils.tryOrDefault(payload.inviteCode, currentRoomState.inviteCode);
  const suggesterCode = JObjectUtils.tryOrDefault(payload.suggesterCode, currentRoomState.suggesterCode);
  const trackReactionsUserListRequest = JObjectUtils.tryOrDefault(
    payload.trackReactionsUserListRequest,
    currentRoomState.trackReactionsUserListRequest
  );
  const exportMixRequestStatus = JObjectUtils.tryOrDefault(payload.exportMixRequestStatus, currentRoomState.exportMixRequestStatus);

  return {
    playlist,
    currentTrackIndex,
    currentTrackPlaybackInfo,
    nextTrackIndex,
    currentPlayerIndex,
    roomState,
    showClearConfirmDialog,
    suggestions,
    currentOnlineUsers,
    userSuggestions,
    socketConnState,
    messages,
    accessLevel,
    roomName,
    owner,
    ownerName,
    memberCount,
    uid,
    visibility,
    isSynced,
    onlyOnePlayingDevice,
    showSearchDialog,
    type,
    seekAt,
    volume,

    inviteCode,
    suggesterCode,
    trackReactionsUserListRequest,
    exportMixRequestStatus
  }
};

const getUpdatedState = (state, payload) => {
  return getUpdatedCurrentRoomState(state, payload)
};

const getUpdatedStateBySocketConn = (state, socketConnState) => {
  const messages = RoomUtil.getUpdatedMessagesByConnectionState(
    state.messages,
    socketConnState
  );

  return getUpdatedState(state, {
    messages,
    socketConnState
  });
};

const queueTrack = (state, payload) => {
  const trackInfo = payload.trackInfo;
  const trackUserMeta = payload.trackUserMeta;
  const currentRoomState = state;
  const playlist = (currentRoomState.playlist).slice();
  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    currentRoomState.messages,
    RoomStatics.playbackEvents.QUEUE,
    payload.triggeredBy
  );

  playlist.push({...trackInfo, ...trackUserMeta});

  return getUpdatedState(state, {
    playlist: playlist,
    messages: messages
  });
};

const queueNextTrackAndPlay = (state, payload) => {
  const currentRoomState = state;
  const trackInfo = payload.trackInfo;
  const updatedState = queueNextTrack(state, payload);

  console.log("Adding and Playing");
  console.log(trackInfo);

  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    currentRoomState.messages,
    RoomStatics.playbackEvents.ADDANDPLAY,
    payload.triggeredBy
  );

  return getUpdatedState(updatedState, {
    roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION,
    messages: messages
  });
};

const queueNextTrack = (state, payload) => {
  const trackInfo = payload.trackInfo;
  const lastPlayingTrack = payload.lastPlayingTrack;
  const trackUserMeta = payload.trackUserMeta;
  const fullTrackInfo = {...trackInfo, ...trackUserMeta};
  const currentRoomState = state;
  const currentPlaylist = currentRoomState.playlist;
  const currentTrackIndex = currentRoomState.currentTrackIndex;

  let lastTrackIndex = 0;
  let newPlaylist = currentPlaylist.slice();

  if(currentPlaylist.length > 0) {
    for(let i=0; i < currentPlaylist.length; i++) {
      if((currentPlaylist[i]).id === lastPlayingTrack.id) {
        lastTrackIndex = i;
      }
    }

    newPlaylist.splice(lastTrackIndex+1, 0, fullTrackInfo);
  } else {
    newPlaylist.push(fullTrackInfo)
  }

  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    currentRoomState.messages,
    RoomStatics.playbackEvents.QUEUENEXT,
    payload.triggeredBy
  );

  console.log("Adding Next");
  console.log(fullTrackInfo);

  return getUpdatedState(state, {
    playlist: newPlaylist,
    nextTrackIndex: currentTrackIndex+1,
    messages: messages
  });
};

const playNextTrack = (state, payload) => {
  const currentRoomState = state;
  const nextTrackIndex = payload.nextTrackIndex;

  console.log('Trying to playing next');

  const nextTrackIndexVal =
    nextTrackIndex !== undefined ?
      nextTrackIndex :
      currentRoomState.nextTrackIndex;
  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    currentRoomState.messages,
    RoomStatics.playbackEvents.PLAYNEXT,
    payload.triggeredBy
  );

  if(nextTrackIndexVal >= currentRoomState.playlist.length) {
    //No more queued videos
    console.log('No more queued videos');
    return state;
  }

  console.log('Playing next track: ', nextTrackIndex, nextTrackIndexVal);

  return getUpdatedState(state, {
    messages: messages,
    nextTrackIndex: nextTrackIndexVal,
    roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
  });

  /*
  if(!RoomUtil.isRoomInTransition(currentRoomState.roomState)) {
    const nextTrackIndexVal =
      nextTrackIndex !== undefined ?
        nextTrackIndex :
        currentRoomState.nextTrackIndex;
    const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
      currentRoomState.messages,
      RoomStatics.playbackEvents.PLAYNEXT
    );

    if(nextTrackIndexVal >= currentRoomState.playlist.length) {
      //No more queued videos
      console.log('No more queued videos');
      return state;
    }

    console.log('Playing next');
    console.log(currentRoomState.playerStates);

    return getUpdatedState(state, {
      messages: messages,
      nextTrackIndex: nextTrackIndexVal,
      roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
    });
  } else {
    console.log('Already in transition!');
    return state;
  }
  */
};

const removeTrackAt = (state, payload) => {
  const currentRoomState = state;
  const trackIndex = payload.trackIndex;
  const currentPlaylist = currentRoomState.playlist;
  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    currentRoomState.messages,
    RoomStatics.playbackEvents.REMOVED,
    payload.triggeredBy
  );

  let newPlaylist = currentPlaylist.slice();
  let currentTrackIndex = currentRoomState.currentTrackIndex;
  let nextTrackIndex = currentRoomState.nextTrackIndex;

  newPlaylist.splice(trackIndex, 1);

  if(currentTrackIndex > trackIndex) {
    currentTrackIndex--;
  }

  if(nextTrackIndex > trackIndex) {
    nextTrackIndex--;
  }

  return getUpdatedState(state, {
    playlist: newPlaylist,
    currentTrackIndex: currentTrackIndex,
    nextTrackIndex: nextTrackIndex,
    messages: messages
  });
};

const removeTrackFromSuggestions = (state, payload) => {
  const currentSuggestions = state.suggestions;

  let trackId = payload.trackInfo.id;
  let newSuggestions = currentSuggestions.filter(suggestion => suggestion.id !== trackId);

  return getUpdatedState(state, {
    suggestions: newSuggestions
  })
};

const upvoteTrack = (state, payload) => {
  console.log('adding upvote');
  let {trackInfo, eventFromFrontend} = payload;
  const currentUserSuggestions = state.userSuggestions;

  if(eventFromFrontend) {
    if (!trackInfo.voteCount) {
      // if there have been no votes yet, event must be from the same user
      trackInfo.voteCount = 1;
      trackInfo.votedUp = true;
    }
    else if (!trackInfo.votedUp) {
      // if the event is from the frontend and user hasn't voted this up, increment the vote count
      console.log('incrementing vote count');
      trackInfo.voteCount = trackInfo.voteCount + 1;
      trackInfo.votedUp = true;
    }
    else {
      // user has already voted this up
      return state;
    }
  }

  let newUserSuggestions = currentUserSuggestions.slice();
  newUserSuggestions = newUserSuggestions.filter(track => track.id !== trackInfo.id);

  let previousTrackInfo = currentUserSuggestions.filter(track => track.id === trackInfo.id);
  let newTrackInfo;
  if(previousTrackInfo && previousTrackInfo.length) {
    previousTrackInfo = previousTrackInfo[0];
    newTrackInfo = {
      ...previousTrackInfo,
      ...trackInfo
    };
  }
  else
    newTrackInfo = trackInfo;

  //console.log({previousTrackInfo, newTrackInfo});

  if(!newUserSuggestions)
    newUserSuggestions = [newTrackInfo];
  else
    newUserSuggestions.push(newTrackInfo);

  newUserSuggestions = newUserSuggestions.sort((elem1, elem2) => elem1.voteCount < elem2.voteCount);

  return getUpdatedState(state, {
    userSuggestions: newUserSuggestions
  })
};

const downvoteTrack = (state, payload) => {
  console.log("downvoting track");
  let {trackInfo, eventFromFrontend} = payload;
  const currentUserSuggestions = state.userSuggestions;

  if(eventFromFrontend) {
    if (trackInfo.votedUp) {
      // if the event is from the frontend and user has voted this up, decrement the vote count
      console.log('decrement vote count');
      trackInfo.voteCount = trackInfo.voteCount - 1;
      trackInfo.votedUp = false;
    }
    else {
      // user hasn't voted this up yet
      return state;
    }
  }

  let newUserSuggestions = currentUserSuggestions.slice();
  newUserSuggestions = newUserSuggestions.filter(track => track.id !== trackInfo.id);

  let previousTrackInfo = currentUserSuggestions.filter(track => track.id === trackInfo.id);
  let newTrackInfo;
  if(previousTrackInfo && previousTrackInfo.length) {
    previousTrackInfo = previousTrackInfo[0];
    newTrackInfo = {
      ...previousTrackInfo,
      ...trackInfo
    };
  }
  else
    newTrackInfo = trackInfo;

  if(trackInfo.voteCount <= 0)
    newTrackInfo.isUserSuggestion = false;

  //console.log({previousTrackInfo, newTrackInfo});

  if(!newUserSuggestions)
    newUserSuggestions = [newTrackInfo];
  else
    newUserSuggestions.push(newTrackInfo);

  newUserSuggestions = newUserSuggestions.sort((elem1, elem2) => elem1.voteCount < elem2.voteCount);

  return getUpdatedState(state, {
    userSuggestions: newUserSuggestions
  })
};

const clearTracks = (state) => {
  console.log('======================================== CLEARING ================================================');
  const messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
    state.messages,
    RoomStatics.playbackEvents.CLEAR,
    {}
  );

  return getUpdatedState(state, {
    messages: messages,
    currentTrackIndex: -1,
    playlist: [],
    roomState: RoomStatics.states.TRACK_EMPTY_LIST,
    showClearConfirmDialog: false
  });
};

const roomLoginFailed = (state, payload) => {
  const {errorCode, message} = payload;
  let socketConnState;

  if(errorCode === 404) {
    socketConnState = RoomStatics.socketStates.INVALIDROOMNAME;
  } else if(errorCode === 403) {
    socketConnState = RoomStatics.socketStates.UNAUTHORISED;
  } else {
    socketConnState = RoomStatics.socketStates.SERVERERROR;
  }

  return getUpdatedStateBySocketConn(roomInitialState, socketConnState);
};

const updateCurrentTrackIndexFromServer = (state, payload) => {
  const currentTrackIndexUpdate = payload.currentTrackIndex;

  if(
    state.isSynced &&
    state.currentTrackIndex !== currentTrackIndexUpdate
  ) {
    if(!RoomUtil.isRoomInTransition(state.roomState)) {
      // Since room is not in transition
      // lets schedule transition for updated current playing index
      return playNextTrack(state, {nextTrackIndex: currentTrackIndexUpdate});
    } else {
      if(
        state.roomState === RoomStatics.states.TRACK_WAITING_FOR_TRANSITION &&
        state.nextTrackIndex !== currentTrackIndexUpdate
      ) {
        return getUpdatedState(state, {
          nextTrackIndex: currentTrackIndexUpdate
        });
      } else if(
        state.roomState === RoomStatics.states.TRACK_DIRECT_TRANSITION &&
        state.nextTrackIndex !== currentTrackIndexUpdate
      ) {
        return getUpdatedState(state, {
          nextTrackIndex: currentTrackIndexUpdate,
          roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
        });
      } else if(
        state.roomState === RoomStatics.states.TRACK_CROSS_TRANSITION &&
        state.nextTrackIndex !== currentTrackIndexUpdate
      ) {
        return getUpdatedState(state, {
          nextTrackIndex: currentTrackIndexUpdate,
          roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
        });
      }

      return state;
    }
  }
  else
    return state
};

const updateCurrentTrackPlayback = (state, payload) => {
  return getUpdatedState(state, {
    currentTrackPlaybackInfo: payload.currentTrackPlaybackInfo
  });
};

const roomPausePlayback = (state) => {
  if(state.roomState === RoomStatics.states.TRACK_CROSS_TRANSITION) {
    return getUpdatedState(state, {
      roomState: RoomStatics.states.TRACK_PAUSED_AND_WAITING_FOR_TRANSITION
    });
  } else {
    let messages = state.messages;

    if(state.roomState === RoomStatics.states.TRACK_DIRECT_TRANSITION) {
      messages = RoomUtil.getUpdatedMessagesByPlaybackEvent(
        state.messages,
        RoomStatics.playbackEvents.NONE,
        undefined
      );
    }

    return getUpdatedState(state, {
      roomState: RoomStatics.states.TRACK_PAUSED,
      messages
    });
  }
};

const roomStartPlayback = (state) => {
  if(state.roomState === RoomStatics.states.TRACK_PAUSED_AND_WAITING_FOR_TRANSITION) {
    return getUpdatedState(state, {
      roomState: RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
    });
  } else {
    return getUpdatedState(state, {
      roomState: RoomStatics.states.TRACK_PLAYING
    });
  }
};

const roomUserBecameOnline = (state, userOnlineStatusInfo) => {
  const onlineUsers = state.currentOnlineUsers.filter(onlineUser => onlineUser.userDfId !== userOnlineStatusInfo.userDfId);

  userOnlineStatusInfo.isOnline = true;

  onlineUsers.push(userOnlineStatusInfo);

  return getUpdatedState(state, {
    currentOnlineUsers: onlineUsers
  });
};

const roomUserBecameOffline = (state, userOnlineStatusInfo) => {
  const onlineUsers = state.currentOnlineUsers.filter(onlineUser => onlineUser.userDfId !== userOnlineStatusInfo.userDfId);

  userOnlineStatusInfo.isOnline = false;

  onlineUsers.push(userOnlineStatusInfo);

  return getUpdatedState(state, {
    currentOnlineUsers: onlineUsers
  });
};

const roomLoginSuccess = (state, payload) => {
  const {
    tracks,
    currentTrackIndex,
    userSuggestions,
    currentOnlineUsers,
    accessLevel,
    roomName,
    uid,
    owner,
    ownerName,
    memberCount ,
    visibility,
    type,
    isSynced,
    onlyOnePlayingDevice,
    currentTrackPlaybackInfo,
    volume
  } = payload.roomData;

  console.log("got state with tracks for room", tracks, currentTrackIndex);

  if(tracks.length < 1) {
    const clearedState = clearTracks(state);
    const messages = RoomUtil.getUpdatedMessagesByConnectionState(
      state.messages,
      RoomStatics.socketStates.LOGGEDIN
    );
    return getUpdatedState(clearedState, {
      socketConnState: RoomStatics.socketStates.LOGGEDIN,
      accessLevel,
      roomName,
      memberCount,
      uid,
      owner,
      ownerName,
      userSuggestions,
      currentOnlineUsers,
      visibility,
      isSynced,
      onlyOnePlayingDevice,
      volume,
      messages
    });
  } else {
    const messages = RoomUtil.getUpdatedMessagesByConnectionState(
      state.messages,
      RoomStatics.socketStates.LOGGEDIN
    );

    const roomState = getInitialRoomPlaybackState(state, payload.roomData);
    const updatedCurrentTrackIndex = isSynced ?
      currentTrackIndex :
      (
        state.currentTrackIndex === -1 ?
          (
            tracks.length >= 1 ? 0 : -1
          ) :
          state.currentTrackIndex
      );

    //console.log(`testified old ${state.roomState} current ${roomState} index ${updatedCurrentTrackIndex} type ${type}`);

    //TODO: It should give nextTrackIndex
    const updatedState = getUpdatedState(state, {
      playlist: tracks,
      currentTrackIndex: updatedCurrentTrackIndex,
      nextTrackIndex: updatedCurrentTrackIndex+1,
      socketConnState: RoomStatics.socketStates.LOGGEDIN,
      currentTrackPlaybackInfo,
      roomState,
      messages,
      accessLevel,
      roomName,
      memberCount,
      userSuggestions,
      currentOnlineUsers,
      uid,
      owner,
      ownerName,
      visibility,
      isSynced,
      onlyOnePlayingDevice,
      volume,
      type
    });

    console.log('Updating room redux after Login', updatedState);

    return updatedState;
  }
};

const getInitialRoomPlaybackState = (state, loginPayload) => {
  if(loginPayload.onlyOnePlayingDevice.status !== RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE) {
    return loginPayload.isPaused ?
      RoomStatics.states.TRACK_PAUSED :
      RoomStatics.states.TRACK_PLAYING;
  } else {
    return (
      state.roomState === RoomStatics.states.TRACK_PAUSED ||
      state.roomState === RoomStatics.states.TRACK_PAUSED_BY_USER ||
      state.roomState === RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START ||
      state.roomState === RoomStatics.states.PLAYERS_NOT_READY
    ) ?
      state.roomState :
      RoomStatics.states.TRACK_PLAYING;
  }
};

const resetRoomState = (state) => {
  const {socketConnState, roomState} = state;

  return {
    ...roomInitialState,
    socketConnState,
    roomState
  };
};

const roomCloseSuccess = (state) => {
  return {
    ...state,
    roomState: RoomStatics.states.CLOSED_BY_REQUEST
  };
};

const roomClosedByEvent = (state) => {
  return {
    ...state,
    roomState: RoomStatics.states.CLOSED_BY_EVENT
  };
};

const roomRemoveReaction = (state, payload) => {
  const {trackIndex, userDfId} = payload;
  const newPlaylist = state.playlist.slice();
  const track = newPlaylist[trackIndex];
  const updatedReactions = track.reactions.filter(r => r.userId !== userDfId);

  newPlaylist[trackIndex] = {
    ...track,
    reactions: updatedReactions
  };

  return {
    ...state,
    playlist: newPlaylist
  };
};

const roomAddReaction = (state, payload) => {
  const {trackIndex, userDfId, reactionCode} = payload;
  const newPlaylist = state.playlist.slice();
  const track = newPlaylist[trackIndex];
  const updatedReactions = track.reactions.filter(r => r.userId !== userDfId);

  updatedReactions.push({
    userId: userDfId,
    code: reactionCode
  });

  newPlaylist[trackIndex] = {
    ...track,
    reactions: updatedReactions
  };

  return {
    ...state,
    playlist: newPlaylist
  };
};

export default function(state = initialState, action) {
  switch(action.type) {
    case ROOM_QUEUE_TRACK:
      return queueTrack(state, action.payload);
    case ROOM_QUEUE_NEXT_TRACK_AND_PLAY:
      return queueNextTrackAndPlay(state, action.payload);
    case ROOM_QUEUE_NEXT_TRACK:
      return queueNextTrack(state, action.payload);
    case ROOM_PLAY_NEXT_TRACK:
      return playNextTrack(state, action.payload);
    case ROOM_REMOVE_TRACK:
      return removeTrackAt(state, action.payload);
    case ROOM_REMOVE_TRACK_SUGGESTION_RESULTS:
      return removeTrackFromSuggestions(state, action.payload);
    case ROOM_UPVOTE_TRACK:
      return upvoteTrack(state, action.payload);
    case ROOM_DOWNVOTE_TRACK:
      return downvoteTrack(state, action.payload);
    case ROOM_UPDATE_IS_SYNCED_PROP:
      return getUpdatedState(state, {
        isSynced: action.payload.isSynced
      });
    case ROOM_UPDATE_ONLY_ONE_PLAYER_PROP:
      return getUpdatedState(state, {
        onlyOnePlayingDevice: action.payload.onlyOnePlayingDevice,
        isSynced: action.payload.onlyOnePlayingDevice.state !== RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE || state.isSynced
      });
    case ROOM_UPDATE_CURRENT_TRACK_INDEX:
      return updateCurrentTrackIndexFromServer(state, action.payload);
    case ROOM_UPDATE_CURRENT_TRACK_PLAYBACK:
      return updateCurrentTrackPlayback(state, action.payload);
    case ROOM_SEEK_AT:
      return getUpdatedState(state, {
        seekAt: action.payload.seekPosInSecs
      });
    case ROOM_SET_VOLUME:
      return getUpdatedState(state, {
        volume: action.payload.volume
      });
    case ROOM_CLEAR_TRACKS:
      return clearTracks(state);
    case ROOM_CLEAR_CURRENT:
      return roomInitialState;
    case ROOM_RESET_CURRENT:
      return resetRoomState(state);
    case ROOM_FETCH_SUGGESTIONS:
      return getUpdatedState(state, {
        suggestions: action.response.data.results.trackSuggestions,
        userSuggestions: action.response.data.results.userSuggestions
      });
    case ROOM_ADD_REACTION:
      return roomAddReaction(state, action.payload);
    case ROOM_REMOVE_REACTION:
      return roomRemoveReaction(state, action.payload);
    case ROOM_USER_ONLINE:
      return roomUserBecameOnline(state, action.payload.userOnlineStatusInfo);
    case ROOM_USER_OFFLINE:
      return roomUserBecameOffline(state, action.payload.userOnlineStatusInfo);
    case ROOM_PAUSE_PLAYBACK:
      return roomPausePlayback(state);
    case ROOM_START_PLAYBACK:
      return roomStartPlayback(state);
    case ROOM_CLOSE:
      return roomCloseSuccess(state);
    case ROOM_CLOSED_BY_EVENT:
      return roomClosedByEvent(state);

    case ROOM_LOGIN_SUCCESS:
      return roomLoginSuccess(state, action.payload);
    case ROOM_LOGIN_FAILURE:
      return roomLoginFailed(state, action.payload);
    case ROOM_LOGIN_IN_PROGRESS:
      return getUpdatedStateBySocketConn(state, RoomStatics.socketStates.LOGGINGIN);
    case ROOM_GET_TRACK_REACTIONS_INFO_CLEAR:
      return getUpdatedState(state, {trackReactionsUserListRequest: TRACK_REACTION_USER_INFO_LOADING});
    case ROOM_GET_TRACK_REACTIONS_INFO_IN_PROGRESS:
      return getUpdatedState(state, {trackReactionsUserListRequest: TRACK_REACTION_USER_INFO_LOADING});
    case ROOM_GET_TRACK_REACTIONS_INFO_FAILURE:
      return getUpdatedState(state, {trackReactionsUserListRequest: TRACK_REACTION_USER_INFO_FAILURE});
    case ROOM_GET_TRACK_REACTIONS_INFO_SUCCESS:
      return getUpdatedState(state, {trackReactionsUserListRequest: action.response.data.reactions});
    case ROOM_INVITE_GENERATE_IN_PROGRESS:
      return getUpdatedState(state, {inviteCode: INVITE_CODE_LOADING});
    case ROOM_INVITE_GENERATE_SUCCESS:
      return getUpdatedState(state, {
        inviteCode: action.response.data.inviteCode,
        suggesterCode: action.response.data.suggesterCode
      });
    case ROOM_INVITE_GENERATE_FAILURE:
      return getUpdatedState(state, {inviteCode: INVITE_CODE_FAILURE});
    case SOCKET_STATUS_CONNECTED:
      return getUpdatedStateBySocketConn(state, RoomStatics.socketStates.CONNECTED);
    case SOCKET_STATUS_DISCONNECTED:
      return getUpdatedStateBySocketConn(state, RoomStatics.socketStates.DISCONNECTED);
    case ROOM_UPDATE_STORE:
      return getUpdatedState(state, action.payload);
    case ROOM_EXPORT_MIX_ERROR:
      console.log('export failed with', action);
      return getUpdatedState(state, {exportMixRequestStatus: ROOM_EXPORT_MIX_ERROR});
    case ROOM_EXPORT_MIX_SUCCESS:
      return getUpdatedState(state, {exportMixRequestStatus: ROOM_EXPORT_MIX_SUCCESS});
    case ROOM_EXPORT_MIX_IN_PROGRESS:
      return getUpdatedState(state, {exportMixRequestStatus: ROOM_EXPORT_MIX_IN_PROGRESS});
  }

  return state;
}