import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import {Reveal} from 'react-foundation-components/lib/reveal';
import {CloseButton} from 'react-foundation-components/lib/close-button';

import MobileVolumeControl from '../../../components/volume-control/component';
import {IRoomSettingsDialogProps} from './roomSettings.type';
import {IRoomSettingsDialogState} from './roomSettings.type';
import {RoomConstants} from '../../../constants/room.constants';
import * as RoomActions from '../../../actions/room.actions';
import {IRoomSettingsDialogStateFromProps} from './roomSettings.type';
import {IRoomSettingsDialogDispatchFromProps} from './roomSettings.type';
import {IRoomSettingsDialogSuppliedProps} from './roomSettings.type';
import {AuthConstants} from '../../../constants/auth';
import Toggle from "react-toggle";

const styles = require('./_style.scss');

class RoomSettingsDialog extends PureComponent<IRoomSettingsDialogProps, IRoomSettingsDialogState> {
  static defaultProps = {
    show: false,
    onHide: () => {},
    data: {
      showShowVolumeControl: true
    },
  };

  handleAllDevicesBtnClick = () => {
    if (!this.isPlayingOnAllDevices()) {
      this.props.roomActions.emitAndUpdateOnlyOnePlayingDevice(false);
    }
  };

  handleCurrentDeviceBtnClick = () => {
    if (!this.isPlayingOnCurrentDevice()) {
      this.props.roomActions.emitAndUpdateOnlyOnePlayingDevice(true);
    }
  };

  handleVisibilityBtnClick = (event) => {
    this.props.roomActions.updateRoomVisibility(
      this.props.uid,
      event.target.checked ? 'PUBLIC' : 'PRIVATE',
    );
  };

  renderPlayOnAllDevicesBtn = () => {
    const playTypeAllDeviceStyle = styles.devicePlayTypeAll + ' ' +
      (
        this.isPlayingOnAllDevices() ?
          styles.devicePlayType__selected :
          ''
      );

      return (
        <div
          className={playTypeAllDeviceStyle}
          onClick={this.handleAllDevicesBtnClick}
        >
          Any device
        </div>
      );
  };

  renderPlayOnlyOnThisDeviceBtn = () => {
    const playTypeThisDeviceStyle = styles.devicePlayTypeOnlyOnCurrent + ' ' +
      (
         this.isPlayingOnCurrentDevice() ?
          styles.devicePlayType__selected :
          ''
      );

    return (
      <div
        className={playTypeThisDeviceStyle}
        onClick={this.handleCurrentDeviceBtnClick}
      >
        Only on this device
      </div>
    );
  };

  renderOtherDeviceInfo = () => {
    const { username, device } = this.props.onlyOnePlayingDevice;

    return (
      <div
        className={styles.otherPlayingDeviceInfo}
      >
        Only playing on {username}'s {device}
      </div>
    );
  };

  render() {
    const {onlyOnePlayingDevice, accessLevel} = this.props;

    if (!onlyOnePlayingDevice) {
      return null;
    }

    const devicePlayTypeWrapInnerStyle = styles.devicePlayTypeWrapInnerWithAccess + ' ' +
      (
        this.isAuthorisedToChangePlayDevice() ?
          '' :
          styles.devicePlayTypeWrapInnerWithAccess__disabled
      );

    return (
      <Reveal
        onHide={this.props.onHide}
        show={this.props.show}
        revealClassName={styles.dialog}
      >
        <CloseButton onClick={this.props.onHide} />

        <h2 className={styles.head}>
          Room settings
        </h2>

        {
          this.props.data.showShowVolumeControl &&
          <MobileVolumeControl
            className={styles.volumeWrap}
            accessLevel={accessLevel}
          />
        }

        <div className={styles.devicePlayTypeWrap}>
          <span>Play music on</span>

          <div className={devicePlayTypeWrapInnerStyle}>
            {
              (this.isAuthorisedToChangePlayDevice() || this.isPlayingOnAllDevices()) &&
              this.renderPlayOnAllDevicesBtn()
            }

            {
              (this.isAuthorisedToChangePlayDevice() || this.isPlayingOnCurrentDevice()) &&
              this.renderPlayOnlyOnThisDeviceBtn()
            }
          </div>

          {
            onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.OTHER &&
            this.renderOtherDeviceInfo()
          }

        </div>

        {
          this.isAuthorisedToChangeVisibilty() &&
          <div className={styles.roomVisibility}>
            <label>
              <span className={styles.roomVisibilityLabel}>Publicly visible</span>
              <Toggle
                className={styles.roomVisibilityToggle}
                checked={this.props.visibility === 'PUBLIC'}
                onChange={this.handleVisibilityBtnClick}
                icons={false} />
            </label>
          </div>
        }

      </Reveal>
    );
  }

  isPlayingOnAllDevices = (): boolean => {
    return this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE;
  };

  isPlayingOnCurrentDevice = (): boolean => {
    return this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.CURRENT
  };

  isAuthorisedToChangePlayDevice = (): boolean => {
    return this.props.accessLevel >= AuthConstants.RoomAccess
  };

  isAuthorisedToChangeVisibilty = (): boolean => {
    return this.props.accessLevel >= AuthConstants.FullAccess
  };
}

function mapStateToProps(state) {
  return {
    onlyOnePlayingDevice: state.currentRoom.onlyOnePlayingDevice,
    accessLevel: state.currentRoom.accessLevel,
    visibility: state.currentRoom.visibility,
    uid: state.currentRoom.uid,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(RoomActions, dispatch)
  };
}

export default connect<
  IRoomSettingsDialogStateFromProps,
  IRoomSettingsDialogDispatchFromProps,
  IRoomSettingsDialogSuppliedProps
>(mapStateToProps, mapDispatchToProps)(RoomSettingsDialog);
