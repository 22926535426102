import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from 'rc-slider'
import {Button} from 'react-foundation-components/lib/button';
import {MenuItem} from 'react-foundation-components/lib/menu';
import {Link } from 'react-router-dom';

import * as roomActions from '../../actions/room.actions';
import {RoomConstants} from "../../constants/room.constants";
import {AuthConstants} from "../../constants/auth";

const styles = require('./_styles.scss');

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TooltipSlider = createSliderWithTooltip(Slider);

class MobileVolumeControl extends Component<any, any> {
  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleOnChange = (value) => {
    if(this.props.volume !== value) {
      this.props.roomActions.setVolume(value);

      if(
        this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.OTHER &&
        this.props.accessLevel >= AuthConstants.SuggesterAccess
      ) {
        this.props.roomActions.emitSetVolume(value);
      }
    }
  };

  render() {

    return (
      <div className={styles.wrap + ' ' + this.props.className}>
        <img
          className={styles.volumeIcon}
          src={require('../../../client/assets/img/volume.png')}
        />

        <TooltipSlider
          value={this.props.volume}
          min={0}
          max={100}
          trackStyle={{
            backgroundColor: 'rgb(33, 153, 232)',
            height: 6
          }}
          railStyle={{
            backgroundColor: '#e6e6e6',
            height: 6
          }}
          handleStyle={[{
            width: 20,
            height: 20,
            borderRadius: 30,
            backgroundColor: 'rgb(33, 153, 232)',
            border: 'none',
            transform: 'translate3d(-5%, -5%, 0)',
          }]}

          tipProps={{ overlayClassName: styles.tooltip, placement: 'top' }}

          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    volume: state.currentRoom.volume,
    accessLevel: state.currentRoom.accessLevel,
    onlyOnePlayingDevice: state.currentRoom.onlyOnePlayingDevice,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(roomActions, dispatch)
  };
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(MobileVolumeControl);
