export const ROOM_CLOSE = 'room-close';
export const ROOM_CLOSE_IN_PROGRESS = 'room-close-in-progress';
export const ROOM_CLOSE_ERROR = 'room-close-error';
export const ROOM_EXPORT_MIX_IN_PROGRESS = 'room-export-mix-in-progress';
export const ROOM_EXPORT_MIX_SUCCESS = 'room-export-mix';
export const ROOM_EXPORT_MIX_ERROR = 'room-export-mix-error';
export const ROOM_LOAD_MIX_IN_PROGRESS = 'room-load-mix-in-progress';
export const ROOM_LOAD_MIX_SUCCESS = 'room-load-mix';
export const ROOM_LOAD_MIX_ERROR = 'room-load-mix-error';
export const ROOM_GET_TRACK_REACTIONS_INFO_SUCCESS = 'room-get-track-reactions-info-success';
export const ROOM_GET_TRACK_REACTIONS_INFO_FAILURE = 'room-get-track-reactions-info-error';
export const ROOM_GET_TRACK_REACTIONS_INFO_IN_PROGRESS = 'room-get-track-reactions-info-progress';
export const ROOM_GET_TRACK_REACTIONS_INFO_CLEAR = 'room-get-track-reactions-info-clear';
export const ROOM_UPDATE_VISIBILITY = 'room-update-visibility';
export const ROOM_UPDATE_VISIBILITY_IN_PROGRESS = 'room-update-visibility-in-progress';
export const ROOM_UPDATE_VISIBILITY_ERROR = 'room-update-visibility-error';

export const ROOM_UPDATE_IS_SYNCED_PROP = 'room-update-is-synced-prop';
export const ROOM_UPDATE_ONLY_ONE_PLAYER_PROP = 'room-update-only-one-player-prop';
export const ROOM_CLOSED_BY_EVENT = 'room-closed-by-event';
export const ROOM_UPDATE_CURRENT_TRACK_INDEX = 'room-update-current-track-index';
export const ROOM_UPDATE_CURRENT_TRACK_PLAYBACK = 'room-update-current-track-playback';
export const ROOM_PAUSE_PLAYBACK = 'room-pause-playback';
export const ROOM_START_PLAYBACK = 'room-start-playback';
export const ROOM_USER_ONLINE = 'room-user-online';
export const ROOM_USER_OFFLINE = 'room-user-offline';
export const ROOM_SEEK_AT = 'room-seek-at';
export const ROOM_SET_VOLUME = 'room-set-volume';
export const ROOM_ADD_REACTION = 'room-add-reaction';
export const ROOM_REMOVE_REACTION = 'room-remove-reaction';
