import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Button } from 'react-foundation-components/lib/button';
import { Menu, MenuItem } from 'react-foundation-components/lib/menu';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

import DropdownBox from '../../components/hoc/dropdown-box/component';
import OutsideClickAlerter from '../../components/hoc/outside-click-alerter/OutsideClickAlerter';
import NotificationList from '../notifications/list/notification-list.component';

import * as notificationActions from '../../actions/notification.actions';
import * as authActions from '../../actions/auth.actions';
import * as dialogActions from '../../actions/dialog.actions';
import { AUTH_TYPE_GOOGLE } from '../../actions/types';
import {DIALOG_TYPES} from "../../actions/dialog.type";
import {IHeaderAuthDispatchFromProps} from './headerAuth.type';
import {IHeaderAuthStateFromProps} from './headerAuth.type';
import {IHeaderAuthSuppliedProps} from './headerAuth.type';
import {IHeaderAuthProps} from './headerAuth.type';
import {IHeaderAuthState} from './headerAuth.type';

const styles = require('./_style.scss');

class HeaderAuth extends Component<IHeaderAuthProps, IHeaderAuthState> {
  static defaultProps: IHeaderAuthSuppliedProps = {
    isButtonTransparent: false,
    onDropdownShowOrHide: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownShown: false,
      notiDropdownShown: false
    };
  }

  componentDidMount() {
    this
      .props
      .notificationActions
      .fetchUserNotifications(
        this.props.currentUserDfId
      );

    if(this.props.authenticated === AUTH_TYPE_GOOGLE) {
      this.checkAndFetchBasicUserDetails();
    }
  }

  componentDidUpdate(prevProps: IHeaderAuthProps, prevState: IHeaderAuthState) {
    if(this.props.authenticated !== prevProps.authenticated && this.props.authenticated === AUTH_TYPE_GOOGLE) {
      this.checkAndFetchBasicUserDetails();
    }

    if(this.state.dropdownShown !== prevState.dropdownShown) {
      this.props.onDropdownShowOrHide(this.state.dropdownShown);
    } else if (this.state.notiDropdownShown !== prevState.notiDropdownShown) {
      this.props.onDropdownShowOrHide(this.state.notiDropdownShown);
    }
  }

  checkAndFetchBasicUserDetails = () => {
    if(this.props.currentUserProfilePicUrl === '') {
      this.props.authActions.getUserBasicProfile();
    }
  };

  showFbMessageReveal() {
    this.props.dialogActions.showDialog(DIALOG_TYPES.FB_LOGIN);
  }

  onSignInClick = () => {
    this.handleClickOutside();
    this.showFbMessageReveal();
  };

  onSignOutClick = () => {
    this.setState({dropdownShown: false});
    this.props.authActions.logoutUser();
  };

  onLoggedInPicClick = () => {
    this.setState({dropdownShown: !this.state.dropdownShown});
  };

  onNotiIconClick = () => {
    this.setState({notiDropdownShown: !this.state.notiDropdownShown});
  };

  handleClickOutside = () => {
    this.setState({dropdownShown: false, notiDropdownShown: false});
  };

  routeToUserProfile = (dfId) => {
    console.log("routing to: " + dfId);
    this.props.history.push('/user/' + dfId);
  };

  onUserProfileClick = () => {
    this.routeToUserProfile(this.props.currentUserDfId);
  };

  renderLoggedIn() {
    return (
      <Menu vertical>
        <MenuItem className={styles.dropdownMenu_item}>
          <Button
            onClick={this.onSignOutClick}>
            Sign Out
          </Button>
        </MenuItem>
        <MenuItem className={styles.dropdownMenu_item}>
          <Button
            onClick={this.onUserProfileClick}>
            My Profile
          </Button>
        </MenuItem>
      </Menu>
    );
  }

  renderLoggedOut() {
    return (
      <Menu vertical>
        <MenuItem className={styles.dropdownMenu_item}>
          <Button
            onClick={this.onSignInClick} >
            Login
          </Button>
        </MenuItem>
      </Menu>
    );
  }

  render() {
    const {dropdownShown, notiDropdownShown} = this.state;
    const dropDownElem =
      this.props.authenticated === AUTH_TYPE_GOOGLE ?
        this.renderLoggedIn() :
        this.renderLoggedOut();
    const btnStyle = styles.userIcon + ' ' + (
      this.props.isButtonTransparent ?
        styles.userIcon__transparent :
        ''
    );

    let proficPicSrc;
    let picStyle;

    if(this.props.authenticated === AUTH_TYPE_GOOGLE && this.props.currentUserProfilePicUrl !== '') {
      proficPicSrc = this.props.currentUserProfilePicUrl;
      picStyle = styles.userIcon_profilePic;
    } else {
      proficPicSrc = require('../../../client/assets/img/user-icon.png');
      picStyle = '';
    }

    let unreadCount = this.props.notificationList.filter(noti => !noti.read).length;

    return (
      <Menu horizontal className={styles.wrap}>
        <MenuItem
          className={styles.menuItemUser}
        >
          <Button
            className={btnStyle}
            onClick={this.onLoggedInPicClick}
          >
            <img
              className={picStyle}
              src={proficPicSrc}/>
          </Button>

          <DropdownBox
            isShown={dropdownShown}
            hide={this.handleClickOutside}
          >
            <div className={styles.dropDownBox}>
              {dropDownElem}
            </div>
          </DropdownBox>
        </MenuItem>

        <MenuItem
          className={styles.menuItemNotif}
        >
          <Button
            className={btnStyle}
            onClick={this.onNotiIconClick}
          >
            <img
              src={require('../../../client/assets/img/notification-icon.png')}/>
            {unreadCount > 0 && <div className={styles.unread_count}>{unreadCount}</div>}
          </Button>

          <DropdownBox
            isShown={notiDropdownShown}
            hide={this.handleClickOutside}
          >
            <span className={styles.notificationsHead}>
              Notifications
            </span>
            <div className={styles.notiDropDownBox}>
              <Scrollbars
                autoHeight={true}
                autoHeightMax={350}>
                <NotificationList fetchAll={false}/>
              </Scrollbars>
            </div>
            <span className={styles.seeMore}>
              <Link to={`/notifications`}>Show All</Link>
            </span>
          </DropdownBox>
        </MenuItem>
      </Menu>
    );
  }
}

function mapStateToProps(state): IHeaderAuthStateFromProps {
  return {
    authenticated: state.auth.authenticated,
    currentUserDisplayName: state.auth.displayName,
    currentUserGoogleId: state.auth.googleId,
    currentUserDfId: state.auth.dfId,
    currentUserProfilePicUrl: state.auth.profilePicUrl,
    notificationList: state.notifications.notificationList
  };
}

function mapDispatchToProps(dispatch): IHeaderAuthDispatchFromProps {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    dialogActions: bindActionCreators(dialogActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
}

// export default connect<
//   IHeaderAuthStateFromProps,
//   IHeaderAuthDispatchFromProps,
//   IHeaderAuthSuppliedProps
// >(mapStateToProps, mapDispatchToProps)(withRouter<IHeaderAuthProps>(HeaderAuth));

export default connect<
  IHeaderAuthStateFromProps,
  IHeaderAuthDispatchFromProps,
  IHeaderAuthSuppliedProps
  >(mapStateToProps, mapDispatchToProps)(withRouter(HeaderAuth));