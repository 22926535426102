import JoinRoom from '../containers/dialog/join-room/join-room.component';
import InviteToRoom from '../containers/dialog/generate-invite/generateInvite.component';
import CreateRoom from '../containers/dialog/create_room/create_room.component';
import FbLoginMessageDialog from '../containers/dialog/google-login-message/fb-login-message.component';
import ReactionUserList from '../containers/dialog/reaction-user-list/component';
import RoomSettingsDialog from '../containers/dialog/room-settings/roomSettings.component';
import TutorialDesktopDialog from "../containers/dialog/tutorial/desktop/tutorialDesktop.component";
import TutorialMobileDialog from "../containers/dialog/tutorial/mobile/tutorialMobile.component";
import ExportMixDialog from "../containers/dialog/export-mix/exportMix.component";
import LoadMixDialog from "../containers/dialog/load-mix/loadMix.component";

export const DIALOG_TYPES = {
  JOIN_ROOM: 'join-room',
  INVITE_TO_ROOM: 'invite-to-room',
  CREATE_ROOM: 'create-room',
  FB_LOGIN: 'fb-login',
  TRACK_REACTION_USERS: 'track-reaction-users',
  ROOM_SETTINGS: 'room-settings',
  EXPORT_MIX: 'export-mix',
  LOAD_MIX: 'load-mix',

  // Tutorials
  TUTORIAL_DESKTOP_DIALOG: 'tutorial-desktop-dialog',
  TUTORIAL_MOBILE_DIALOG: 'tutorial-mobile-dialog',
};

export const DIALOG_TYPE_TO_COMPONENT_MAP = new Map<string, any>([
  [DIALOG_TYPES.JOIN_ROOM, JoinRoom],
  [DIALOG_TYPES.INVITE_TO_ROOM, InviteToRoom],
  [DIALOG_TYPES.CREATE_ROOM, CreateRoom],
  [DIALOG_TYPES.FB_LOGIN, FbLoginMessageDialog],
  [DIALOG_TYPES.TRACK_REACTION_USERS, ReactionUserList],
  [DIALOG_TYPES.ROOM_SETTINGS, RoomSettingsDialog],
  [DIALOG_TYPES.EXPORT_MIX, ExportMixDialog],
  [DIALOG_TYPES.LOAD_MIX, LoadMixDialog],

  [DIALOG_TYPES.TUTORIAL_DESKTOP_DIALOG, TutorialDesktopDialog],
  [DIALOG_TYPES.TUTORIAL_MOBILE_DIALOG, TutorialMobileDialog],
]);

export const DIALOG_REGISTER_FOR_PAGE = 'dialog-register-for-page';
export const DIALOG_SHOW = 'dialog-show';
export const DIALOG_HIDE = 'dialog-hide';
export const BLUR_MAIN_CONTENT = 'blur-main-content';
export const UNBLUR_MAIN_CONTENT = 'unblur-main-content';
