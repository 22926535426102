import {PREFS_SET} from "../actions/preferences.types";
import {PREF_KEY} from "../actions/preferences.types";
import {PREF_VIDEO_SIZE_MODE} from "../actions/preferences.types";
import uuidV4 from 'uuid/v4';

const getPrefVal = (prefKey, defaultVal) => {
  const val = localStorage.getItem(prefKey);

  if(val !== undefined && val !== null) {
    console.log("returning value for", prefKey, val);
    return val;
  } else {
    setPrefVal(prefKey, defaultVal);
    console.log("returning default value for", prefKey, defaultVal);
    return defaultVal;
  }
};

const setPrefVal = (prefKey, prefVal) => {
  localStorage.setItem(prefKey, prefVal);
};

const initialState = {
  [PREF_KEY.VIDEO_SIZE_MODE]: getPrefVal(PREF_KEY.VIDEO_SIZE_MODE, PREF_VIDEO_SIZE_MODE.THEATRE),
  [PREF_KEY.LOCAL_SESSION_ID]: getPrefVal(PREF_KEY.LOCAL_SESSION_ID, uuidV4()),

  // Tutorials seen counts
  [PREF_KEY.TUTORIAL_DESKTOP_ONBOARDING]: getPrefVal(PREF_KEY.TUTORIAL_DESKTOP_ONBOARDING, 2),
  [PREF_KEY.TUTORIAL_MOBILE_ONBOARDING]: getPrefVal(PREF_KEY.TUTORIAL_MOBILE_ONBOARDING, 2),
};

const updatePref = (state, prefKey, prefVal) => {
  setPrefVal(prefKey, prefVal);

  return {
    ...state,
    [prefKey]: prefVal
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PREFS_SET:
      console.log('Preference Reducer: ', action);
      return updatePref(state, action.payload.prefKey, action.payload.prefVal);
  }

  return state;
};
