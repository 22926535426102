import {DIALOG_SHOW} from './dialog.type';
import {DIALOG_HIDE} from './dialog.type';
import {BLUR_MAIN_CONTENT} from './dialog.type';
import {UNBLUR_MAIN_CONTENT} from './dialog.type';
import {DIALOG_REGISTER_FOR_PAGE} from './dialog.type';

export function showDialog(dialogType, dialogData = {}) {
  return {
    type: DIALOG_SHOW,
    payload: {
      dialogType,
      dialogData
    }
  };
}

export function hideDialog(dialogType) {
  return {
    type: DIALOG_HIDE,
    payload: {
      dialogType
    }
  };
}

export function registerDialogs(dialogTypes) {
  return {
    type: DIALOG_REGISTER_FOR_PAGE,
    payload: {
      dialogTypes
    }
  };
}

export function blurMainContent() {
  return {
    type: BLUR_MAIN_CONTENT
  };
}

export function unblurMainContent() {
  return {
    type: UNBLUR_MAIN_CONTENT
  };
}