import {GLOBAL_TOGGLE_FULLSCREEN} from './global.types';
import {GLOBAL_SET_FULLSCREEN_VAL} from './global.types';
import {GLOBAL_REGISTER_OUTSIDE_CLICK_CONTAINER_ID} from './global.types';
import {GLOBAL_UNREGISTER_OUTSIDE_CLICK_CONTAINER_ID} from './global.types';
import {GLOBAL_SET_OUTSIDE_CLICK_STATUS} from './global.types';

export function toggleFullscreen() {
  return {
    type: GLOBAL_TOGGLE_FULLSCREEN
  };
}

export function setFullscreenVal(isFullscreen) {
  return {
    type: GLOBAL_SET_FULLSCREEN_VAL,
    payload: {
      isFullscreen
    }
  };
}

export function setOutsideClickContainerId(containerId) {
  return {
    type: GLOBAL_REGISTER_OUTSIDE_CLICK_CONTAINER_ID,
    payload: {
      containerId
    }
  };
}

export function removeOutsideClickContainerId(containerId) {
  return {
    type: GLOBAL_UNREGISTER_OUTSIDE_CLICK_CONTAINER_ID,
    payload: {
      containerId
    }
  };
}

export function setOutsideClickContainerIdStatus(containerId, status) {
  return {
    type: GLOBAL_SET_OUTSIDE_CLICK_STATUS,
    payload: {
      containerId,
      status
    }
  };
}
