import React, { PureComponent } from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import ReactImageFallback from "react-image-fallback";

import {Button} from 'react-foundation-components/lib/button';

import FlashBox from '../../../../components/flash-box/component';
import {ILoadMixListProps} from './mixScrollList.type';
import {ILoadMixListState} from './mixScrollList.type';
import {IUserMix} from '../../../../../server/services/mixService.type';
import {MIX_ACTIONS} from './mixScrollList.type';
import FormatUtils from '../../../../utils/FormatUtils';

const styles = require('./_style.scss');

class MixScrollList extends PureComponent<ILoadMixListProps, ILoadMixListState> {
  static defaultProps = {
    mixes: [],
    onMixAction: () => {},
  };

  scrollContElement;

  constructor(props) {
    super(props);

    this.state = {
      scrollContainerHeight: 0,
      userFeedback: {},
    };
  }

  componentDidMount() {
    const scrollContainerHeight =
      this.scrollContElement ?
        this.scrollContElement.clientHeight :
        this.state.scrollContainerHeight;

    this.setState({
      scrollContainerHeight
    });
  }

  onMixAdded = (index: number) => {
    this.setState({userFeedback: {
        ...this.state.userFeedback,
        [index]: MIX_ACTIONS.ADD_MIX
      }});

    this.props.onMixAction(MIX_ACTIONS.ADD_MIX, index);
  };

  onFeedBackComplete = (index: number) => {
    const clone = {...this.state.userFeedback};

    delete clone[index];

    this.setState({userFeedback: clone});
  };

  renderList = () => {
    return this.props.mixes.map((mix: IUserMix, index: number) => {
      const feedbackAction: MIX_ACTIONS = this.state.userFeedback[index];
      const feedbackMessage: string = this.messageFromAction(feedbackAction);

      const mixDurationInSeconds: number = mix.playList.reduce(
        (total, track) => total + track.duration,
        0
      );
      const mixThumbnail = `https://img.youtube.com/vi/${mix.playList[0].id}/mqdefault.jpg`;

      return (
        <div
          key={mix.uid}
          className={styles.listItem}
        >
          <div className={styles.leftSection}>
            <ReactImageFallback
              src={mixThumbnail}
              fallbackImage={require("../../../../../client/assets/img/track-empty-thumb.png")}
              initialImage={require("../../../../../client/assets/img/track-empty-thumb.png")}
              className={styles.listThumbImg}
            />
          </div>
          <div className={styles.centerSection}>
            <span className={styles.mixTitle}>
              {mix.name}
            </span>

            <div className={styles.mixInfo}>
              <div className={styles.mixDuration}>
                {FormatUtils.duration(mixDurationInSeconds)}
              </div>

              <div className={styles.mixTrackCount}>
                {mix.playList.length} Tracks
              </div>
            </div>

            {
              feedbackMessage &&
              <FlashBox
                showDuration={500}
                className={styles.feedbackCont}
                onFlashComplete={() => {
                  this.onFeedBackComplete(index);
                }}
              >
                <div className={styles.feedback}>
                  {feedbackMessage}
                </div>
              </FlashBox>
            }
          </div>
          <div className={styles.addBtnSection}>
            <Button
              className={styles.addBtn}
              onClick={() => this.onMixAdded(index)}
            >
              <img src={require("../../../../../client/assets/img/add-icon.png")}/>
            </Button>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div
        className={styles.scrollCont}
        ref={(elem) => this.scrollContElement = elem}
      >
        {
          this.state.scrollContainerHeight > 0 &&

          <Scrollbars
            autoHeight={true}
            autoHeightMax={this.state.scrollContainerHeight}
          >
            <div className={styles.listWrap}>
              {this.renderList()}
            </div>
          </Scrollbars>
        }
      </div>
    );
  }

  messageFromAction = (actionType: MIX_ACTIONS) => {
    switch (actionType) {
      case MIX_ACTIONS.ADD_MIX:
        return 'Added mix to playlist';
      default:
        return null;
    }
  };
}

export default MixScrollList;