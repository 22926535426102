import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {TitleBar} from 'react-foundation-components/lib/title-bar';
import {TitleBarItem} from 'react-foundation-components/lib/title-bar';
import {TitleBarTitle} from 'react-foundation-components/lib/title-bar';
import {Button} from 'react-foundation-components/lib/button';
import {Menu} from 'react-foundation-components/lib/menu';
import {MenuItem} from 'react-foundation-components/lib/menu';
import {Link } from 'react-router-dom';

import HeaderAuth from '../../header_auth/header_auth.component';
import RoomHeaderActions from '../../room-header-actions/component';
import DesktopVolumeControl from './volume-control/component';
import {DIALOG_TYPES} from '../../../actions/dialog.type';

const globalStyles = require('../../../../client/assets/css/global.scss');
const baseHeaderStyles = require('../basic/_style.scss');
const styles = require('./_style.scss');

import * as dialogActions from '../../../actions/dialog.actions';
import * as globalActions from '../../../actions/global.actions';
import {PREF_KEY} from "../../../actions/preferences.types";
import {AuthConstants} from "../../../constants/auth";

class DesktopRoomHeader extends Component<any, any> {
  static defaultProps = {
    onToggleOffCanvas: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      showInviteDialog: false,
      showJoinRoomDialog: false
    };
  }

  componentWillMount() {
    const dialogs = [
      DIALOG_TYPES.INVITE_TO_ROOM,
      DIALOG_TYPES.FB_LOGIN,
      DIALOG_TYPES.EXPORT_MIX,
      DIALOG_TYPES.LOAD_MIX,
      DIALOG_TYPES.TRACK_REACTION_USERS,
      DIALOG_TYPES.ROOM_SETTINGS,
    ];

    if(this.props.tutorialOnboardingDialogSeenCount > 0) {
      dialogs.push(DIALOG_TYPES.TUTORIAL_DESKTOP_DIALOG)
    }

    this.props.dialogActions.registerDialogs(dialogs);
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    if(this.props.tutorialOnboardingDialogSeenCount > 0) {
      this.props.dialogActions.showDialog(DIALOG_TYPES.TUTORIAL_DESKTOP_DIALOG);
    }
  }

  toggleFullScreen = () => {
    this.props.globalActions.toggleFullscreen();
  };

  showInviteDialog = () => {
    this.props.dialogActions.showDialog(DIALOG_TYPES.INVITE_TO_ROOM);
  };

  render() {
    const titleBarStyle = baseHeaderStyles.title_bar + ' ' +
      (
        this.props.isHeaderHidden ?
          baseHeaderStyles.title_bar__hidden :
          ''
      ) + ' ' +
      (
        this.props.isHeaderTransparent ?
          baseHeaderStyles.title_bar__transparent :
          ''
      );
    const fullscreenBtnImage = this.props.isFullscreen ?
      require('../../../../client/assets/img/minimize-icon.png') :
      require('../../../../client/assets/img/full-screen-icon.png');
    const fullscreenBtnClass = this.props.isFullscreen ?
      styles.fullscreenBtnMin :
      styles.fullscreenBtnMax;

    return (
      <TitleBar className={titleBarStyle}>
        <div className={globalStyles.wrap}>
          <TitleBarItem position="left">
            <TitleBarTitle className={baseHeaderStyles.title_bar_title}>
              <Link to="/">
                <img
                  className={baseHeaderStyles.title_bar_logoIcon}
                  src={require('../../../../client/assets/img/logo-icon.png')}
                />
                <img
                  className={baseHeaderStyles.title_bar_logoText}
                  src={require('../../../../client/assets/img/logo.png')}
                />
              </Link>
            </TitleBarTitle>
          </TitleBarItem>
          <TitleBarItem position="right">

            {
              (
                this.props.accessLevel >= AuthConstants.RoomAccess &&
                this.props.visibility !== 'SOLO'
              ) &&
              <Button
                className={styles.inviteBtn}
                onClick={this.showInviteDialog}
              >
                <img src={require('../../../../client/assets/img/invite-icon.png')} />
                <span>Invite</span>
              </Button>
            }

            <DesktopVolumeControl
            />

            <Menu className={baseHeaderStyles.title_bar_desktop_menu}>
              <MenuItem className={baseHeaderStyles.titleBarDesktopMenuItem}>
                <Button
                  className={fullscreenBtnClass}
                  onClick={this.toggleFullScreen}
                  id="fullscreen-btn"
                  >
                  <img src={fullscreenBtnImage} />
                </Button>
              </MenuItem>

            </Menu>

            <HeaderAuth
              isButtonTransparent={false}
            />

            <RoomHeaderActions
              roomName={this.props.roomName}
              roomOwnerName={this.props.roomOwnerName}
              roomOwnerDfId={this.props.roomOwnerDfId}
              accessLevel={this.props.accessLevel}
              visibility={this.props.visibility}
              roomId={this.props.roomId}
            />

          </TitleBarItem>
        </div>

      </TitleBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFullscreen: state.global.isFullscreen,
    tutorialOnboardingDialogSeenCount: state.prefs[PREF_KEY.TUTORIAL_DESKTOP_ONBOARDING],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dialogActions: bindActionCreators(dialogActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopRoomHeader);
