import {SOCKET_PENDING_MESSAGES_FLUSHED} from '../actions/types';
import {IWsClientEvent} from '../middleware/ws.type';
import {SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS} from '../actions/types';
import {SOCKET_FLUSH_PENDING_MESSAGES_ADD} from '../actions/types';
import {SOCKET_FLUSH_PENDING_MESSAGES_REMOVE} from '../actions/types';

interface IWsReducerState {
  pendingWsMessages: Array<IWsClientEvent>;
}

export const PENDING_WS_MESSAGES_EMPTY = [];
export const PENDING_WS_MESSAGES_FLUSH_IN_PROGRESS = [];

const initialState: IWsReducerState = {
  pendingWsMessages: [],
};

const addMessage = (state: IWsReducerState, message: IWsClientEvent): IWsReducerState => {
  const updatedMessages = state.pendingWsMessages.slice();

  updatedMessages.push(message);

  return {
    pendingWsMessages: updatedMessages,
  };
};

const removeMessage = (state: IWsReducerState, message: IWsClientEvent): IWsReducerState => {
  const updatedMessages = state.pendingWsMessages.filter(m => m.uid !== message.uid);

  return {
    pendingWsMessages: updatedMessages,
  };
};

export default function(state: IWsReducerState = initialState, action) {
  switch(action.type) {
    case SOCKET_PENDING_MESSAGES_FLUSHED:
      return { ...state, pendingWsMessages: PENDING_WS_MESSAGES_EMPTY };
    case SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS:
      return { ...state, pendingWsMessages: PENDING_WS_MESSAGES_FLUSH_IN_PROGRESS };
    case SOCKET_FLUSH_PENDING_MESSAGES_ADD:
      return addMessage(state, action.wsMessage);
    case SOCKET_FLUSH_PENDING_MESSAGES_REMOVE:
      return removeMessage(state, action.wsMessage);
  }

  return state;
}
