import {GLOBAL_SET_FULLSCREEN_VAL} from '../actions/global.types';
import {GLOBAL_REGISTER_OUTSIDE_CLICK_CONTAINER_ID} from '../actions/global.types';
import {GLOBAL_UNREGISTER_OUTSIDE_CLICK_CONTAINER_ID} from '../actions/global.types';
import {GLOBAL_SET_OUTSIDE_CLICK_STATUS} from '../actions/global.types';

export const OUTSIDE_CLICK_STATUS = {
  WATCHING: 0,
  NOT_WATCHING: 1,
  CAUGHT: 2,
};

const initialState = {
  isFullscreen: false,

  containerIdsForOutsideClickAlert: [],
};

export default function(state = initialState, action) {

  switch(action.type) {
    case GLOBAL_SET_FULLSCREEN_VAL:
      return { ...state, isFullscreen: action.payload.isFullscreen };

    case GLOBAL_REGISTER_OUTSIDE_CLICK_CONTAINER_ID:
      return {
        ...state,
        containerIdsForOutsideClickAlert: [
          ...state.containerIdsForOutsideClickAlert,
          {
            containerId: action.payload.containerId,
            status: OUTSIDE_CLICK_STATUS.WATCHING
          }
        ]
      };

    case GLOBAL_UNREGISTER_OUTSIDE_CLICK_CONTAINER_ID:
      return {
        ...state,
        containerIdsForOutsideClickAlert: state.containerIdsForOutsideClickAlert.filter(
          cont => cont.containerId !== action.payload.containerId
        )
      };

    case GLOBAL_SET_OUTSIDE_CLICK_STATUS:
      const newList = state.containerIdsForOutsideClickAlert.map(
        cont => {
          if(cont.containerId === action.payload.containerId) {
            cont.status = action.payload.status;
          }

          return cont;
        }
      );

      return {
        ...state,
        containerIdsForOutsideClickAlert: newList
      };
  }

  return state;
}
