import React, { PureComponent } from 'react';
import {Motion, spring, presets} from 'react-motion';

const styles = require('./_styles.scss');

const FLASH_ANIM_STATE = {
  FADE_IN: 'fade-in',
  SHOWN: 'shown',
  FADE_OUT: 'fade-out',
  HIDDEN: 'hidden'
};
const SHOW_DURATION = 800;
const NothingElem = null;

class FlashBox extends PureComponent<any, any> {
  static defaultProps = {
    showDuration: SHOW_DURATION,
    className: '',
    onFlashComplete: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      animState: FLASH_ANIM_STATE.FADE_IN,
      stateTransitionScheduler: null
    };
  }

  cancelTransition() {
    if(this.state.stateTransitionScheduler != null) {
      clearTimeout(this.state.stateTransitionScheduler);
      this.setState({stateTransitionScheduler: null});
    }
  }

  fadeInComplete = () => {
    this.setState({
      animState: FLASH_ANIM_STATE.SHOWN
    });

    if(!this.props.persistent) {
      const scheduler = setTimeout(() => {
        this.cancelTransition();
        this.setState({
          animState: FLASH_ANIM_STATE.FADE_OUT
        });
      }, this.props.showDuration);

      this.setState({stateTransitionScheduler: scheduler});
    }
  };

  fadeOutComplete = () => {
    this.setState({
      animState: FLASH_ANIM_STATE.HIDDEN
    });

    this.props.onFlashComplete();
  };

  render() {
    switch(this.state.animState) {
      case FLASH_ANIM_STATE.FADE_IN:
        return(
          <Motion
            defaultStyle={{x: 0}}
            style={{
              x: spring(1, {stiffness: 150, damping: 17})
            }}
            onRest={this.fadeInComplete}
          >
            {({x}) =>
              <div
                className={styles.wrap + ' ' + this.props.className}
                style={{
                  opacity: x
                }}
              >
                {this.props.children}
              </div>}
          </Motion>
        );

      case FLASH_ANIM_STATE.SHOWN:
        return(
          <div
            className={styles.wrap + ' ' + this.props.className}
            style={{
              opacity: 1
            }}
          >
            {this.props.children}
          </div>
        );

      case FLASH_ANIM_STATE.FADE_OUT:
        return(
          <Motion
            defaultStyle={{x: 1}}
            style={{
              x: spring(0, {stiffness: 180, damping: 17})
            }}
            onRest={this.fadeOutComplete}
          >
            {({x}) =>
              <div
                className={styles.wrap + ' ' + this.props.className}
                style={{
                  opacity: x
                }}
              >
                {this.props.children}
              </div>}
          </Motion>
        );

      case FLASH_ANIM_STATE.HIDDEN:
        return NothingElem;
    }
  }
}

export default FlashBox;
