import fscreen from 'fscreen';

import {GLOBAL_TOGGLE_FULLSCREEN} from '../actions/global.types';
import * as globalActions from '../actions/global.actions';

const globalUtilMiddleware = (function() {

  const handleEvent = (store) => {
    if (fscreen.fullscreenElement !== null) {
      store.dispatch(globalActions.setFullscreenVal(true));
    } else {
      store.dispatch(globalActions.setFullscreenVal(false));
    }
  };

  const toggleFullScreen = () => {
    if (fscreen.fullscreenElement === null) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      if (fscreen.exitFullscreen) {
        fscreen.exitFullscreen();
      }
    }
  };

  const initialiseEventListeners = (store) => {
    fscreen.addEventListener(
      'fullscreenchange',
      () => {
        handleEvent(store)
      },
      false
    );

    /*
    fscreen.removeEventListener(
      'fullscreenchange',
      () => {
        handleEvent(store)
      },
      false
    );
    */
  };

  return store => {
    initialiseEventListeners(store);

    return next => action => {

      //initialiseEventListeners(store);

      switch (action.type) {
        case GLOBAL_TOGGLE_FULLSCREEN:
          toggleFullScreen();
          break;
      }

      // console.log('Pass through room middleware', action);
      return next(action);
    }
  }
})();

export default globalUtilMiddleware;