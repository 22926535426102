import {HEADER_HIDE} from './header.type';
import {HEADER_SHOW} from './header.type';
import {HEADER_MAKE_TRANSPARENT} from './header.type';
import {HEADER_MAKE_OPAQUE} from './header.type';
import {HEADER_SET_CONFIG} from "./header.type";

export function showOrHideHeader(show) {
  if(show){
    return {
      type: HEADER_SHOW
    };
  } else {
    return {
      type: HEADER_HIDE
    };
  }
}

export function opaqueOrTransparentHeader(transparent) {
  if(transparent){
    return {
      type: HEADER_MAKE_TRANSPARENT
    };
  } else {
    return {
      type: HEADER_MAKE_OPAQUE
    };
  }
}

export function setHeaderConfig(config) {
  return {
    type: HEADER_SET_CONFIG,
    config
  };
}