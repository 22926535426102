/// <reference path="../../../types/index.d.ts"/>

import {
  FB_AUTH_SDK_LOADED,
  CHECK_AUTH,
  LOGIN_USER,
  LOGOUT_USER, GOOGLE_AUTH_SDK_LOADED, CALL_API
} from './types';
import {
  FETCH_USER_BASIC_PROFILE, FETCH_USER_BASIC_PROFILE_ERROR,
  FETCH_USER_BASIC_PROFILE_IN_PROGRESS
} from "./current-user.types";

const getIsMobile = () => {
  let isMobile = false;

  try {
    isMobile = !!((window.navigator && (window.navigator as any).standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
  } catch (ex) {
    // continue regardless of error
  }

  return isMobile;
};

const loadScript = (url, callback) => {
  const script = document.createElement("script") as any;
  script.type = "text/javascript";
  script.async = true;
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    };
  } else {
    script.onload = function () {
      if (callback && typeof callback === "function") {
        callback();
      }
    };
  }
  script.src = url;
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
};

const fbParams = {
  appId: 1034058776724974,
  scope: 'public_profile,email',
  xfbml: false,
  cookie: false,
  reAuthenticate: false,
  size: 'metro',
  fields: 'name,email,picture',
  cssClass: 'kep-login-facebook',
  version: '2.9',
  language: 'en_US',
  isMobile: getIsMobile()
};

export function initFbSdk() {
  return (dispatch, getState) => {
    function loadSdkAsynchronously() {
      ((d, s, id) => {
        let js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9&appId=1034058776724974";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }

    function setFbAsyncInit() {
      const { appId, xfbml, cookie, version } = fbParams;
      window.fbAsyncInit = () => {
        window.FB.init({
          version: `v${version}`,
          appId,
          xfbml,
          cookie
        });

        dispatch({
          type: FB_AUTH_SDK_LOADED
        });
      };
    }

    const { auth } = getState();

    if(!auth.fbSdkLoaded) {
      setFbAsyncInit();
      loadSdkAsynchronously();
    }
  };
}

export function initGoogleSdk() {
  return (dispatch, getState) => {
    function loadSdkAsynchronously() {
      /*
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://apis.google.com/js/api:client.js';
      s.onload = initGoogle;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      console.log('Loading google script');
      */

      console.log('Loading google script');
      loadScript('https://apis.google.com/js/api:client.js', initGoogle);
    }

    function initGoogle() {
      console.log('init google script');
      window.gapi.load('auth2', start);
    }

    function start() {
      console.log('Starting google script');

      window.gapi.auth2.init({
        client_id: '254938412860-brjbtdujnoub9us3i9qac4go475fnog1.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        // Request scopes in addition to 'profile' and 'email'
        //scope: 'additional_scope'
      })
      .then((GoogleAuth) => {
        console.log('Loaded google script');

        window.GoogleAuth = GoogleAuth;

        dispatch({
          type: GOOGLE_AUTH_SDK_LOADED
        });
      }).catch(err => {
        console.error(err)
      });
    }

    const { auth } = getState();

    if(!auth.googleSdkLoaded) {
      loadSdkAsynchronously();
    }
  }
}

export function loginUser() {
  return { type: LOGIN_USER };
}

export function logoutUser() {
  return { type: LOGOUT_USER };
}

export function checkAuth() {
  return { type: CHECK_AUTH };
}

export function getUserBasicProfile() {
  return (dispatch, getState) => {
    const requestConfig = {
      url: process.env.API_URL + '/user/me'
    };

    dispatch({
      type: CALL_API,
      payload: {
        requestConfig: requestConfig,
        authenticated: true,
        types: [
          FETCH_USER_BASIC_PROFILE_IN_PROGRESS,
          FETCH_USER_BASIC_PROFILE,
          FETCH_USER_BASIC_PROFILE_ERROR
        ]
      }
    })
  }
}
