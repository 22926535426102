import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter, RouteComponentProps} from 'react-router';
import queryString from 'query-string';

import {Button} from 'react-foundation-components/lib/button';

import ConsumeInviteState from '../../common/consume-invite.component.state';

const styles = require('./_style.scss');
const loadingSpinnerStyles = require('../../common/loading-spinner.scss');

import * as roomActions from '../../actions/room.actions';
import * as dialogActions from '../../actions/dialog.actions';
import * as headerActions from '../../actions/header.actions';

import {INVITE_CODE_CONSUME_LOADING} from '../../reducers/room-list.reducer';
import {INVITE_CODE_CONSUME_FAILURE} from '../../reducers/room-list.reducer';
import {INVITE_CODE_CONSUME_SUCCESS} from '../../reducers/room-list.reducer';
import {AUTH_TYPE_GOOGLE} from '../../actions/types';
import {HEADER_TYPES} from '../../actions/header.type';
import {DIALOG_TYPES} from '../../actions/dialog.type';
import {IInvitePageProps} from './invitePage.type';
import {IInvitePageState} from './invitePage.type';

console.log('WASSUP module loaded');

class InvitePage extends ConsumeInviteState<IInvitePageProps, IInvitePageState> {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      inviteCode: (queryString.parse(this.props.location.search)).code as string,
    };
  }

  componentDidMount() {
    this.checkAndSendRequest(this.state.inviteCode);

    console.log('WASSUP mounted');

    this.props.headerActions.setHeaderConfig({
      type: HEADER_TYPES.BASIC,
      data: {}
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkAndSendRequest(this.state.inviteCode);
    this.checkAndRedirect(prevProps, prevState, 1500);
  }

  showLoginDialog = () => {
    this.props.dialogActions.showDialog(DIALOG_TYPES.FB_LOGIN);
  };

  getContentElem(inviteCodeConsume) {
    const codeNotSpecifiedElem = (
      <div className={styles.invitePage_wrap}>
        <h4 className={styles.pageHead}>Invite code not specified.</h4>
      </div>
    );
    
    const loadingElem = (
      <div className={styles.invitePage_wrap}>
        <h4 className={styles.pageHead}>Adding you</h4>
        <div className={loadingSpinnerStyles.loadingSpinnerWrap}>
          <div
            className={loadingSpinnerStyles.loadingSpinner} >
          </div>
        </div>
      </div>
    );

    console.log('wassup');

    if(!this.state.inviteCode) {
      return codeNotSpecifiedElem;
    }

    if(this.props.authenticated !== AUTH_TYPE_GOOGLE) {
      return (
        <div className={styles.invitePage_wrap}>
          <h4 className={styles.pageHead}>Please login to use this invite code.</h4>
          <Button onClick={this.showLoginDialog}>
            Login now
          </Button>
        </div>
      );
    }

    if(!this.state.initiatedRequest) {
      return loadingElem;
    }
  
    switch (inviteCodeConsume.status) {
      case INVITE_CODE_CONSUME_LOADING:
        return loadingElem;
      case INVITE_CODE_CONSUME_FAILURE:
        return (
          <div className={styles.invitePage_wrap}>
            <h4 className={styles.pageHead}>Invalid or expired invite code</h4>
          </div>
        );
      case INVITE_CODE_CONSUME_SUCCESS:
        return (
          <div className={styles.invitePage_wrap}>
            <h4 className={styles.pageHead}>Success! Redirecting you...</h4>
          </div>
        );
      default:
        return codeNotSpecifiedElem;
    }
  }

  render() {
    return this.getContentElem(this.props.inviteCodeConsume);
  }
}

function mapStateToProps(state) {
  return {
    inviteCodeConsume: state.roomsList.inviteCodeConsume,
    authenticated: state.auth.authenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(roomActions, dispatch),
    headerActions: bindActionCreators(headerActions, dispatch),
    dialogActions: bindActionCreators(dialogActions, dispatch),
  };
}

// export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(withRouter(InvitePage));
export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(InvitePage);