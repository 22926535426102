import axios from 'axios';
import uuidV4 from 'uuid/v4';
import JwtDecode from 'jwt-decode';

import {
  AUTH_USER_BASIC,
  AUTH_USER_FB,
  AUTH_TYPE_BASIC,

  CALL_API,
  LOGIN_USER,
  LOGOUT_USER,
  CHECK_AUTH, AUTH_USER_GOOGLE
} from '../actions/types';
import {
  getAccessToken,
  signOut,
  signInToGoogle
} from './helper/auth-helper';

function handleApi(store, requestConfig, authenticated) {
  if(authenticated) {
    return getAccessToken(store.dispatch, store.getState)
      .then((authInfo) => {
        const updatedRequestConfig = {
          ...requestConfig,
          headers: {'x-access-token': authInfo.token.accessToken}
        };

        return axios(updatedRequestConfig);
      })

      // Implement proper response checking for both, api call and getAccessToken

  } else {
    return axios(requestConfig);
  }
}

function consumeCallApiAction(apiRequest, store, next) {
  let { requestConfig, types, authenticated } = apiRequest;
  const [ requestType, successType, errorType ] = types;
  const requestId = uuidV4();

  store.dispatch({
    type: requestType,
    payload: {
      requestId: requestId
    }
  });

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret requests
  return handleApi(store, requestConfig, authenticated)
    .then( response => {
      next({
             response,
             requestId: requestId,
             authenticated,
             type: successType
           })
    })
    .catch( error => {
      console.log('API Error', error);
      next({
             error: error.message || 'There was an error.',
             requestId: requestId,
             type: errorType
           })
    })
}

function consumeLoginAction(store) {
  signInToGoogle(store.dispatch, store.getState);
}

function consumeLogoutAction(store) {
  signOut(store.dispatch);
}

function consumeCheckAuth(store) {
  getAccessToken(store.dispatch, store.getState).then((authInfo) => {
    const actionType = authInfo.authType === AUTH_TYPE_BASIC ?
      AUTH_USER_BASIC :
      AUTH_USER_GOOGLE;

    let decodedAccessToken = JwtDecode(authInfo.token.accessToken);
    let googleId = decodedAccessToken.googleId,
        displayName = decodedAccessToken.displayName,
        dfId = decodedAccessToken.dfId;

    store.dispatch({
      type: actionType,
      payload: {
        googleId,
        displayName,
        dfId
      }
    });
  });
}

export default store => next => action => {
  switch(action.type) {
    case CALL_API:
      return consumeCallApiAction(action.payload, store, next);
    case LOGIN_USER:
      return consumeLoginAction(store);
    case LOGOUT_USER:
      return consumeLogoutAction(store);
    case CHECK_AUTH:
      return consumeCheckAuth(store);
  }

  // console.log('Pass through', action);
  return next(action);
}