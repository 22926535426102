/**
 * Created by anirudh on 29/09/17.
 */

export const USER_FOLLOW_IN_PROGRESS = 'user-follow-in-progress';
export const USER_FOLLOW_SUCCESS = 'user-follow-success';
export const USER_FOLLOW_ERROR = 'user-follow-in-progress';

export const USER_UNFOLLOW_IN_PROGRESS = 'user-follow-in-progress';
export const USER_UNFOLLOW_SUCCESS = 'user-follow-success';
export const USER_UNFOLLOW_ERROR = 'user-follow-in-progress';

export const FETCH_USER_PROFILE_IN_PROGRESS = 'fetch-user-profile-in-progress';
export const FETCH_USER_PROFILE = 'fetch-user-profile';
export const FETCH_USER_PROFILE_ERROR = 'fetch-user-profile-error';

export const FETCH_USER_BASIC_PROFILE_IN_PROGRESS = 'fetch-user-basic-profile-in-progress';
export const FETCH_USER_BASIC_PROFILE = 'fetch-user-basic-profile';
export const FETCH_USER_BASIC_PROFILE_ERROR = 'fetch-user-basic-profile-error';

export const FETCH_USER_FOLLOWERS_IN_PROGRESS = 'fetch-user-followers-in-progress';
export const FETCH_USER_FOLLOWERS = 'fetch-user-followers';
export const FETCH_USER_FOLLOWERS_ERROR = 'fetch-user-followers-error';

export const FETCH_USER_MIXES_IN_PROGRESS = 'fetch-user-mixes-in-progress';
export const FETCH_USER_MIXES = 'fetch-user-mixes';
export const FETCH_USER_MIXES_ERROR = 'fetch-user-mixes-error';

export const INITIALISE_PROFILE_STATE = 'initialise-profile-state';