export const PREFS_SET = 'prefs-set';

export const PREF_KEY = {
  VIDEO_SIZE_MODE: 'video-size-mode',
  LOCAL_SESSION_ID: 'local-session-id',
  TUTORIAL_DESKTOP_ONBOARDING: 'tutorial-desktop-onboarding',
  TUTORIAL_MOBILE_ONBOARDING: 'tutorial-mobile-onboarding',
};

export const PREF_VIDEO_SIZE_MODE = {
  THEATRE: 'THEATRE',
  MINI: 'MINI'
};
