import axios from 'axios';
import JwtDecode from 'jwt-decode';
import {
  AUTH_INFO_LOCAL_STORAGE,
  AUTH_USER_BASIC,
  UNAUTH_USER,
  AUTH_ERROR,
  AUTH_TYPE_BASIC,
  REFRESHING_TOKEN,
  DONE_REFRESHING_TOKEN,
  LOGGING_IN_TO_DF,
  DONE_LOGGING_IN_TO_DF, AUTH_TYPE_GOOGLE, AUTH_USER_GOOGLE,
} from '../../actions/types';
import {IAuthInfo} from './auth.type';

const ROOT_URL = process.env.API_URL;

// Configure
/*
Promise.config({
  longStackTraces: true,
  warnings: true // note, run node with --trace-warnings to see full stack traces for warnings
});
*/

function isTokenExpired(jwtoken) {
  //console.log('Checking expiry', jwtoken);

  return jwtoken.exp < Date.now() / 1000;
}

function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}

function loginToDfWithAuth(dispatch, authType = AUTH_TYPE_BASIC, requestData = {}): Promise<IAuthInfo> {
  console.log(`logging into DF with ${authType} auth`, requestData);

  const loginToDfPromise: Promise<IAuthInfo> =  axios
  .post(`${ROOT_URL}/auth`, requestData)
  .then(response => {
    const authInfo: IAuthInfo = {
      authType: authType,
      token: response.data.token
    };
    const authenticateAction = authType === AUTH_TYPE_BASIC ?
      AUTH_USER_BASIC :
      AUTH_USER_GOOGLE;

    localStorage.setItem(AUTH_INFO_LOCAL_STORAGE, JSON.stringify(authInfo));

    let decodedAuthToken = JwtDecode(authInfo.token.accessToken);
    let googleId = decodedAuthToken.googleId;
    let displayName = decodedAuthToken.displayName;
    let dfId = decodedAuthToken.dfId;

    dispatch({
      type: authenticateAction,
      payload: {
        googleId,
        displayName,
        dfId
      }
    });

    /*
    dispatch({
       type: authType,
       payload: {
         fbId,
         fbName,
         dfId
       }
     });
     */

    return(authInfo);
  })
  .catch(() => {
    dispatch({
      type: DONE_LOGGING_IN_TO_DF
    });
    dispatch(authError('Bad Login Info'));

    throw('Login failure');
  });

  dispatch({
    type: LOGGING_IN_TO_DF,
    loginToDfPromise
  });

  return loginToDfPromise;
}

function loginToDfWithBasicAuth(dispatch) {
  return loginToDfWithAuth(dispatch);
}

function loginToDfWithGoogleAuth(dispatch, google_id_Token) {
  return loginToDfWithAuth(dispatch, AUTH_TYPE_GOOGLE, { google_id_Token: google_id_Token });
}

function checkFbConnected(getState) {
  return new Promise(function (resolve, reject) {
    const { auth } = getState();

    if(!auth.fbSdkLoaded) {
      reject('FB SDK not loaded yet!');
    }

    window.FB.getLoginStatus((response) => {
      if(response.status === 'connected') {
        resolve(response.authResponse.accessToken);
      } else {
        reject(response);
      }
    });
  });
}

function checkGoogleConnected(getState) {
  return new Promise(function (resolve, reject) {
    const { auth } = getState();

    if(!auth.googleSdkLoaded) {
      reject('Google SDK not loaded yet!');
    }

    const googleUser = window.GoogleAuth.currentUser.get();

    if(googleUser && googleUser.isSignedIn()) {
      const authResp = googleUser.getAuthResponse(true);

      resolve(authResp.id_token);
    } else {
      reject();
    }
  });
}

// function trySilentFbConnectAndLoginToDf(dispatch, getState) {
//   return checkFbConnected(getState)
//   .then((fbAccessToken) => {
//     return loginToDfWithFbAuth(dispatch, fbAccessToken);
//   })
// }

function trySilentGoogleConnectAndLoginToDf(dispatch, getState) {
  return checkGoogleConnected(getState)
    .then((googleAccessToken) => {
      return loginToDfWithGoogleAuth(dispatch, googleAccessToken);
    })
}

function loginToDf(dispatch, getState, trySilentGoogle = false): Promise<IAuthInfo> {
  return new Promise(function (resolve) {
    if(trySilentGoogle) {
      console.log('Trying silent auth');
      trySilentGoogleConnectAndLoginToDf(dispatch, getState)
      .then((authInfo) => {
        resolve(authInfo);
      })
      .catch((err) => {
        console.log('Silent Google login failed.', err);
        resolve(loginToDfWithBasicAuth(dispatch));
      });
    } else {
      resolve(loginToDfWithBasicAuth(dispatch));
    }
  });
}

function saveTokenInfoToLocalStorage(authInfo) {
  localStorage.setItem(AUTH_INFO_LOCAL_STORAGE, JSON.stringify(authInfo));
}

function refreshToken(dispatch, getState, authInfo) {
  const refreshTokenPromise =
  axios
    .post(
      `${ROOT_URL}/auth/token`,
      { refreshToken: authInfo.token.refreshToken }
    )
    .then(response => {
      dispatch({
        type: DONE_REFRESHING_TOKEN
      });

      authInfo.token.accessToken = response.data.token.accessToken;
      saveTokenInfoToLocalStorage(authInfo);

      return authInfo;
    })
    .catch(err => {
      dispatch({
        type: DONE_REFRESHING_TOKEN
      });

      throw err;
    });

  dispatch({
    type: REFRESHING_TOKEN,
    refreshTokenPromise
  });

  return refreshTokenPromise;
}

function signOutAndLoginToDf(
  dispatch,
  getState,
  trySilentGoogle = false,
  resolve: (v) => void = () => {},
  reject:() => void = () => {}
) {
  let { loginToDfPromise } = getState().auth;

  signOut(dispatch);

  if(!loginToDfPromise) {
    loginToDfPromise = loginToDf(
      dispatch,
      getState,
      trySilentGoogle
    )
  }

  loginToDfPromise
  .then((latestAuthInfo) => {
    resolve(latestAuthInfo);
  })
  .catch(reject);
}

export function signOut(dispatch) {
  localStorage.removeItem(AUTH_INFO_LOCAL_STORAGE);
  dispatch({
    type: UNAUTH_USER
  });
}

export function getAccessToken(dispatch, getState): Promise<IAuthInfo> {
  return new Promise(function (resolve, reject) {
    const authInfoString = localStorage.getItem(AUTH_INFO_LOCAL_STORAGE);

    if(authInfoString) {
      const authInfo = JSON.parse(authInfoString);

      //const decodedAccessToken = jwt.decode(authInfo.token.accessToken);
      const decodedAccessToken = JwtDecode(authInfo.token.accessToken);

      const isExpired = isTokenExpired(decodedAccessToken);

      if(isExpired) {
        let { refreshTokenPromise } = getState().auth;

        if(!refreshTokenPromise) {
          refreshTokenPromise = refreshToken(dispatch, getState, authInfo);
        }

        refreshTokenPromise
          .then((refreshedAuthInfo) => {
            resolve(refreshedAuthInfo);
          })
          .catch((err) => {
            console.log('Refreshing token failed', err);
            signOutAndLoginToDf(
              dispatch,
              getState,
              // We try to silently login
              authInfo.authType === AUTH_TYPE_GOOGLE,
              resolve,
              reject
            );
          });


      } else {
        resolve(authInfo);
      }
    } else {
      signOutAndLoginToDf(
        dispatch,
        getState,
        false,
        resolve,
        reject
      );
    }
  });
}

export function signInToGoogle(dispatch, getState) {
  return new Promise(function (resolve, reject) {
    const { auth } = getState();

    if(!auth.googleSdkLoaded) {
      reject('Google SDK not loaded yet!');
    }

    window.GoogleAuth
      .signIn()
      .then(GoogleUser => {
        const authResp = GoogleUser.getAuthResponse(true);

        console.log('Google login succeeded', authResp, 'ID', GoogleUser.getId());

        resolve(loginToDfWithGoogleAuth(
          dispatch,
          authResp.id_token
        ));
      })
      .catch(err => {
        reject(err);
      });
  });
}