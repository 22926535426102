export const AUTH_USER_BASIC = 'auth-user-basic';
export const AUTH_USER_FB = 'auth-user-fb';
export const AUTH_USER_GOOGLE = 'auth-user-google';
export const UNAUTH_USER = 'unauth-user';
export const AUTH_ERROR = 'auth-error';
export const REFRESHING_TOKEN = 'refreshing-token';
export const DONE_REFRESHING_TOKEN = 'done-refreshing-token';
export const LOGGING_IN_TO_DF = 'logging-in-to-df';
export const DONE_LOGGING_IN_TO_DF = 'done-logging-in-to-df';
export const FB_AUTH_SDK_LOADED = 'fb-auth-sdk-loaded';
export const GOOGLE_AUTH_SDK_LOADED = 'google-auth-sdk-loaded';
export const NO_OP = 'no-op';

// Room actions
export const ROOM_UPDATE_STORE = Symbol('room-update-store');
export const ROOM_QUEUE_TRACK = Symbol('room-queue-track');
export const ROOM_QUEUE_NEXT_TRACK_AND_PLAY = Symbol('room-queue-next-track-and-play');
export const ROOM_QUEUE_NEXT_TRACK = Symbol('room-queue-next-track');
export const ROOM_PLAY_NEXT_TRACK = Symbol('room-play-next-track');
export const ROOM_REMOVE_TRACK = Symbol('room-remove-track');
export const ROOM_REMOVE_TRACK_SUGGESTION_RESULTS = Symbol('room-remove-track-suggestion-results');
export const ROOM_CLEAR_TRACKS = Symbol('room-clear-tracks');
export const ROOM_UPVOTE_TRACK = Symbol('room-upvote-track');
export const ROOM_DOWNVOTE_TRACK = Symbol('room-downvote-track');
export const ROOM_CREATE = 'room-create';
export const ROOM_CREATE_IN_PROGRESS = 'room-create-in-progress';
export const ROOM_CREATE_ERROR = 'room-create-error';
export const ROOM_CLEAR_CURRENT = 'room-clear-current';
export const ROOM_RESET_CURRENT = 'room-reset-current';
export const ROOM_FETCH_SUGGESTIONS = 'room-fetch-suggestions';
export const ROOM_FETCH_SUGGESTIONS_IN_PROGRESS = 'room-fetch-suggestions-in-progress';
export const ROOM_FETCH_SUGGESTIONS_ERROR = 'room-fetch-suggestions-error';
export const ROOM_LOGIN_SUCCESS = 'room-login-success';
export const ROOM_LOGIN_FAILURE = 'room-login-error';
export const ROOM_LOGIN_IN_PROGRESS = 'room-login-in-progress';
export const ROOM_INVITE_GENERATE_SUCCESS = 'room-generate-invite-success';
export const ROOM_INVITE_GENERATE_FAILURE = 'room-generate-invite-error';
export const ROOM_INVITE_GENERATE_IN_PROGRESS = 'room-generate-invite-in-progress';
export const ROOM_INVITE_CONSUME_SUCCESS = 'room-consume-invite-success';
export const ROOM_INVITE_CONSUME_FAILURE = 'room-consume-invite-error';
export const ROOM_INVITE_CONSUME_IN_PROGRESS = 'room-consume-invite-in-progress';
export const ROOM_INVITE_CONSUME_CLEAR = 'room-consume-invite-clear';

export const ROOM_CLEAR_ALL_LISTS = 'room-clear-all-lists';
export const ROOM_FETCH_PRIVATE_LIST = 'room-fetch-private-list';
export const ROOM_FETCH_PRIVATE_LIST_IN_PROGRESS = 'room-fetch-private-list-in-progress';
export const ROOM_FETCH_PRIVATE_LIST_ERROR = 'room-fetch-private-list-error';
export const ROOM_FETCH_PUBLIC_LIST = 'room-fetch-public-list';
export const ROOM_FETCH_PUBLIC_LIST_IN_PROGRESS = 'room-fetch-public-list-in-progress';
export const ROOM_FETCH_PUBLIC_LIST_ERROR = 'room-fetch-public-list-error';
export const ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST = 'room-fetch-public-accessible-list';
export const ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS = 'room-fetch-public-accessible-list-in-progress';
export const ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_ERROR = 'room-fetch-public-accessible-list-error';
export const ROOM_FETCH_SOLO_LIST = 'room-fetch-solo-list';
export const ROOM_FETCH_SOLO_LIST_IN_PROGRESS = 'room-fetch-solo-list-in-progress';
export const ROOM_FETCH_SOLO_LIST_ERROR = 'room-fetch-solo-list-error';
export const ROOM_FETCH_USER_LIST = 'room-fetch-user-list';
export const ROOM_FETCH_USER_LIST_IN_PROGRESS = 'room-fetch-user-list-in-progress';
export const ROOM_FETCH_USER_LIST_ERROR = 'room-fetch-user-list-error';

// Websocket actions
export const SOCKET_CONNECT = Symbol('socket-connect');
export const SOCKET_DISCONNECT = Symbol('socket-disconnect');
export const SOCKET_SEND_EVENT = Symbol('socket-send-event');
export const SOCKET_SEND_AUTHENTICATED_EVENT = Symbol('socket-send-authenticated-event');
export const SOCKET_STATUS_CONNECTING = 'socket-status-connecting';
export const SOCKET_STATUS_CONNECTED = 'socket-status-connected';
export const SOCKET_STATUS_DISCONNECTED = 'socket-status-disconnected';
export const SOCKET_FLUSH_PENDING_MESSAGES = Symbol('SOCKET_FLUSH_PENDING_MESSAGES');
export const SOCKET_FLUSH_PENDING_MESSAGES_ADD = Symbol('SOCKET_FLUSH_PENDING_MESSAGES_ADD');
export const SOCKET_FLUSH_PENDING_MESSAGES_REMOVE = Symbol('SOCKET_FLUSH_PENDING_MESSAGES_REMOVE');
export const SOCKET_PENDING_MESSAGES_FLUSHED = Symbol('SOCKET_PENDING_MESSAGES_FLUSHED');
export const SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS = Symbol('SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS');

// ENUMS
export const AUTH_TYPE_NONE = 'auth-type-none';
export const AUTH_TYPE_BASIC = 'auth-type-basic';
export const AUTH_TYPE_FB = 'auth-type-fb';
export const AUTH_TYPE_GOOGLE = 'auth-type-google';
export const AUTH_INFO_LOCAL_STORAGE = 'auth-info-local-storage';

export const CALL_API = Symbol('Call API');
export const LOGIN_USER = Symbol('Login User');
export const LOGOUT_USER = Symbol('Logout User');
export const CHECK_AUTH = Symbol('Check Auth');