import io from 'socket.io-client';
import uuidV4 from 'uuid/v4';

import * as wsActions from '../actions/ws.actions';
import * as roomActions from '../actions/room.actions';
import {WsEvents} from '../constants/ws.events';
import {SOCKET_CONNECT} from '../actions/types';
import {SOCKET_DISCONNECT} from '../actions/types';
import {SOCKET_SEND_EVENT} from '../actions/types';
import {SOCKET_SEND_AUTHENTICATED_EVENT} from '../actions/types';
import {IWsClientEvent} from './ws.type';
import {getAccessToken} from './helper/auth-helper';
import {IAuthInfo} from './helper/auth.type';
import {ROOM_LOGIN_SUCCESS} from '../actions/types';

const passThroughMessages = [
  WsEvents.CLIENT_LOGIN,
  WsEvents.CLIENT_ROOM_LOGIN,
];

const socketMiddleware = (function(){
  let socket = null;

  const addPendingMessage = (store, wsEvent: IWsClientEvent) => {
    if (passThroughMessages.indexOf(wsEvent.eventType) <= -1) {
      store.dispatch(wsActions.addPendingMessages(wsEvent));
    }
  };

  const removePendingMessage = (store, wsEvent: IWsClientEvent) => {
    if (passThroughMessages.indexOf(wsEvent.eventType) <= -1) {
      store.dispatch(wsActions.removePendingMessages(wsEvent));
    }
  };

  const emitEvent = (ws, store, wsEvent: IWsClientEvent) => {
    // console.log('DEBUGG', 'emitting', wsEvent);

    addPendingMessage(store, wsEvent);

    if (wsEvent.isAuthenticated) {
      getAccessToken(store.dispatch, store.getState)
        .then((authInfo: IAuthInfo) => {
        const accessToken = authInfo.token.accessToken;

          // console.log('DEBUGG', 'Auth', {
          //   ...wsEvent.payload,
          //   accessToken,
          // });

        socket.emit(
          wsEvent.eventType,
          {
            ...wsEvent.payload,
            accessToken,
          },
          () => {
            // console.log('DEBUGG', 'Successfull auth remove', wsEvent.eventType);
            removePendingMessage(store, wsEvent);
          }
        );
      });
    } else {
      socket.emit(
        wsEvent.eventType,
        wsEvent.payload,
        () => {
          // console.log('DEBUGG', 'Successfull remove', wsEvent.eventType);
          removePendingMessage(store, wsEvent);
        }
      );
    }
  };

  const flushPendingMessages = (ws, store) => {
    const pendingMessages = store.getState().ws.pendingWsMessages.slice();
    const messageQueueSize = pendingMessages.length;

    if (messageQueueSize > 0) {
      store.dispatch(wsActions.pendingMessagesFlushInProgress());

      for (let i=0; i < messageQueueSize; i++) {
        const wsEvent: IWsClientEvent = pendingMessages.shift();

        emitEvent(ws ,store, wsEvent);
      }

      setTimeout(() => {
        store.dispatch(wsActions.pendingMessagesFlushComplete());

        const currentRoomName = store.getState().currentRoom.roomName;

        if (currentRoomName !== '') {
          store.dispatch(roomActions.loginOverSocket(currentRoomName));
        }
      }, 3000);
    }
  };

  const onConnect = (ws, store) => evt => {
    //Send a handshake, or authenticate with remote end
    //Tell the store we're connected
    store.dispatch(wsActions.connected());
    store.dispatch(wsActions.loginOverSocket());
  };

  const onDisconnect = (ws, store) => evt => {
    //Tell the store we've disconnected
    store.dispatch(wsActions.disconnected());
  };

  const onMessage = (ws, store) => evt => {
    store.dispatch(wsActions.messageReceived(evt));
  };

  const initialiseSocketListeners = (wskt, store) => {
    wskt.on('connect', onConnect(wskt, store));
    wskt.on('disconnect', onDisconnect(wskt, store));
    wskt.on(WsEvents.SERVER_ON_MESSAGE, onMessage(wskt, store));
  };

  const teardownSocketListeners = (wskt) => {
    wskt.off('connect');
    wskt.off('disconnect');
    wskt.off(WsEvents.SERVER_ON_MESSAGE);
  };

  return store => next => action => {
    switch(action.type) {

      //The user wants us to connect
      case SOCKET_CONNECT:
        console.log('*** Connecting socket ');

        //Start a new connection to the server
        if(socket != null) {
          teardownSocketListeners(socket);
          socket.close();
        }

        //Send an action that shows a "connecting..." status for now
        store.dispatch(wsActions.connecting());

        /*
        //Attempt to connect (we could send a 'failed' action on error)
        socket = new WebSocket(action.url);
        socket.onmessage = onMessage(socket,store);
        socket.onclose = onClose(socket,store);
        socket.onopen = onOpen(socket,store,action.token);
        */

        //socket = io.connect({'forceNew':true });
        socket = io.connect({
          transports: ['websocket'],
          reconnectionDelayMax: 2000,
        });

        initialiseSocketListeners(socket, store);

        break;

      //The user wants us to disconnect
      case SOCKET_DISCONNECT:
        if(socket != null) {
          teardownSocketListeners(socket);
          socket.close();
        }
        socket = null;

        //Set our state to disconnected
        store.dispatch(wsActions.disconnected());
        break;

      //Send the 'SEND_MESSAGE' action down the websocket to the server
      case SOCKET_SEND_EVENT:
        // console.log("new action", action.eventData, action.eventType)
        const wsEvent: IWsClientEvent = {
          uid: uuidV4(),
          eventType: action.eventType,
          isAuthenticated: false,
          payload: {
            roomId: store.getState().currentRoom.uid,
            eventData: action.eventData
          }
        };

        emitEvent(socket, store, wsEvent);

        break;

      //Send the 'SEND_MESSAGE' action down the websocket to the server
      case SOCKET_SEND_AUTHENTICATED_EVENT:
        console.log('*** Draining ', action.eventType);
        const wsAuthEvent: IWsClientEvent = {
          uid: uuidV4(),
          eventType: action.eventType,
          isAuthenticated: true,
          payload: {
            roomId: store.getState().currentRoom.uid,
            eventData: action.eventData,
          }
        };

        emitEvent(socket, store, wsAuthEvent);

        break;

      // case SOCKET_FLUSH_PENDING_MESSAGES:
      //   flushPendingMessages(socket, store);
      //   break;

      case ROOM_LOGIN_SUCCESS:
        flushPendingMessages(socket, store);
        next(action);
        break;

      //This action is irrelevant to us, pass it on to the next middleware
      default:
        // console.log('Pass through ws middleware', action);
        return next(action);
    }
  }

})();

export default socketMiddleware