import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {Button} from 'react-foundation-components/lib/button';
import {MenuItem} from 'react-foundation-components/lib/menu';
import {Link } from 'react-router-dom';
import Toggle from 'react-toggle';

import OutsideClickAlerter from '../../components/hoc/outside-click-alerter/OutsideClickAlerter';
import DropdownBox from '../../components/hoc/dropdown-box/component';

const styles = require('./_styles.scss');

import * as dialogActions from '../../actions/dialog.actions';
import * as roomActions from '../../actions/room.actions';
import * as prefActions from '../../actions/preferences.actions';
import {AuthConstants} from "../../constants/auth";
import {DIALOG_TYPES} from "../../actions/dialog.type";
import {PREF_KEY, PREF_VIDEO_SIZE_MODE} from "../../actions/preferences.types";

class RoomHeaderActions extends Component<any, any> {
  static defaultProps = {
    roomName: '...',
    roomId: '...',
    roomOwnerName: '...',
    roomOwnerDfId: '',
    accessLevel: '',
    visibility: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownShown: false
    };
  }

  onHamburgerClick = () => {
    this.setState({dropdownShown: !this.state.dropdownShown});
  };

  handleClickOutside = () => {
    this.setState({dropdownShown: false});
  };

  closeRoom = () => {
    this.props.roomActions.closeRoom(this.props.roomId);
  };

  handleVideoSizeModeChange = (event) => {
    this.props.prefActions.setPref(
      PREF_KEY.VIDEO_SIZE_MODE,
      event.target.checked ? PREF_VIDEO_SIZE_MODE.THEATRE : PREF_VIDEO_SIZE_MODE.MINI
    );
  };

  renderDropdown = () => {
    return (
      <div className={styles.roomInfoBox}>
        <span className={styles.roomNameLabel}>{this.props.roomName}</span>
        <span className={styles.roomAuthorLabel}>
          by <Link to={`/user/${this.props.roomOwnerDfId}`}>{this.props.roomOwnerName}</Link>
        </span>

        <div className={styles.lineBreak}/>

        <div>
          <label className={styles.videoSizeToggleCont}>
            <Toggle
              className={styles.videoSizeToggle}
              checked={this.props.videoSizeMode === PREF_VIDEO_SIZE_MODE.THEATRE}
              onChange={this.handleVideoSizeModeChange}
              icons={false} />
            <span className={styles.videoSizeToggleLabel}>Theatre mode</span>
          </label>
        </div>

        <div className={styles.lineBreak}/>

        {
          (
            this.props.accessLevel === AuthConstants.FullAccess
          ) &&
          <Button
            onClick={this.closeRoom}
            className={styles.roomHeaderActionBtn}
          >
            Close room
          </Button>
        }
      </div>
    );
  };

  render() {
    const {dropdownShown} = this.state;
    const dropDownElem = this.renderDropdown();

    return (
      <OutsideClickAlerter
        onOutsideClick={this.handleClickOutside}
        className={styles.wrap}
        disableCheck={!dropdownShown}>
        <Button
          className={styles.roomPropertiesBtn}
          onClick={this.onHamburgerClick}
        >
          <img
            src={require('../../../client/assets/img/three-dots-icon-2.png')}/>
        </Button>


        <DropdownBox
          isShown={dropdownShown}
        >
          {dropDownElem}
        </DropdownBox>
      </OutsideClickAlerter>
    );
  }
}

function mapStateToProps(state) {
  return {
    videoSizeMode: state.prefs[PREF_KEY.VIDEO_SIZE_MODE]
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dialogActions: bindActionCreators(dialogActions, dispatch),
    roomActions: bindActionCreators(roomActions, dispatch),
    prefActions: bindActionCreators(prefActions, dispatch)
  };
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(RoomHeaderActions);
