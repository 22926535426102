/**
 * Created by swapnil on 10/01/17.
 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import {Button} from 'react-foundation-components/lib/button';

import BasicHeader from '../header/basic/basic-header.component'
import DesktopRoomHeader from '../header/desktop-room/desktop-room-header.component'
import {HEADER_TYPES} from "../../actions/header.type";
import {DIALOG_TYPE_TO_COMPONENT_MAP} from '../../actions/dialog.type';
import InvitePage from "../invite-page/invitePage.component";

import * as wsActions from '../../actions/ws.actions';
import * as dialogActions from '../../actions/dialog.actions';
import * as globalActions from '../../actions/global.actions';

import 'react-foundation-components/lib/_typography.scss';
import '../../../client/assets/css/vendor.scss'
import {OUTSIDE_CLICK_STATUS} from '../../reducers/global.reducer';
const styles = require('./_style.scss');

// const AsyncHome = AsyncComponent(() => import('../../containers/home/component'));
// const AsyncProfilePage = AsyncComponent(() => import('../user-profile/profile.component'));
// const AsyncNotificationPage = AsyncComponent(() => import('../notifications/page/notification-page.component'));
// const AsyncRoomWrapper = AsyncComponent(() => import('../room/room.component'));
// const AsyncInvitePage = AsyncComponent(() => import('../../containers/invite-page/invitePage.component'));

function Loading() {
  return <div>Loading....</div>;
}


const AsyncHome = Loadable({
  loader: () => import('../../containers/home/component'),
  loading: Loading,
});

const AsyncProfilePage = Loadable({
  loader: () => import('../user-profile/profile.component'),
  loading: Loading,
});

const AsyncNotificationPage = Loadable({
  loader: () => import('../notifications/page/notification-page.component'),
  loading: Loading,
});

const AsyncRoomWrapper = Loadable({
  loader: () => import('../room/room.component'),
  loading: Loading,
});

const AsyncInvitePage = Loadable({
  loader: () => import('../invite-page/invitePage.component'),
  loading: Loading,
});

class Main extends Component<any, any> {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.wsActions.disconnect();
    this.props.wsActions.connect();
  }
  
  componentWillUnmount() {
    this.props.wsActions.disconnect();
  }

  handleClickInCapturePhase = (event) => {
    // To prevent events from bubbling up
    this.props.containerIdsForOutsideClickAlert.map(containerObj => {
      if(containerObj.status === OUTSIDE_CLICK_STATUS.WATCHING) {
        const containerId = containerObj.containerId;
        const containerNode = document.getElementById(containerId);

        if (containerNode && !containerNode.contains(event.target)) {
          event.stopPropagation();
          event.preventDefault();

          this.props.globalActions.setOutsideClickContainerIdStatus(
            containerId,
            OUTSIDE_CLICK_STATUS.CAUGHT
          );
        }
      }
    });
  };

  render() {
    const dialogsElem = this.renderDialogs();
    const contentStyles = styles.content + ' ' +
      (this.props.isContentBlurred ?
        styles.content__blurred :
        '');
      
    let HEADER;

    switch(this.props.headerConfig.type) {
      case HEADER_TYPES.BASIC:
        HEADER = BasicHeader;
        break;
      case HEADER_TYPES.DESKTOP_ROOM:
        HEADER = DesktopRoomHeader;
        break;
    }

    console.log('wassup main');

    return (
      <div
        className={contentStyles}
        onClickCapture={this.handleClickInCapturePhase}
      >

        { HEADER &&
          <HEADER
            isHeaderHidden={this.props.isHeaderHidden}
            isHeaderTransparent={this.props.isHeaderTransparent}
            {...this.props.headerConfig.data}
          />
        }

        <Switch>
          <Route path="/room/:roomName" component={AsyncRoomWrapper}/>
          <Route path="/user/:userId" component={AsyncProfilePage}/>
          <Route path="/notifications" component={AsyncNotificationPage}/>
          <Route path="/invite" component={InvitePage}/>
          <Route exact path="/" component={AsyncHome}/>
          <Redirect from='*' to='/' />
        </Switch>

        {dialogsElem}
      </div>
    );
  }

  renderDialogs = () => {
    return this.props.registeredDialogs.map((dialog) => {
      const {type, show, data} = dialog;
      const DialogComponent = DIALOG_TYPE_TO_COMPONENT_MAP.get(type);

      return (
        <DialogComponent
          key={type}
          show={show}
          data={data}
          onHide={() => {
            this.props.dialogActions.hideDialog(type);
          }}
          {...this.props.headerConfig.data}
        />
      );
    });
  };
}

function mapStateToProps(state) {
  return {
    isContentBlurred: state.dialog.isContentBlurred,
    isHeaderHidden: state.header.isHidden,
    isHeaderTransparent: state.header.isTransparent,
    headerConfig: state.header.config,
    registeredDialogs: state.dialog.registeredDialogs,
    containerIdsForOutsideClickAlert: state.global.containerIdsForOutsideClickAlert,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    wsActions: bindActionCreators(wsActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch),
    dialogActions: bindActionCreators(dialogActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);