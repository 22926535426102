import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

// Foundation Components
import Toggle from 'react-toggle';
import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';
import {Button} from 'react-foundation-components/lib/button';
import {FormField} from 'react-foundation-components/lib/forms';
import {FormFieldInput} from 'react-foundation-components/lib/forms';
import {FormFieldError} from 'react-foundation-components/lib/forms';
import Reveal from 'react-foundation-components/lib/reveal';
import CloseButton from 'react-foundation-components/lib/close-button';

import {CREATE_ROOM_REQUEST_IN_PROGRESS} from '../../../reducers/room-list.reducer'
import {CREATE_ROOM_REQUEST_ERROR} from '../../../reducers/room-list.reducer'
import {CREATE_ROOM_REQUEST_NONE} from '../../../reducers/room-list.reducer'
import * as roomActions from '../../../actions/room.actions';
import CheckAuthButton from '../../hoc/CheckAuthButton';
import {PREF_KEY} from '../../../actions/preferences.types';
import ViewportUtil from '../../../utils/viewportUtil';
import {AUTH_TYPE_GOOGLE} from '../../../actions/types';
import {ICreateRoomStateFromProps} from './createRoom.type';
import {ICreateRoomSuppliedProps} from './createRoom.type';
import {ICreateRoomRouterProps} from './createRoom.type';
import {ICreateRoomState} from './createRoom.type';
import {ICreateRoomProps} from './createRoom.type';

const styles = require('./_style.scss');

class CreateRoom extends Component<ICreateRoomProps, ICreateRoomState> {
  static defaultProps: ICreateRoomSuppliedProps = {
    onHide: () => {},
    show: false
  };

  constructor(props) {
    super(props);

    this.state = {
      roomName: '',
      roomVisibility: 'PRIVATE',
      roomNameError: false,

      /*
        createRoomClickBlockedByAuthInfo: {
          roomName: '',
          roomVisibility: ''
        }
       */
      createRoomClickBlockedByAuthInfo: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.createRoomRequest === CREATE_ROOM_REQUEST_IN_PROGRESS &&
      (this.props.createRoomRequest !== CREATE_ROOM_REQUEST_ERROR ||
        this.props.createRoomRequest !== CREATE_ROOM_REQUEST_NONE)) {
      // We need to redirect to latest room
      this.props.history.push('/room/' + this.props.createRoomRequest.name);
    }

    if(
      prevProps.show !== this.props.show &&
      !this.props.show &&
      this.state.createRoomClickBlockedByAuthInfo !== null
    ) {
      // Dialog is hidden now

      this.setState({createRoomClickBlockedByAuthInfo: null});
    }

    if(prevProps.authenticated !== this.props.authenticated && this.props.authenticated === AUTH_TYPE_GOOGLE) {
      // User now logged in

      const createRoomClickBlockedByAuthInfo = this.state.createRoomClickBlockedByAuthInfo;

      if(
        createRoomClickBlockedByAuthInfo !== null &&
        this.props.createRoomRequest === CREATE_ROOM_REQUEST_NONE
      ) {
        this.setState({createRoomClickBlockedByAuthInfo: null});
        this.createRoomWithParams(
          createRoomClickBlockedByAuthInfo.roomName,
          createRoomClickBlockedByAuthInfo.roomVisibility,
        );
      }
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + (string.slice(1)).toLowerCase();
  }

  handleRoomNameValChange(event) {
    this.setState({
      roomName: event.target.value,
      roomNameError: false,
      createRoomClickBlockedByAuthInfo: null,
    });
  }

  handleRoomVisiblityChange = (event) => {
    this.setState({
      roomVisibility: event.target.checked ? 'PUBLIC' : 'PRIVATE',
      createRoomClickBlockedByAuthInfo: null,
    });
  };

  onCreateRoomClicked = () => {
    const {roomName, roomVisibility} = this.state;

    this.createRoomWithParams(roomName, roomVisibility);
  };

  onCreateRoomClickAndAuthCheckFail = () => {
    this.setState({
      createRoomClickBlockedByAuthInfo: {
        roomName: this.state.roomName,
        roomVisibility: this.state.roomVisibility
      }
    });
  };

  createRoomWithParams(roomName, roomVisibility) {
    if (roomName.trim().toLowerCase() !== '' || roomVisibility === 'SOLO') {
      const newRoomName = roomName.trim().toLowerCase().replace(/ /g, '-');
      const singlePlayingDeviceInfo = {
        sessionId: this.props.localSessionId,
        device: ViewportUtil.getDevice()
      };

      // We are now defaulting to party mode
      this.props.createRoom(
        newRoomName,
        roomVisibility,
        singlePlayingDeviceInfo,
        true,
      );

      this.props.onHide();
    } else {
      this.setState({roomNameError: true});
    }
  }

  render(){
    return(
      <Reveal
        revealClassName={styles.createRoomDialog}
        onHide={this.props.onHide}
        show={this.props.show} >
        <CloseButton
          onClick={this.props.onHide} />

        <FlexRow horizontalAlignment="center">
          <FlexColumn
            small={8}
            medium={6}
            large={6}
            className={styles.content}
          >
            <h4 className={styles.headTitle}>Create Room</h4>
            <FormField
              className={styles.roomNameInputCont}
              error={this.state.roomNameError} >
              <FormFieldInput
                value={this.state.roomName}
                className={styles.roomNameInput}
                placeholder="Name"
                onChange={this.handleRoomNameValChange.bind(this)} />
              <FormFieldError>Please fill out the room name</FormFieldError>
            </FormField>

            <label className={styles.roomVisibitlity_labelCont}>
              <span className={styles.roomVisibitlity_toggleLabel}>Publicly visible</span>
              <Toggle
                className={styles.roomVisibitlity_toggle}
                onChange={this.handleRoomVisiblityChange}
                checked={this.state.roomVisibility === 'PUBLIC'}
                icons={false} />
            </label>

            <CheckAuthButton
              className={styles.createRoomSubmit}
              onClickAndAuthCheckFail={this.onCreateRoomClickAndAuthCheckFail}
              onClick={this.onCreateRoomClicked} >
              Start
            </CheckAuthButton>
          </FlexColumn>
        </FlexRow>
      </Reveal>
    );
  }

}

function mapStateToProps(state): ICreateRoomStateFromProps {
  return {
    createRoomRequest: state.roomsList.createRoomRequest,
    localSessionId: state.prefs[PREF_KEY.LOCAL_SESSION_ID],

    authenticated: state.auth.authenticated,
  };
}

// export default connect<ICreateRoomStateFromProps, any, ICreateRoomRouterProps>(mapStateToProps, roomActions)(withRouter<ICreateRoomProps>(CreateRoom));
export default connect<ICreateRoomStateFromProps, any, ICreateRoomRouterProps>(mapStateToProps, roomActions)(withRouter(CreateRoom));