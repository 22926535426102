import {ROOM_CREATE} from './types';
import {ROOM_CREATE_IN_PROGRESS} from './types';
import {ROOM_CREATE_ERROR} from './types';
import {ROOM_FETCH_SUGGESTIONS} from './types';
import {ROOM_FETCH_SUGGESTIONS_IN_PROGRESS} from "./types";
import {ROOM_FETCH_SUGGESTIONS_ERROR} from './types';
import {CALL_API} from './types';
import {ROOM_UPDATE_STORE} from './types';
import {ROOM_QUEUE_TRACK} from './types';
import {ROOM_QUEUE_NEXT_TRACK_AND_PLAY} from './types';
import {ROOM_QUEUE_NEXT_TRACK} from './types';
import {ROOM_PLAY_NEXT_TRACK} from './types';
import {ROOM_REMOVE_TRACK} from './types';
import {ROOM_REMOVE_TRACK_SUGGESTION_RESULTS} from './types';
import {ROOM_CLEAR_TRACKS} from './types';
import {ROOM_LOGIN_SUCCESS} from './types';
import {ROOM_LOGIN_FAILURE} from './types';
import {ROOM_CLEAR_CURRENT} from './types';
import {ROOM_RESET_CURRENT} from './types';
import {ROOM_LOGIN_IN_PROGRESS} from './types';
import {ROOM_UPVOTE_TRACK} from './types';
import {ROOM_DOWNVOTE_TRACK} from './types';
import {ROOM_FETCH_PUBLIC_LIST_IN_PROGRESS} from './types';
import {ROOM_FETCH_PUBLIC_LIST} from './types';
import {ROOM_FETCH_PUBLIC_LIST_ERROR} from './types';
import {ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST} from './types';
import {ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS} from './types';
import {ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_ERROR} from './types';
import {ROOM_FETCH_PRIVATE_LIST_IN_PROGRESS} from './types';
import {ROOM_FETCH_PRIVATE_LIST} from './types';
import {ROOM_FETCH_PRIVATE_LIST_ERROR} from './types';
import {ROOM_FETCH_SOLO_LIST_IN_PROGRESS} from './types';
import {ROOM_FETCH_SOLO_LIST} from './types';
import {ROOM_FETCH_SOLO_LIST_ERROR} from './types';
import {ROOM_FETCH_USER_LIST_IN_PROGRESS} from './types';
import {ROOM_FETCH_USER_LIST} from './types';
import {ROOM_FETCH_USER_LIST_ERROR} from './types';
import {ROOM_INVITE_GENERATE_SUCCESS} from './types';
import {ROOM_INVITE_GENERATE_FAILURE} from './types';
import {ROOM_INVITE_GENERATE_IN_PROGRESS} from './types';
import {ROOM_INVITE_CONSUME_SUCCESS} from './types';
import {ROOM_INVITE_CONSUME_FAILURE} from './types';
import {ROOM_INVITE_CONSUME_IN_PROGRESS} from './types';
import {ROOM_INVITE_CONSUME_CLEAR} from './types';
import {ROOM_CLEAR_ALL_LISTS} from './types';
import {
  ROOM_START_PLAYBACK, ROOM_UPDATE_VISIBILITY, ROOM_UPDATE_VISIBILITY_ERROR,
  ROOM_UPDATE_VISIBILITY_IN_PROGRESS
} from './room.types';
import {ROOM_CLOSE} from './room.types';
import {ROOM_CLOSE_IN_PROGRESS} from './room.types';
import {ROOM_CLOSE_ERROR} from './room.types';
import {ROOM_EXPORT_MIX_SUCCESS} from './room.types';
import {ROOM_EXPORT_MIX_IN_PROGRESS} from './room.types';
import {ROOM_EXPORT_MIX_ERROR} from './room.types';
import {ROOM_LOAD_MIX_SUCCESS} from './room.types';
import {ROOM_LOAD_MIX_IN_PROGRESS} from './room.types';
import {ROOM_LOAD_MIX_ERROR} from './room.types';
import {ROOM_CLOSED_BY_EVENT} from './room.types';
import {ROOM_UPDATE_IS_SYNCED_PROP} from './room.types';
import {ROOM_UPDATE_ONLY_ONE_PLAYER_PROP} from './room.types';
import {ROOM_UPDATE_CURRENT_TRACK_INDEX} from './room.types';
import {ROOM_USER_ONLINE} from './room.types';
import {ROOM_USER_OFFLINE} from './room.types';
import {ROOM_FETCH_LIMIT} from '../reducers/helper/room-list.helper';
import {PREF_KEY} from "./preferences.types";

import * as WsActions from '../actions/ws.actions';
import {WsEvents} from '../constants/ws.events';
import ViewportUtil from '../utils/viewportUtil';
import {RoomConstants} from '../constants/room.constants';
import {ROOM_UPDATE_CURRENT_TRACK_PLAYBACK} from './room.types';
import {ROOM_PAUSE_PLAYBACK} from './room.types';
import {ROOM_SEEK_AT} from './room.types';
import {ROOM_SET_VOLUME} from './room.types';
import {ROOM_GET_TRACK_REACTIONS_INFO_IN_PROGRESS} from './room.types';
import {ROOM_GET_TRACK_REACTIONS_INFO_SUCCESS} from './room.types';
import {ROOM_GET_TRACK_REACTIONS_INFO_FAILURE} from './room.types';
import {ROOM_GET_TRACK_REACTIONS_INFO_CLEAR} from './room.types';
import {ROOM_ADD_REACTION} from './room.types';
import {ROOM_REMOVE_REACTION} from './room.types';
import {IRoomPropsUpdateRequest} from "../../server/services/roomService.type";

export function clearAllRoomList() {
  return {
    type: ROOM_CLEAR_ALL_LISTS
  }
}

export function fetchPublicRoomList(offset = 0, limit = ROOM_FETCH_LIMIT) {
  return (dispatch, getState) => {
    const isPublicRoomListLoading = getState().roomsList.allPublic.isLoadingMore;

    if(!isPublicRoomListLoading) {
      const requestConfig = {
        url: process.env.API_URL + '/room/public/',
        params: {
          offset,
          limit
        }
      };

      dispatch({
        type: CALL_API,
        payload: {
          requestConfig: requestConfig,
          authenticated: true,
          types: [
            ROOM_FETCH_PUBLIC_LIST_IN_PROGRESS,
            ROOM_FETCH_PUBLIC_LIST,
            ROOM_FETCH_PUBLIC_LIST_ERROR
          ]
        }
      })
    }
  }
}

export function fetchUsersRoomList(dfId, offset = 0, limit = ROOM_FETCH_LIMIT) {
  return (dispatch, getState) => {
    const isUsersRoomListLoading = getState().roomsList.allSelectedUsers.isLoadingMore;

    if(!isUsersRoomListLoading) {
      const requestConfig = {
        url: process.env.API_URL + '/user/' + dfId + '/rooms',
        params: {
          offset,
          limit
        }
      };

      dispatch({
        type: CALL_API,
        payload: {
          requestConfig: requestConfig,
          authenticated: true,
          types: [
            ROOM_FETCH_USER_LIST_IN_PROGRESS,
            ROOM_FETCH_USER_LIST,
            ROOM_FETCH_USER_LIST_ERROR
          ]
        }
      })
    }
  }
}

export function fetchPublicAccessibleRoomList(offset = 0, limit = ROOM_FETCH_LIMIT) {
  return (dispatch, getState) => {
    const isAccessiblePublicRoomListLoading = getState().roomsList.allAccessiblePublic.isLoadingMore;

    if(!isAccessiblePublicRoomListLoading) {
      const requestConfig = {
        url: process.env.API_URL + '/room/public/myaccess',
        params: {
          offset,
          limit
        }
      };

      dispatch({
        type: CALL_API,
        payload: {
          requestConfig: requestConfig,
          authenticated: true,
          types: [
            ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS,
            ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST,
            ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_ERROR
          ]
        }
      })
    }
  }
}

export function fetchPrivateRoomList(offset = 0, limit = ROOM_FETCH_LIMIT) {
  return (dispatch, getState) => {
    const isPrivateRoomListLoading = getState().roomsList.allPrivate.isLoadingMore;

    if(!isPrivateRoomListLoading) {
      const requestConfig = {
        url: process.env.API_URL + '/room/private/',
        params: {
          offset,
          limit
        }
      };

      dispatch({
        type: CALL_API,
        payload: {
          requestConfig: requestConfig,
          authenticated: true,
          types: [ROOM_FETCH_PRIVATE_LIST_IN_PROGRESS, ROOM_FETCH_PRIVATE_LIST, ROOM_FETCH_PRIVATE_LIST_ERROR]
        }
      })
    }
  }
}

export function fetchSoloRoomList(offset = 0, limit = ROOM_FETCH_LIMIT) {
  return (dispatch, getState) => {
    const isSoloRoomListLoading = getState().roomsList.allSolo.isLoadingMore;

    if(!isSoloRoomListLoading) {
      const requestConfig = {
        url: process.env.API_URL + '/room/solo/',
        params: {
          offset,
          limit
        }
      };

      dispatch({
        type: CALL_API,
        payload: {
          requestConfig: requestConfig,
          authenticated: true,
          types: [ROOM_FETCH_SOLO_LIST_IN_PROGRESS, ROOM_FETCH_SOLO_LIST, ROOM_FETCH_SOLO_LIST_ERROR]
        }
      })
    }
  }
}

export function fetchAllYourRooms() {
  return (dispatch, getState) => {
    const roomListState = getState().roomsList;
    
    console.log('$$ list room FETCH YOUR');

    dispatch(fetchPublicAccessibleRoomList(roomListState.allAccessiblePublic.offset));
    dispatch(fetchPrivateRoomList(roomListState.allPrivate.offset));
    // dispatch(fetchSoloRoomList(roomListState.allSolo.offset));
  }
}

export function fetchAllPublicRooms() {
  return (dispatch, getState) => {
    const roomListState = getState().roomsList;

    console.log('$$ list room FETCH PUBLIC');

    dispatch(fetchPublicRoomList(roomListState.allPublic.offset));
  }
}

export function fetchAllUsersRooms(dfId) {
  return function (dispatch, getState) {
    var roomListState = getState().roomsList;

    console.log("$$ list room FETCH USER");

    dispatch(fetchUsersRoomList(dfId, roomListState.allSelectedUsers.offset));
  };
}

export function createRoom(name, visibility, singlePlayingDeviceInfo, isSynced = true) {
  const requestConfig = {
    url: process.env.API_URL + `/room/${visibility.toLowerCase()}/`,
    method: 'post',
    data: {
      name,
      visibility,
      isSynced,
      singlePlayingDeviceInfo,
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_CREATE_IN_PROGRESS, ROOM_CREATE, ROOM_CREATE_ERROR]
    }
  }
}

export function updateStore(payload) {
  return {
    type: ROOM_UPDATE_STORE,
    payload
  }
}

export function upvoteTrack(trackInfo, eventFromFrontend = false) {
  trackInfo.isUserSuggestion = true;
  if(!trackInfo.hasOwnProperty('voters')) trackInfo.voters = "You";

  return {
    type: ROOM_UPVOTE_TRACK,
    payload: {
      trackInfo,
      eventFromFrontend: eventFromFrontend
    }
  }
}

export function downvoteTrack(trackInfo, eventFromFrontend = false) {
  return {
    type: ROOM_DOWNVOTE_TRACK,
    payload: {
      trackInfo,
      eventFromFrontend: eventFromFrontend
    }
  }
}

export function queueTrack(trackInfo, trackUserMeta, triggeredBy) {
  return {
    type: ROOM_QUEUE_TRACK,
    payload: {
      trackInfo,
      trackUserMeta,
      triggeredBy
    }
  }
}

export function queueNextTrackAndPlay(trackInfo, trackUserMeta, lastPlayingTrack, triggeredBy) {
  return {
    type: ROOM_QUEUE_NEXT_TRACK_AND_PLAY,
    payload: {
      trackInfo,
      trackUserMeta,
      lastPlayingTrack,
      triggeredBy
    }
  }
}

export function queueNextTrack(trackInfo, trackUserMeta, lastPlayingTrack, triggeredBy) {
  return {
    type: ROOM_QUEUE_NEXT_TRACK,
    payload: {
      trackInfo,
      trackUserMeta,
      lastPlayingTrack,
      triggeredBy
    }
  }
}

export function playNext(nextTrackIndex, triggeredBy) {
  return {
    type: ROOM_PLAY_NEXT_TRACK,
    payload: {
      nextTrackIndex,
      triggeredBy
    }
  }
}

export function removeTrack(trackIndex, triggeredBy) {
  return {
    type: ROOM_REMOVE_TRACK,
    payload: {
      trackIndex,
      triggeredBy
    }
  }
}

export function removeTrackFromSuggestions(trackInfo) {
  return {
    type: ROOM_REMOVE_TRACK_SUGGESTION_RESULTS,
    payload: {
      trackInfo
    }
  }
}

export function closeRoomByEvent() {
  return {
    type: ROOM_CLOSED_BY_EVENT
  }
}

export function updateCurrentTrackIndex(currentTrackIndex, triggeredBy) {
  return {
    type: ROOM_UPDATE_CURRENT_TRACK_INDEX,
    payload: {
      currentTrackIndex,
      triggeredBy
    }
  }
}

export function clearTracks(triggeredBy) {
  return {
    type: ROOM_CLEAR_TRACKS,
    payload: {
      triggeredBy
    }
  }
}

export function updateCurrentTrackPlayback(currentTrackPlaybackInfo) {
  return {
    type: ROOM_UPDATE_CURRENT_TRACK_PLAYBACK,
    payload: {
      currentTrackPlaybackInfo
    }
  }
}

export function roomUserIsOnline(userOnlineStatusInfo) {
  return {
    type: ROOM_USER_ONLINE,
    payload: {
      userOnlineStatusInfo
    }
  }
}

export function roomUserIsOffline(userOnlineStatusInfo) {
  return {
    type: ROOM_USER_OFFLINE,
    payload: {
      userOnlineStatusInfo
    }
  }
}

export function setSeekAt(seekPosInSecs, triggeredBy) {
  return {
    type: ROOM_SEEK_AT,
    payload: {
      seekPosInSecs,
      triggeredBy
    }
  }
}

export function setVolume(volume, triggeredBy) {
  return {
    type: ROOM_SET_VOLUME,
    payload: {
      volume,
      triggeredBy
    }
  }
}

export function loginSucceeded(roomData) {
  return {
    type: ROOM_LOGIN_SUCCESS,
    payload: {
      roomData
    }
  }
}

export function loginFailed(errorCode, message) {
  return {
    type: ROOM_LOGIN_FAILURE,
    payload: {
      errorCode,
      message
    }
  }
}

export function clearCurrentRoomData() {
  return {
    type: ROOM_CLEAR_CURRENT
  }
}

export function resetCurrentRoomData() {
  return {
    type: ROOM_RESET_CURRENT
  }
}

export function loggingInOverSocket() {
  return {
    type: ROOM_LOGIN_IN_PROGRESS
  }
}

export function getTrackReactionsInfo(roomId, trackIndex) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/trackReactions`,
    method: 'get',
    params: {
      roomId,
      trackIndex
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [
        ROOM_GET_TRACK_REACTIONS_INFO_IN_PROGRESS,
        ROOM_GET_TRACK_REACTIONS_INFO_SUCCESS,
        ROOM_GET_TRACK_REACTIONS_INFO_FAILURE
      ]
    }
  }
}

export function clearGetTrackReactionsInfo() {
  return {
    type: ROOM_GET_TRACK_REACTIONS_INFO_CLEAR
  }
}

export function getInviteCode(roomId, visibility) {
  const requestConfig = {
    url: process.env.API_URL + `/room/${visibility.toLowerCase()}/for/${roomId}/generateInvite`,
    method: 'get'
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_INVITE_GENERATE_IN_PROGRESS, ROOM_INVITE_GENERATE_SUCCESS, ROOM_INVITE_GENERATE_FAILURE]
    }
  }
}

export function getTrackSuggestions(roomId, visibility, currentTrackVideoId) {
  const requestConfig = {
    url: process.env.API_URL + `/room/${visibility.toLowerCase()}/for/${roomId}/suggestions?currentPlayingId=${currentTrackVideoId}`,
    method: 'get'
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [
        ROOM_FETCH_SUGGESTIONS_IN_PROGRESS,
        ROOM_FETCH_SUGGESTIONS,
        ROOM_FETCH_SUGGESTIONS_ERROR
      ]
    }
  }
}

export function consumeInviteCode(inviteCode) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/joinByInvite`,
    method: 'post',
    data: {
      inviteCode: inviteCode
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_INVITE_CONSUME_IN_PROGRESS, ROOM_INVITE_CONSUME_SUCCESS, ROOM_INVITE_CONSUME_FAILURE]
    }
  }
}

export function closeRoom(roomId) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/close`,
    method: 'post',
    data: {
      roomId
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_CLOSE_IN_PROGRESS, ROOM_CLOSE, ROOM_CLOSE_ERROR]
    }
  }
}

export function updateRoomVisibility(roomId, roomVisiblity) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/updateVisibility`,
    method: 'post',
    data: {
      roomId,
      roomVisiblity,
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_UPDATE_VISIBILITY_IN_PROGRESS, ROOM_UPDATE_VISIBILITY, ROOM_UPDATE_VISIBILITY_ERROR]
    }
  }
}

export function exportMix(roomName, mixName, updateMix) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/exportMix`,
    method: 'post',
    data: {
      roomName,
      mixName,
      updateMix
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_EXPORT_MIX_IN_PROGRESS, ROOM_EXPORT_MIX_SUCCESS, ROOM_EXPORT_MIX_ERROR]
    }
  }
}

export function loadMix(roomName, mixId) {
  const requestConfig = {
    url: process.env.API_URL + `/room/global/loadMix`,
    method: 'post',
    data: {
      roomName,
      mixId
    }
  };

  return {
    type: CALL_API,
    payload: {
      requestConfig: requestConfig,
      authenticated: true,
      types: [ROOM_LOAD_MIX_IN_PROGRESS, ROOM_LOAD_MIX_SUCCESS, ROOM_LOAD_MIX_ERROR]
    }
  }
}

export function resetConsumeInviteCode() {
  return {
    type: ROOM_INVITE_CONSUME_CLEAR
  }
}

export function addReaction(userDfId, trackIndex, reactionCode) {
  return {
    type: ROOM_ADD_REACTION,
    payload: {
      userDfId,
      trackIndex,
      reactionCode
    }
  }
}

export function removeReaction(userDfId, trackIndex) {
  return {
    type: ROOM_REMOVE_REACTION,
    payload: {
      userDfId,
      trackIndex
    }
  }
}

/*
 All functions which send events over websocket
 */

export function emitAndAddReaction(userDfId, trackIndex, reactionCode, trackInfo) {
  return (dispatch, getState) => {
    dispatch(addReaction(userDfId, trackIndex, reactionCode));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_ADD_REACTION,
      eventData: {
        trackIndex,
        reactionCode,
        trackInfo
      }
    }));
  }
}

export function emitAndRemoveReaction(userDfId, trackIndex) {
  return (dispatch, getState) => {
    dispatch(removeReaction(userDfId, trackIndex));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_REMOVE_REACTION,
      eventData: {
        trackIndex
      }
    }));
  }
}

export function loginOverSocket(roomName) {
  return (dispatch, getState) => {
    dispatch(loggingInOverSocket());
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_ROOM_LOGIN,
      eventData: {
        roomName,
        localSessionId: getState().prefs[PREF_KEY.LOCAL_SESSION_ID]
      }
    }));
  }
}

export function emitAndUpdateCurrentTrackPlayback(currentTrackPlaybackInfo) {
  return (dispatch, getState) => {
    dispatch(updateCurrentTrackPlayback(currentTrackPlaybackInfo));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_CURRENT_TRACK_PLAYBACK_INFO,
      eventData: {
        currentTrackPlaybackInfo
      }
    }));
  };
}

export function pauseRoomPlayback(triggeredBy) {
  return {
    type: ROOM_PAUSE_PLAYBACK,
    payload: {
      triggeredBy
    }
  }
}

export function resumeRoomPlayback(triggeredBy) {
  return {
    type: ROOM_START_PLAYBACK,
    payload: {
      triggeredBy
    }
  }
}

export function emitAndPauseRoomPlayback(triggeredBy) {
  return (dispatch, getState) => {
    dispatch(pauseRoomPlayback(triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_PAUSE_ROOM_PLAYBACK,
      eventData: {}
    }));
  };
}

export function emitAndResumeRoomPlayback(triggeredBy) {
  return (dispatch, getState) => {
    dispatch(resumeRoomPlayback(triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_RESUME_ROOM_PLAYBACK,
      eventData: {}
    }));
  };
}

export function emitBroadcastResync() {
  return (dispatch, getState) => {
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_BROADCAST_RESYNC,
      eventData: {}
    }));
  };
}

export function emitAndUpdateIsSynced(isSynced) {
  return (dispatch, getState) => {
    const currentRoomState = getState().currentRoom;
    const trackIds = currentRoomState.playlist.map(vid => vid.id);

    dispatch({
      type: ROOM_UPDATE_IS_SYNCED_PROP,
      payload: {
        isSynced
      }
    });

    dispatch(emitUpdateRoomProps({
      currentPlayingIndex: currentRoomState.currentTrackIndex,
      onlyOnePlayingDeviceInfo: currentRoomState.onlyOnePlayingDevice,
      trackIds,
      isSynced
    }));
  };
}

export function emitAndUpdateOnlyOnePlayingDevice(playOnlyOnThisDevice) {
  return (dispatch, getState) => {
    const currentRoomState = getState().currentRoom;
    const trackIds = currentRoomState.playlist.map(vid => vid.id);
    const device = ViewportUtil.getDevice();
    const onlyOnePlayingDevice = {
      status: playOnlyOnThisDevice ?
        RoomConstants.ONLY_ONE_PLAYING_DEVICE.CURRENT :
        RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE,
      username: '',
      device
    };

    dispatch({
      type: ROOM_UPDATE_ONLY_ONE_PLAYER_PROP,
      payload: {
        onlyOnePlayingDevice
      }
    });

    dispatch({
      type: ROOM_UPDATE_IS_SYNCED_PROP,
      payload: {
        isSynced: playOnlyOnThisDevice
      }
    });

    const newRoomProps: IRoomPropsUpdateRequest = {
      currentPlayingIndex: currentRoomState.currentTrackIndex,
      isSynced: playOnlyOnThisDevice,
      trackIds,
      onlyOnePlayingDeviceInfo: onlyOnePlayingDevice
    };

    // We also set isSynced to true if onlyOnePlayingDevice is true
    dispatch(emitUpdateRoomProps(newRoomProps));
  };
}

export function emitUpdateRoomProps(roomProps: IRoomPropsUpdateRequest) {
  return (dispatch, getState) => {
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_UPDATE_ROOM_PROPS,
      eventData: {
        roomProps,
        localSessionId: getState().prefs[PREF_KEY.LOCAL_SESSION_ID]
      }
    }));
  }
}

export function emitSeekAt(seekPosInSecs) {
  return WsActions.sendAuthenticated({
    eventType: WsEvents.CLIENT_SEEK_AT,
    eventData: {
      seekPosInSecs
    }
  })
}

export function emitSetVolume(volume) {
  return WsActions.sendAuthenticated({
    eventType: WsEvents.CLIENT_SET_VOLUME,
    eventData: {
      volume
    }
  })
}

export function emitCurrentTrackUpdate(currentTrackIndex) {
  return (dispatch, getState) => {
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_UPDATE_CURRENT_TRACK,
      eventData: {
        currentTrackIndex
      }
    }));
  }
}

export function emitTrackPlaybackStartedNotify(currentTrackIndex) {
  return (dispatch, getState) => {
    console.log('LALALALA Emitted', currentTrackIndex);

    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_NOTIFY_TRACK_PLAYBACK_STARTED,
      eventData: {
        currentTrackIndex
      }
    }));
  }
}

export function emitRoomClosed() {
  return (dispatch, getState) => {
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_CLOSE_ROOM,
      eventData: {}
    }));
  }
}

export function emitAndQueueTrack(trackInfo, trackUserMeta) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(queueTrack(trackInfo, trackUserMeta, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_QUEUE_TRACK,
      eventData: {
        trackInfo
      }
    }));
  }
}

export function emitAndQueueNextTrackAndPlay(trackInfo, trackUserMeta, currentPlayingTrack) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(queueNextTrackAndPlay(trackInfo, trackUserMeta, currentPlayingTrack, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_QUEUE_NEXT_TRACK_AND_PLAY,
      eventData: {
        trackInfo,
        lastPlayingTrack: currentPlayingTrack
      }
    }));
  }
}

export function emitAndQueueNextTrack(trackInfo, trackUserMeta, currentPlayingTrack) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(queueNextTrack(trackInfo, trackUserMeta, currentPlayingTrack, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_QUEUE_NEXT_TRACK,
      eventData: {
        trackInfo,
        lastPlayingTrack: currentPlayingTrack
      }
    }));
  }
}

export function queueNextTrackAndPlayButEmitQueueNext(trackInfo, trackUserMeta, currentPlayingTrack) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(queueNextTrackAndPlay(trackInfo, trackUserMeta, currentPlayingTrack, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_QUEUE_NEXT_TRACK,
      eventData: {
        trackInfo,
        lastPlayingTrack: currentPlayingTrack
      }
    }));
  }
}

export function emitAndPlayNext(nextTrackIndex) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(playNext(nextTrackIndex, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_PLAY_NEXT_TRACK,
      eventData: {
        nextTrackIndex
      }
    }));
  } 
}

export function emitAndRemoveTrack(trackIndex) {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(removeTrack(trackIndex, triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_REMOVE_TRACK,
      eventData: {
        trackIndex
      }
    }));
  }
}

export function emitAndClearTracks() {
  return (dispatch, getState) => {
    const triggeredBy = {
      id: getState().auth.dfId,
      name: getState().auth.displayName
    };

    dispatch(clearTracks(triggeredBy));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_CLEAR_TRACKS,
      eventData: {}
    }));
  }
}

export function emitAndUpvoteTrack(trackInfo) {
  return (dispatch, getState) => {
    dispatch(upvoteTrack(trackInfo, true));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_UPVOTE_TRACK,
      eventData: {
        trackInfo
      }
    }));
  }
}

export function emitAndDownvoteTrack(trackInfo) {
  return (dispatch, getState) => {
    dispatch(downvoteTrack(trackInfo, true));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_DOWNVOTE_TRACK,
      eventData: {
        trackInfo
      }
    }));
  }
}

export function emitAndRemoveTrackFromSuggestions(trackInfo) {
  return(dispatch, getState) => {
    dispatch(removeTrackFromSuggestions(trackInfo));
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_REMOVE_TRACK_SUGGESTION_RESULTS,
      eventData: {
        trackInfo
      }
    }))
  }
}

export function emitLeaveCurrentRoom() {
  return(dispatch, getState) => {
    dispatch(clearCurrentRoomData());
    dispatch(WsActions.sendAuthenticated({
      eventType: WsEvents.CLIENT_ROOM_LOGOUT
    }))
  }
}
