import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Button} from 'react-foundation-components/lib/button';

import {DIALOG_TYPES} from "../../actions/dialog.type";
import {AUTH_TYPE_GOOGLE} from '../../actions/types';

import * as dialogActions from '../../actions/dialog.actions';

class CheckAuthButton extends Component<any, any> {
  static defaultProps = {
    onClick: () => {},
    onClickAndAuthCheckFail: () => {},
    className: '',
    disableCheck: false
  };

  onButtonClick() {
    if(!this.props.disableCheck && this.props.authenticated !== AUTH_TYPE_GOOGLE ) {
      this.props.showDialog(DIALOG_TYPES.FB_LOGIN);
      this.props.onClickAndAuthCheckFail();
    } else {
      this.props.onClick();
    }
  }

  render() {
    return (
      <Button
        className={this.props.className}
        onClick={this.onButtonClick.bind(this)} >
        {this.props.children}
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect<any, any, any>(mapStateToProps, dialogActions)(CheckAuthButton);