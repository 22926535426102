import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

// Foundation Components
import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';
import {Button} from 'react-foundation-components/lib/button';
import {FormField} from 'react-foundation-components/lib/forms';
import {FormFieldInput} from 'react-foundation-components/lib/forms';
import {FormFieldError} from 'react-foundation-components/lib/forms';
import Reveal from 'react-foundation-components/lib/reveal';
import CloseButton from 'react-foundation-components/lib/close-button';

import * as roomActions from '../../../actions/room.actions';
import {IExportMixStateFromProps} from './exportMix.type';
import {IExportMixSuppliedProps} from './exportMix.type';
import {IExportMixRouterProps} from './exportMix.type';
import {IExportMixState} from './exportMix.type';
import {IExportMixProps} from './exportMix.type';
import {ROOM_EXPORT_MIX_IN_PROGRESS} from "../../../actions/room.types";
import {ROOM_EXPORT_MIX_SUCCESS} from "../../../actions/room.types";
import {ROOM_EXPORT_MIX_ERROR} from "../../../actions/room.types";

const styles = require('./_style.scss');

class ExportMixDialog extends Component<IExportMixProps, IExportMixState> {
  static defaultProps: IExportMixSuppliedProps = {
    onHide: () => {},
    show: false
  };

  constructor(props) {
    super(props);

    this.state = {
      mixName: '',
      mixNameError: false
    };
  }

  onExportMixClicked = () => {
    const {mixName} = this.state;
    const {roomName} = this.props;

    if (mixName.trim().toLowerCase() !== '') {
      this.props.exportMix(
        roomName, mixName, this.props.exportMixRequestStatus === ROOM_EXPORT_MIX_ERROR
      );

      //this.props.onHide();
    } else {
      this.setState({mixNameError: true});
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if(this.props.exportMixRequestStatus === ROOM_EXPORT_MIX_SUCCESS
      && prevProps.exportMixRequestStatus === ROOM_EXPORT_MIX_IN_PROGRESS)
      this.props.onHide()
  };

  handleMixNameValChange(event) {
    this.setState({
      mixName: event.target.value,
      mixNameError: false
    });
  }

  render(){
    return(
      <Reveal
        revealClassName={styles.exportMixDialog}
        onHide={this.props.onHide}
        show={this.props.show} >
        <CloseButton
          onClick={this.props.onHide} />

        <FlexRow horizontalAlignment="center">
          <FlexColumn
            small={8}
            medium={6}
            large={6}
            className={styles.content}
          >
            <h4 className={styles.headTitle}>Export Mix</h4>

            <FormField
              className={styles.mixNameInputCont}
              error={this.props.exportMixRequestStatus === ROOM_EXPORT_MIX_ERROR} >

              <FormFieldInput
                value={this.state.mixName}
                className={styles.mixNameInput}
                placeholder="Mix Name"
                onChange={this.handleMixNameValChange.bind(this)} />
              <FormFieldError>A mix with this name already exists, overwrite it?</FormFieldError>
            </FormField>

            <Button
              className={styles.exportMixSubmit}
              onClick={this.onExportMixClicked} >
              {this.props.exportMixRequestStatus === ROOM_EXPORT_MIX_ERROR && <span>Update</span>}
              {this.props.exportMixRequestStatus !== ROOM_EXPORT_MIX_ERROR && <span>Export</span>}
            </Button>

          </FlexColumn>
        </FlexRow>
      </Reveal>
    );
  }

}

function mapStateToProps(state): IExportMixStateFromProps {
  return {
    roomName: state.currentRoom.roomName,
    authenticated: state.auth.authenticated,
    exportMixRequestStatus: state.currentRoom.exportMixRequestStatus
  };
}

// export default connect<IExportMixStateFromProps, any, IExportMixRouterProps>(mapStateToProps, roomActions)(withRouter<IExportMixProps>(ExportMixDialog));
export default connect<IExportMixStateFromProps, any, IExportMixRouterProps>(mapStateToProps, roomActions)(ExportMixDialog);