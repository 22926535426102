/**
 * Created by anirudh on 29/09/17.
 */

import {USER_FOLLOW_IN_PROGRESS} from './current-user.types';
import {USER_FOLLOW_SUCCESS} from './current-user.types';
import {USER_UNFOLLOW_ERROR} from './current-user.types';
import {USER_UNFOLLOW_IN_PROGRESS} from './current-user.types';
import {USER_UNFOLLOW_SUCCESS} from './current-user.types';
import {USER_FOLLOW_ERROR} from './current-user.types';
import {FETCH_USER_PROFILE_IN_PROGRESS} from './current-user.types';
import {FETCH_USER_PROFILE} from './current-user.types';
import {FETCH_USER_PROFILE_ERROR} from './current-user.types';
import {FETCH_USER_FOLLOWERS_IN_PROGRESS} from './current-user.types';
import {FETCH_USER_FOLLOWERS} from './current-user.types';
import {FETCH_USER_FOLLOWERS_ERROR} from './current-user.types';
import {FETCH_USER_MIXES_IN_PROGRESS} from "./current-user.types";
import {FETCH_USER_MIXES} from "./current-user.types";
import {FETCH_USER_MIXES_ERROR} from "./current-user.types";
import {INITIALISE_PROFILE_STATE} from './current-user.types';
import {CALL_API} from './types';

export function getUserProfile(dfId) {
  return (dispatch, getState) => {
    const requestConfig = {
      url: process.env.API_URL + '/user/' + dfId + '/userDetails'
    };
    
    dispatch({
      type: CALL_API,
      payload: {
        requestConfig: requestConfig,
        authenticated: true,
        types: [
          FETCH_USER_PROFILE_IN_PROGRESS,
          FETCH_USER_PROFILE,
          FETCH_USER_PROFILE_ERROR
        ]
      }
    })
  }
}

export function getUsersMixes(dfId) {
  return (dispatch, getState) => {

    console.log('getting mixes viewable by current user');

    const params = {
      offset: 0,
      limit: 10
    };

    const requestConfig = {
      url: process.env.API_URL + '/user/mixes',
      params
    };

    dispatch({
      type: CALL_API,
      payload: {
        requestConfig: requestConfig,
        authenticated: true,
        types: [
          FETCH_USER_MIXES_IN_PROGRESS,
          FETCH_USER_MIXES,
          FETCH_USER_MIXES_ERROR
        ]
      }
    })
  }
}

export function getUserFollowers(dfId) {
  return (dispatch, getState) => {
    const requestConfig = {
      url: process.env.API_URL + '/user/' + dfId + '/followers'
    };

    dispatch({
      type: CALL_API,
        payload: {
        requestConfig: requestConfig,
        authenticated: true,
        types: [
          FETCH_USER_FOLLOWERS_IN_PROGRESS,
          FETCH_USER_FOLLOWERS,
          FETCH_USER_FOLLOWERS_ERROR
        ]
      }
    })
  }
}

export function followUser(dfId) {
  return (dispatch, getState) => {
    const requestConfig = {
      url: process.env.API_URL + '/user/' + dfId + '/follow',
      method: 'post'
    };

    dispatch({
      type: CALL_API,
      payload: {
        requestConfig: requestConfig,
        authenticated: true,
        types: [
          USER_FOLLOW_IN_PROGRESS,
          USER_FOLLOW_SUCCESS,
          USER_FOLLOW_ERROR
        ]
      }
    })
  }
}

export function unfollowUser(dfId) {
  return (dispatch, getState) => {
    const requestConfig = {
      url: process.env.API_URL + '/user/' + dfId + '/unfollow',
      method: 'post'
    };

    dispatch({
               type: CALL_API,
               payload: {
                 requestConfig: requestConfig,
                 authenticated: true,
                 types: [
                   USER_UNFOLLOW_IN_PROGRESS,
                   USER_UNFOLLOW_SUCCESS,
                   USER_UNFOLLOW_ERROR
                 ]
               }
             })
  }
}

export function initialiseProfileState() {
  return {
    type: INITIALISE_PROFILE_STATE
  }
}