import React, {Component} from 'react';

import {RoomStatics} from './component.statics';
import RoomUtil from './component.util';
import ViewportUtil from '../../utils/viewportUtil';
import YoutubeWrapper from '../../components/youtube-wrapper/component';
import {RoomConstants} from '../../constants/room.constants';
import {AuthConstants} from '../../constants/auth';
import {PLAYLIST_NOT_LOADED} from '../../reducers/current-room.reducer';

export const NO_SEEK = Symbol('no-seek');

class RoomStateComponent extends Component<any, any> {
  constructor(props) {
    super(props);

    let currentTrackIndex = -1;
    let isPlayerVisible = RoomStatics.noPlayers.map((id, i) => {
      return i == currentTrackIndex
    });

    let playerStates = RoomStatics.noPlayers.map(() => {
      return YoutubeWrapper.playbackStates.UNSTARTED;
    });

    let trackQueue = RoomStatics.noPlayers.map((id, i) => {
      return null;
    });

    this.state = {
      showInviteDialog: false,

      trackQueue: trackQueue,
      isPlayerVisible: isPlayerVisible,
      playerStates: playerStates,
      ytPlayerObjects: [],
      noReadyPlayers: 0,
      receivedTrackEvent: false,
      isTouchDevice: ViewportUtil.isMobileDevice(),
      songSuggestions: {
        videoId: null
      },
      isRoomAccessWarningDismissed: false,
    };
  }

  componentDidMount() {
    // this.props.wsActions.connect();
    this.props.roomActions.loginOverSocket(this.props.match.params.roomName);
    let noReadyPlayers = ViewportUtil.isVideoRenderableDevice() ? 0 : RoomStatics.noPlayers.length;

    this.setState({
      noReadyPlayers: noReadyPlayers
    });
  }

  componentWillMount() {}

  componentWillUnmount() {
    //this.props.wsActions.disconnect();
    console.log("client leaving room, unmounting socket events");
    this.props.roomActions.emitLeaveCurrentRoom();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("Component updating.");
    // console.log({
    //   prev: prevState,
    //   current: this.state
    // });
    // console.log({
    //   prevProps: prevProps,
    //   currentProps: this.props
    // });

    //We check if we need to re-sync
    if(
      prevProps.socketConnState !== RoomStatics.socketStates.CONNECTED &&
      this.props.socketConnState === RoomStatics.socketStates.CONNECTED
    ) {
      this.props.roomActions.loginOverSocket(this.props.match.params.roomName);
    }

    if(
      this.props.socketConnState === RoomStatics.socketStates.LOGGEDIN &&
      prevProps.authenticated !== this.props.authenticated
    ) {
      this.props.roomActions.loginOverSocket(this.props.match.params.roomName);
    }

    if (
      !RoomUtil.isPlaylistEqual(this.props.playlist, prevProps.playlist) ||
      this.props.currentTrackIndex !== prevProps.currentTrackIndex ||
      (
        prevProps.socketConnState !== RoomStatics.socketStates.LOGGEDIN &&
        this.props.socketConnState === RoomStatics.socketStates.LOGGEDIN
      )
    ) {
      this.refreshSuggestions();
    }

    if(
      !RoomUtil.isPlaylistEqual(this.props.playlist, prevProps.playlist) ||
      this.props.roomState !== prevProps.roomState ||
      this.state.noReadyPlayers !== prevState.noReadyPlayers ||
      this.props.currentTrackIndex !== prevProps.currentTrackIndex ||
      this.props.nextTrackIndex !== prevProps.nextTrackIndex ||
      this.props.currentPlayerIndex !== prevProps.currentPlayerIndex ||
      this.state.receivedTrackEvent
    ) {
      this.handleRoomState(prevProps, prevState);
    }

    if(this.props.match.params.roomName !== prevProps.match.params.roomName) {
      this.handleRoomUrlChange();
    }

    if(
      this.props.roomState === RoomStatics.states.TRACK_PLAYING &&
      (
        prevProps.roomState === RoomStatics.states.PLAYERS_NOT_READY ||
        prevProps.roomState === RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START ||
        prevProps.roomState === RoomStatics.states.TRACK_WAITING_FOR_TRANSITION ||
        prevProps.roomState === RoomStatics.states.TRACK_EMPTY_LIST ||
        prevProps.roomState === RoomStatics.states.TRACK_TRANSITION_COMPLETE
      )
    ) {
      this.props.roomActions.emitTrackPlaybackStartedNotify(this.props.currentTrackIndex);
    }
  }

  handleRoomUrlChange() {
    this.props.roomActions.resetCurrentRoomData();
    this.props.roomActions.loginOverSocket(this.props.match.params.roomName);
  }

  handleRoomState(prevProps, prevState) {
    //console.log('Handling room state');

    if(this.state.receivedTrackEvent) {
      this.setState({
        receivedTrackEvent: false
      });
    }

    if(this.props.roomState === RoomStatics.states.TRACK_EMPTY_LIST) {
      this.handleRoomTrackEmptyList(
        prevProps,
        prevState
      );
    } else if(this.props.roomState === RoomStatics.states.CLOSED_BY_REQUEST) {
      this.handleRoomClosedByRequest(
        prevProps,
        prevState
      );
    } else if(this.props.roomState === RoomStatics.states.CLOSED_BY_EVENT) {
      this.handleRoomClosedByEvent(
        prevProps,
        prevState
      );
    } else {
      if(
        this.props.playlist !== PLAYLIST_NOT_LOADED &&
        this.props.playlist.length === 0 &&
        this.props.roomState !== RoomStatics.states.PLAYERS_NOT_READY
      ) {
        console.log('Changing room state to empty list');
        // Checking if playlist is cleared
        this.updateReduxStore({
          roomState: RoomStatics.states.TRACK_EMPTY_LIST,
          currentTrackIndex: -1,
          nextTrackIndex: -1
        });
      } else {
        if(this.props.roomState === RoomStatics.states.PLAYERS_NOT_READY) {
          console.log('Checking if all players ready');
          if(this.state.noReadyPlayers === RoomStatics.noPlayers.length) {
            const newRoomState =
              this.state.isTouchDevice ?
                RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START :
                RoomStatics.states.TRACK_PLAYING;

            this.updateReduxStore({
              roomState: newRoomState
            });
          }
        } else if(this.props.roomState === RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START) {
          this.handleRoomWaitingForTapToStart(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_PLAYING) {
          this.handleRoomTrackPlaying(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_PAUSED) {
          this.handleRoomTrackPaused(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_PAUSED_BY_USER) {
          this.handleRoomTrackPausedByUser(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_ENDED) {
          this.handleRoomTrackEnded(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_CROSS_TRANSITION) {
          this.handleRoomTrackCrossTransition(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_DIRECT_TRANSITION) {
          this.handleRoomTrackDirectTransition(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_WAITING_FOR_TRANSITION) {
          this.handleRoomWaitingForTransition(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_PAUSED_AND_WAITING_FOR_TRANSITION) {
          this.handleRoomPausedAndWaitingForTransition(
            prevProps,
            prevState
          );
        } else if(this.props.roomState === RoomStatics.states.TRACK_TRANSITION_COMPLETE) {
          this.handleRoomTransitionComplete(
            prevProps,
            prevState
          );
        }
      }
    }
  }

  handleRoomWaitingForTapToStart(prevProps, prevState) {
    const playlist = this.props.playlist;

    if(
      playlist !== PLAYLIST_NOT_LOADED &&
      playlist.length === 0
    ) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_EMPTY_LIST
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PAUSED,
        YoutubeWrapper.playbackStates.UNSTARTED
      );
    }
  }

  handleRoomTrackPlaying(prevProps, prevState) {
    const playlist = this.props.playlist;

    if(playlist.length === 0) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_EMPTY_LIST
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PLAYING,
        YoutubeWrapper.playbackStates.UNSTARTED
      );
    }
  }

  handleRoomTrackPaused(prevProps, prevState) {
    const playlist = this.props.playlist;

    if(playlist.length === 0) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_EMPTY_LIST
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PAUSED,
        YoutubeWrapper.playbackStates.UNSTARTED
      );
    }
  }

  handleRoomTrackPausedByUser(prevProps, prevState) {
    const playlist = this.props.playlist;

    if(playlist.length === 0) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_EMPTY_LIST
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PLAYING,
        YoutubeWrapper.playbackStates.UNSTARTED
      );
    }
  }

  handleRoomTrackEnded(prevProps, prevState) {
    const currentTrackIndex = this.props.currentTrackIndex;
    const nextTrackIndex = currentTrackIndex+1;
    const playlist = this.props.playlist;

    if(playlist.length > nextTrackIndex) {
      this.updateReduxStore({
        currentTrackIndex: nextTrackIndex,
        nextTrackIndex: nextTrackIndex+1,
        roomState: RoomStatics.states.TRACK_DIRECT_TRANSITION
      });
    } else if(playlist.length == 0) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_EMPTY_LIST
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PLAYING,
        YoutubeWrapper.playbackStates.UNSTARTED
      );
    }
  }

  handleRoomTransitionComplete(prevProps, prevState) {
    const messages =
      RoomUtil
        .getUpdatedMessagesByPlaybackEvent(
          this.props.messages,
          RoomStatics.playbackEvents.FADECOMPLETE,
          {}
        );

    let updatedCurrentTrackIndex;

    console.log('*** Transition complete', prevProps.roomState);

    if(prevProps.roomState === RoomStatics.states.TRACK_CROSS_TRANSITION) {
      const nextPlayerIndex = RoomUtil.getNextPlayerIndex(this.getCurrentPlayerIndex());
      const newStateVals = {
        currentTrackIndex: this.props.nextTrackIndex,
        currentPlayerIndex: nextPlayerIndex,
        nextTrackIndex: this.props.nextTrackIndex + 1,
        roomState: RoomStatics.states.TRACK_PLAYING,
        messages: messages
      };

      console.log('*** Transition complete for cross', newStateVals);

      updatedCurrentTrackIndex = this.props.nextTrackIndex;
      this.updateReduxStore(newStateVals);
    } else {
      updatedCurrentTrackIndex = this.props.currentTrackIndex;
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_PLAYING,
        messages: messages
      });
    }

    this.props.roomActions.emitCurrentTrackUpdate(updatedCurrentTrackIndex);
  }

  handleRoomTrackCrossTransition(prevProps, prevState) {
    const messages =
      RoomUtil
        .getUpdatedMessagesByPlaybackEvent(
          this.props.messages,
          RoomStatics.playbackEvents.FADING,
          this.props.messages.playbackEvent.triggeredBy
        );

    this.updateReduxStore({
      messages: messages
    });

    this.updateRoomTracks(
      YoutubeWrapper.playbackStates.FADING_OUT,
      YoutubeWrapper.playbackStates.FADING_IN
    );
  }

  handleRoomTrackDirectTransition(prevProps, prevState) {
    const messages =
      RoomUtil
        .getUpdatedMessagesByPlaybackEvent(
          this.props.messages,
          RoomStatics.playbackEvents.FADING,
          this.props.messages.playbackEvent.triggeredBy
        );

    this.updateReduxStore({
      messages: messages
    });

    this.updateRoomTracks(
      YoutubeWrapper.playbackStates.FADING_IN,
      YoutubeWrapper.playbackStates.UNSTARTED
    );
  }

  handleRoomPausedAndWaitingForTransition(prevProps, prevState) {
    const messages =
      RoomUtil
        .getUpdatedMessagesByPlaybackEvent(
          this.props.messages,
          RoomStatics.playbackEvents.NONE,
          {}
        );

    this.updateReduxStore({
      messages: messages
    });

    this.updateRoomTracks(
      YoutubeWrapper.playbackStates.PAUSED,
      YoutubeWrapper.playbackStates.UNSTARTED
    );
  }

  handleRoomWaitingForTransition(prevProps, prevState) {
    let currentPlayerIndex = this.getCurrentPlayerIndex();
    let nextPlayerIndex = RoomUtil.getNextPlayerIndex(currentPlayerIndex);
    let nextTrackIndex = this.props.nextTrackIndex;

    if(
      this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.OTHER ||
      prevProps.roomState === RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START ||
      (
        ViewportUtil.isMobileDevice() &&
        ViewportUtil.isIos()
      )
    ) {

      const newRoomState = prevProps.roomState === RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START ?
        RoomStatics.states.TRACK_WAITING_FOR_TAP_TO_START :
        RoomStatics.states.TRACK_PLAYING;

      this.updateReduxStore({
        roomState: newRoomState,
        currentTrackIndex: nextTrackIndex,
        nextTrackIndex: nextTrackIndex+1
      });

      this.props.roomActions.emitCurrentTrackUpdate(nextTrackIndex);

    } else if(
      prevProps.roomState === RoomStatics.states.TRACK_EMPTY_LIST ||
      prevProps.roomState === RoomStatics.states.TRACK_ENDED ||
      prevProps.roomState === RoomStatics.states.TRACK_PAUSED ||
      prevProps.roomState === RoomStatics.states.TRACK_PAUSED_BY_USER
    ) {

      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_DIRECT_TRANSITION,
        currentTrackIndex: nextTrackIndex,
        nextTrackIndex: nextTrackIndex+1
      });

    } else {
      if(this.state.playerStates[nextPlayerIndex] === YoutubeWrapper.playbackStates.BUFFERED) {
        this.updateReduxStore({roomState: RoomStatics.states.TRACK_CROSS_TRANSITION});
      } else {
        this.updateRoomTracks(
          YoutubeWrapper.playbackStates.PLAYING,
          YoutubeWrapper.playbackStates.UNSTARTED
        );
      }
    }
  }

  handleRoomTrackEmptyList(prevProps, prevState) {
    let playlist = this.props.playlist;

    console.log('Handling track mepty list');

    if(playlist.length > 0) {
      console.log('Playlist no more empty');

      this.updateReduxStore({
        currentTrackIndex: 0,
        nextTrackIndex: 1,
        roomState: RoomStatics.states.TRACK_DIRECT_TRANSITION
      });
    } else {
      this.updateRoomTracks(
        YoutubeWrapper.playbackStates.PAUSED,
        YoutubeWrapper.playbackStates.PAUSED
      );
    }
  }

  handleRoomClosedByRequest(prevProps, prevState) {
    this.props.roomActions.emitRoomClosed();

    setTimeout(() => {
      this.props.history.push('/');
    }, 500)
  }

  handleRoomClosedByEvent(prevProps, prevState) {
    this.props.history.push('/');
  }

  handleTapToStartClick = () => {
    this.updateReduxStore({
      roomState: RoomStatics.states.TRACK_PLAYING
    });
  };
  
  toggleTrackPlayOrPause = (forMobile = false): boolean => {

    if(
      this.props.roomState === RoomStatics.states.TRACK_PAUSED ||
      this.props.roomState === RoomStatics.states.TRACK_PAUSED_AND_WAITING_FOR_TRANSITION ||
      this.props.roomState === RoomStatics.states.TRACK_PAUSED_BY_USER
    ) {
      this.startPlayingAndForcePlay(forMobile);

      return true;
    } else {
    //else if(this.props.roomState === RoomStatics.states.TRACK_PLAYING) {
      this.pausePlayingAndForcePause(forMobile);

      return false;
    }
  };
  
  startPlayingAndForcePlay = (forMobile = false) => {
    const triggeredBy = {
      id: this.props.dfId,
      name: this.props.displayName
    };

    if(this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE) {
      this.props.roomActions.resumeRoomPlayback(triggeredBy);
    } else {
      this.props.roomActions.emitAndResumeRoomPlayback(triggeredBy);
    }

    if(forMobile) {
      const currentYtObject = this.state.ytPlayerObjects[this.props.currentPlayerIndex];
      currentYtObject.playVideo();
    }
  };
  
  pausePlayingAndForcePause = (forMobile = false) => {
    const triggeredBy = {
      id: this.props.dfId,
      name: this.props.displayName
    };

    if(this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE) {
      this.props.roomActions.pauseRoomPlayback(triggeredBy);
    } else {
      this.props.roomActions.emitAndPauseRoomPlayback(triggeredBy);
    }
  };

  onPlayerReady(ev, index) {
    //console.log("Readying... "+index);

    this.state.ytPlayerObjects[index] = ev.target;

    this.setState({
      noReadyPlayers: this.state.noReadyPlayers+1,
      ytPlayerObjects: this.state.ytPlayerObjects
    });
  }

  onPlayerFadeOutComplete(index) {
    this.updateReduxStore({
      roomState: RoomStatics.states.TRACK_TRANSITION_COMPLETE
    });
  }

  onPlayerFadeInComplete(index) {
    // if(this.props.roomState === RoomStatics.states.TRACK_DIRECT_TRANSITION) {
    //   this.updateReduxStore({
    //     roomState: RoomStatics.states.TRACK_TRANSITION_COMPLETE
    //   });
    // }

    if(RoomUtil.isRoomInTransition(this.props.roomState)) {
      this.updateReduxStore({
        roomState: RoomStatics.states.TRACK_TRANSITION_COMPLETE
      });
    }
  }

  onPlayerPlaybackEnded(index) {
    this.updateReduxStore({
      roomState: RoomStatics.states.TRACK_ENDED
    });
  }

  onPlayerPlaybackPausedByUser(index) {
    this.updateReduxStore({
      roomState: RoomStatics.states.TRACK_PAUSED_BY_USER
    });
  }

  onPlayerPlaybackPlaying(index) {
    // Not required anymore

    // if(this.props.roomState !== RoomStatics.states.TRACK_PLAYING) {
    //   this.updateReduxStore({
    //     roomState: RoomStatics.states.TRACK_PLAYING
    //   });
    // }
  }

  refreshSuggestions() {
    if(this.props.currentTrackIndex >= 0 && this.props.playlist[this.props.currentTrackIndex]) {
      this.props.roomActions.getTrackSuggestions(
        this.props.uid,
        this.props.visibility,
        this.props.playlist[this.props.currentTrackIndex].id
      );
    }
  }

  onClearedByUser = () => {
    this.props.roomActions.emitAndClearTracks();
  };

  onPlayNextClicked = () => {
    if(this.props.nextTrackIndex < this.props.playlist.length) {
      this.playTrackAtIndex(this.props.nextTrackIndex);
    }
  };

  closeRoom = () => {
    this.props.roomActions.closeRoom(this.props.uid);
  };

  onUpvoteTrack(actionData) {
    this
      .props
      .roomActions
      .emitAndUpvoteTrack(
        actionData.videoInfo
      )
  }

  onDownvoteTrack(actionData) {
    this
      .props
      .roomActions
      .emitAndDownvoteTrack(
        actionData.videoInfo
      )
  }

  onQueueNextAndPlayTrack(actionData) {
    if(this.props.isSynced) {
      this
        .props
        .roomActions
        .emitAndQueueNextTrackAndPlay(
          actionData.videoInfo,
          this.getInitialTrackMeta(),
          RoomUtil
            .getCurrentPlayingVideoInfo(
              this.props.currentTrackIndex,
              this.props.playlist
            )
        );
    } else {
      this
        .props
        .roomActions
        .queueNextTrackAndPlayButEmitQueueNext(
          actionData.videoInfo,
          this.getInitialTrackMeta(),
          RoomUtil
            .getCurrentPlayingVideoInfo(
              this.props.currentTrackIndex,
              this.props.playlist
            )
        );
    }
  }

  onQueueNextTrack(actionData) {
    if(this.props.playlist.length > 0) {
      this
        .props
        .roomActions
        .emitAndQueueNextTrack(
          actionData.videoInfo,
          this.getInitialTrackMeta(),
          RoomUtil
            .getCurrentPlayingVideoInfo(
              this.props.currentTrackIndex,
              this.props.playlist
            )
        );
    } else {
      this.onQueueTrack(actionData);
    }
  }

  onQueueTrack(actionData) {
    this
      .props
      .roomActions
      .emitAndQueueTrack(
        actionData.videoInfo,
        this.getInitialTrackMeta()
      );
  }

  removeTrackFromSuggestions(actionData) {
    this
      .props
      .roomActions
      .emitAndRemoveTrackFromSuggestions(
        actionData.videoInfo
      )
  }

  playTrackAtIndex(actionedTrackIndex) {
    // if(RoomUtil.isRoomInTransition(this.props.roomState)) {
    //   return;
    // }

    if(this.props.isSynced) {
      this.props.roomActions.emitAndPlayNext(actionedTrackIndex);
    } else {
      this.props.roomActions.playNext(actionedTrackIndex);
    }
  }

  removeTrackAtIndex(actionedTrackIndex) {
    this.props.roomActions.emitAndRemoveTrack(actionedTrackIndex);
  }

  onPlayerPlayBackStateChange(playerIndex, playbackState) {
    // this.log("Player: "+playerIndex+" state updated: "+playbackState);

    switch (playbackState) {
      case YoutubeWrapper.playbackStates.BUFFERED:
        this.onPlayerBuffered(playerIndex);
        break;
      case YoutubeWrapper.playbackStates.FADE_IN_COMPLETE:
        this.onPlayerFadeInComplete(playerIndex);
        break;
      case YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE:
        this.onPlayerFadeOutComplete(playerIndex);
        break;
      case YoutubeWrapper.playbackStates.ENDED:
        this.onPlayerPlaybackEnded(playerIndex);
        break;
      case YoutubeWrapper.playbackStates.PAUSED_BY_USER:
        this.onPlayerPlaybackPausedByUser(playerIndex);
        break;
      case YoutubeWrapper.playbackStates.PLAYING:
        this.onPlayerPlaybackPlaying(playerIndex);
        break;
    }
  }

  onInnerPlayerPlayBackStateChange = (playerIndex, payload) => {
    if(playerIndex === this.getCurrentPlayerIndex()) {
      const data = {
        status: payload.status,
        trackElapsed: payload.trackElapsed,
        trackDuration: payload.trackDuration,
        lastUpdatedAt: Date.now()
      };

      if(this.props.onlyOnePlayingDevice.status !== RoomConstants.ONLY_ONE_PLAYING_DEVICE.CURRENT) {
        this.props.roomActions.updateCurrentTrackPlayback(data);
      } else {
        this.props.roomActions.emitAndUpdateCurrentTrackPlayback(data);
      }
    }
  };

  onPlayerBuffered(playerIndex) {
    let playerStates = this.state.playerStates.slice();
    let currentState = playerStates[playerIndex];

    if(currentState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.log('Player buffered index: '+playerIndex);

      playerStates[playerIndex] = YoutubeWrapper.playbackStates.BUFFERED;
      this.setState({playerStates: playerStates, receivedTrackEvent: true});
    } else {
      this.log('Ignoring player buffered event index: '+playerIndex);
    }
  }

  handleSeek = (seekPosInSecs) => {
    this.props.roomActions.setSeekAt(seekPosInSecs);

    if(
      this.props.onlyOnePlayingDevice.status === RoomConstants.ONLY_ONE_PLAYING_DEVICE.OTHER &&
      this.props.accessLevel >= AuthConstants.SuggesterAccess
    ) {
      this.props.roomActions.emitSeekAt(seekPosInSecs);
    }
  };

  handleOnSeekConsumed = () => {
    this.props.roomActions.setSeekAt(NO_SEEK);
  };

  handleIsSyncedChange = (event) => {
    this.props.roomActions.emitAndUpdateIsSynced(event.target.checked);
  };

  handleOnlyOnePlayerChange = (event) => {
    console.log('Working...');
    this.props.roomActions.emitAndUpdateOnlyOnePlayingDevice(event.target.checked);
  };

  showHideClearConfirmDialog(val) {
    this.updateReduxStore({showClearConfirmDialog: val});
  }

  hideOrShowInviteDialog(val) {
    this.setState({showInviteDialog: val});
  }

  handleRoomAccessWarningDismiss = () => {
    this.setState({isRoomAccessWarningDismissed: true});
  };

  updateRoomTracks(currentPlayerState, nextPlayerState, currPlayerIndex = undefined) {
    let playlist = this.props.playlist;
    let currentTrackIndex = this.props.currentTrackIndex;
    let nextTrackIndex = this.props.nextTrackIndex;
    let oldTrackQueue = this.state.trackQueue;
    let oldPlayerStates = this.state.playerStates;

    let currentPlayerIndex = currPlayerIndex || this.getCurrentPlayerIndex();
    let updatedTrackQueue = RoomUtil.getUpdatedTrackQueue(
      currentTrackIndex,
      nextTrackIndex,
      currentPlayerIndex,
      playlist
    );
    let updatedPlayerStates = RoomUtil.getUpdatedPlayerStates(
      currentPlayerState,
      nextPlayerState,
      currentPlayerIndex,
      oldTrackQueue,
      updatedTrackQueue,
      oldPlayerStates
    );
    let isPlayerVisible = RoomStatics.noPlayers.map((id, i) => {
      return i == currentPlayerIndex && this.props.roomState != RoomStatics.states.TRACK_EMPTY_LIST
    });

    /*console.log('====Updating room Tracks: '+this.props.roomState);
    console.log({
      playlist: playlist,
      currentTrackIndex: currentTrackIndex,
      nextTrackIndex: nextTrackIndex,
      oldTrackQueue: oldTrackQueue,
      oldPlayerStates: oldPlayerStates,
      currentPlayerState: currentPlayerState,
      nextPlayerState: nextPlayerState
    });
    console.log({
      currentTrackIndex: currentTrackIndex,
      nextTrackIndex: nextTrackIndex,
      trackQueue: updatedTrackQueue,
      playerStates: updatedPlayerStates,
      isPlayerVisible: isPlayerVisible
    });*/

    this.setState({
      //currentTrackIndex: currentTrackIndex,
      //nextTrackIndex: nextTrackIndex,
      trackQueue: updatedTrackQueue,
      playerStates: updatedPlayerStates,
      isPlayerVisible: isPlayerVisible
    });
  }

  getInitialTrackMeta = () => {
    return {
      reactions: [],
      addedBy: {
        dfId: this.props.dfId,
        displayName: this.props.displayName
      }
    };
  };

  getCurrentPlayerIndex() {
    return this.props.currentPlayerIndex;
  }

  updateReduxStore(values) {
    this.props.roomActions.updateStore(values);
  }

  isRoomPaused = (): boolean => {
    return this.props.roomState === RoomStatics.states.TRACK_PAUSED ||
      this.props.roomState === RoomStatics.states.TRACK_PAUSED_AND_WAITING_FOR_TRANSITION ||
      this.props.roomState === RoomStatics.states.TRACK_PAUSED_BY_USER;
  };

  shouldAllowUserToTogglePlayPause = (): boolean => {
    return (
      this.props.onlyOnePlayingDevice.status !== RoomConstants.ONLY_ONE_PLAYING_DEVICE.OTHER ||
      this.props.accessLevel >= AuthConstants.RoomAccess
    );
  };

  log(message) {
    //console.log(getCurrentSystemTime()+' Room: '+message);
  }
}

export default RoomStateComponent;
