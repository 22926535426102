const NoAccess = 0;
const DFAccess = 1;
const GoogleAccess = 2;
const SuggesterAccess = 3;
const RoomAccess = 4;
const FullAccess = 5; //Room owner has full access

export const AuthConstants = {
  NoAccess,
  DFAccess,
  GoogleAccess,
  SuggesterAccess,
  RoomAccess,
  FullAccess
};
