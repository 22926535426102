import {ROOM_FETCH_PRIVATE_LIST_IN_PROGRESS} from '../actions/types';
import {ROOM_FETCH_PUBLIC_LIST_IN_PROGRESS} from '../actions/types';
import {ROOM_FETCH_USER_LIST_IN_PROGRESS} from '../actions/types';
import {ROOM_FETCH_PRIVATE_LIST} from '../actions/types';
import {ROOM_FETCH_PUBLIC_LIST} from '../actions/types';
import {ROOM_FETCH_USER_LIST} from '../actions/types';
import {ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST} from '../actions/types';
import {ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS} from '../actions/types';
import {ROOM_FETCH_PRIVATE_LIST_ERROR} from '../actions/types';
import {ROOM_FETCH_USER_LIST_ERROR} from '../actions/types';
import {ROOM_CREATE} from '../actions/types';
import {ROOM_CREATE_IN_PROGRESS} from '../actions/types';
import {ROOM_CREATE_ERROR} from '../actions/types';
import {ROOM_CLEAR_ALL_LISTS} from '../actions/types';
import {getInitialStateForRoomList} from "./helper/room-list.helper";
import {setLoadingForList} from "./helper/room-list.helper";
import {getUpdatedFetchRoomList} from "./helper/room-list.helper";
import {ROOM_INVITE_CONSUME_SUCCESS} from "../actions/types";
import {ROOM_INVITE_CONSUME_FAILURE} from "../actions/types";
import {ROOM_INVITE_CONSUME_IN_PROGRESS} from "../actions/types";
import {ROOM_INVITE_CONSUME_CLEAR} from "../actions/types";

export const CREATE_ROOM_REQUEST_NONE = Symbol('create-room-request-none');
export const CREATE_ROOM_REQUEST_IN_PROGRESS = Symbol('create-room-request-in-progress');
export const CREATE_ROOM_REQUEST_ERROR = Symbol('create-room-request-error');
export const INVITE_CODE_CONSUME_LOADING = Symbol('invite-code-consume-loading');
export const INVITE_CODE_CONSUME_FAILURE = Symbol('invite-code-consume-failure');
export const INVITE_CODE_CONSUME_NONE = Symbol('invite-code-consume-none');
export const INVITE_CODE_CONSUME_SUCCESS = Symbol('invite-code-consume-success');

export interface IRoomListCreateRoomRequestSuccess {
  name: string
}

export type IRoomListCreateRoomRequest = symbol | IRoomListCreateRoomRequestSuccess;

const initialState = {
  allPrivate: getInitialStateForRoomList(),
  // allSolo: getInitialStateForRoomList(),
  allPublic: getInitialStateForRoomList(),
  allAccessiblePublic: getInitialStateForRoomList(),
  allSelectedUsers: getInitialStateForRoomList(),
  
  createRoomRequest: CREATE_ROOM_REQUEST_NONE,
  inviteCodeConsume: {
    status: INVITE_CODE_CONSUME_NONE,
    resolvedRoomName: ''
  }
};

export default function(state = initialState, action) {
  switch(action.type) {
    case ROOM_CREATE:
      return { ...state, createRoomRequest: action.response.data.results };
    case ROOM_CREATE_IN_PROGRESS:
      return { ...state, createRoomRequest: CREATE_ROOM_REQUEST_IN_PROGRESS };
    case ROOM_CREATE_ERROR:
      return { ...state, createRoomRequest: CREATE_ROOM_REQUEST_ERROR };

    case ROOM_INVITE_CONSUME_SUCCESS:
      return {
        ...state,
        inviteCodeConsume: {
          status: INVITE_CODE_CONSUME_SUCCESS,
          resolvedRoomName: action.response.data.roomName
        }
      };
    case ROOM_INVITE_CONSUME_FAILURE:
      return {
        ...state,
        inviteCodeConsume: {
          status: INVITE_CODE_CONSUME_FAILURE,
          resolvedRoomName: ''
        }
      };
    case ROOM_INVITE_CONSUME_IN_PROGRESS:
      return {
        ...state,
        inviteCodeConsume: {
          status: INVITE_CODE_CONSUME_LOADING,
          resolvedRoomName: ''
        }
      };
    case ROOM_INVITE_CONSUME_CLEAR:
      return {
        ...state,
        inviteCodeConsume: {
          status: INVITE_CODE_CONSUME_NONE,
          resolvedRoomName: ''
        }
      };

    case ROOM_CLEAR_ALL_LISTS:
      return {
        ...state,
        allPrivate: getInitialStateForRoomList(),
        allPublic: getInitialStateForRoomList(),
        allAccessiblePublic: getInitialStateForRoomList(),
        // allSolo: getInitialStateForRoomList(),
        allSelectedUsers: getInitialStateForRoomList()
      };

    //Room list fetch in progress
    case ROOM_FETCH_PRIVATE_LIST_IN_PROGRESS:
      return {
        ...state,
        allPrivate: setLoadingForList(state.allPrivate, action.payload)
      };
    case ROOM_FETCH_PUBLIC_LIST_IN_PROGRESS:
      return {
        ...state,
        allPublic: setLoadingForList(state.allPublic, action.payload)
      };
    case ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS:
      return {
        ...state,
        allAccessiblePublic: setLoadingForList(state.allAccessiblePublic, action.payload)
      };
    // case ROOM_FETCH_SOLO_LIST_IN_PROGRESS:
    //   return {
    //     ...state,
    //     allSolo: setLoadingForList(state.allSolo, action.payload)
    //   };
    case ROOM_FETCH_USER_LIST_IN_PROGRESS:
      console.log('getting users room list');
      return {
        ...state,
        allSelectedUsers: setLoadingForList(state.allSelectedUsers, action.payload)
      };

    //Room list fetch complete
    case ROOM_FETCH_PRIVATE_LIST:
      return {
        ...state,
        allPrivate: getUpdatedFetchRoomList(state.allPrivate, action.response.data, action.requestId)
      };
    case ROOM_FETCH_PUBLIC_LIST:
      return {
        ...state,
        allPublic: getUpdatedFetchRoomList(state.allPublic, action.response.data, action.requestId)
      };
    case ROOM_FETCH_PUBLIC_ACCESSIBLE_LIST:
      return {
        ...state,
        allAccessiblePublic: getUpdatedFetchRoomList(state.allAccessiblePublic, action.response.data, action.requestId)
      };
    // case ROOM_FETCH_SOLO_LIST:
    //   return {
    //     ...state,
    //     allSolo: getUpdatedFetchRoomList(state.allSolo, action.response.data, action.requestId)
    //   };
    case ROOM_FETCH_USER_LIST:
      console.log('got users room list', action.response.data);
      return {
        ...state,
        allSelectedUsers: getUpdatedFetchRoomList(state.allSelectedUsers, action.response.data, action.requestId)
      };

    //Room list fetch error
    case ROOM_FETCH_PRIVATE_LIST_ERROR:
      return {
        ...state,
        allPrivate: getUpdatedFetchRoomList(state.allPrivate, {results: []}, action.requestId)
      };
    case ROOM_FETCH_USER_LIST_ERROR:
      return {
        ...state,
        allSelectedUsers: getUpdatedFetchRoomList(state.allSelectedUsers, {results: []}, action.requestId)
      };
  }

  return state;
}