/**
 * Created by anirudh on 29/09/17.
 */

import {FETCH_USER_MIXES, USER_FOLLOW_IN_PROGRESS} from '../actions/current-user.types';
import {USER_FOLLOW_SUCCESS} from '../actions/current-user.types';
import {USER_FOLLOW_ERROR} from '../actions/current-user.types';
import {USER_UNFOLLOW_IN_PROGRESS} from '../actions/current-user.types';
import {USER_UNFOLLOW_SUCCESS} from '../actions/current-user.types';
import {USER_UNFOLLOW_ERROR} from '../actions/current-user.types';
import {FETCH_USER_PROFILE_IN_PROGRESS} from '../actions/current-user.types';
import {FETCH_USER_PROFILE} from '../actions/current-user.types';
import {FETCH_USER_PROFILE_ERROR} from '../actions/current-user.types';
import {FETCH_USER_FOLLOWERS_IN_PROGRESS} from '../actions/current-user.types';
import {FETCH_USER_FOLLOWERS} from '../actions/current-user.types';
import {FETCH_USER_FOLLOWERS_ERROR} from '../actions/current-user.types';
import {INITIALISE_PROFILE_STATE} from '../actions/current-user.types';

export const USER_MIXES_LOADING = [];
export const USER_MIXES_ERROR = [];

const initialState = {
  googleId: '',
  dfId: '',
  displayName: '',
  followers: [],
  following: [],
  isFollower: false,
  hasRequested: false,
  profilePrivacy: 'PUBLIC',
  profilePicUrl: '',
  followButtonDisabled: false,
  userMixes: USER_MIXES_LOADING,
};

export default function(state = initialState, action) {
  // console.log('Profile Reducer: ', action);

  switch(action.type) {
    case USER_FOLLOW_SUCCESS:
      let results = action.response.data;
      return {
        ...state,
        isFollower: results.isFollower,
        hasRequested: results.hasRequested
      };
    case USER_FOLLOW_IN_PROGRESS:
      return { ...state, followButtonDisabled: true };
    case USER_FOLLOW_ERROR:
      return { ...state };
    case USER_UNFOLLOW_SUCCESS:
      return { ...state, isFollower: false, hasRequested: false };
    case USER_UNFOLLOW_IN_PROGRESS:
      return { ...state };
    case USER_UNFOLLOW_ERROR:
      return { ...state };
    case FETCH_USER_PROFILE:
      const userDetails = action.response.data.results;
      return {
        ...state,
        googleId: userDetails.googleId,
        dfId: userDetails.dfId,
        displayName: userDetails.displayName,
        following: userDetails.following,
        profilePrivacy: userDetails.profilePrivacy,
        profilePicUrl: userDetails.profilePicUrl
      };
    case FETCH_USER_PROFILE_IN_PROGRESS:
      return { ...state };
    case FETCH_USER_PROFILE_ERROR:
      return { ...state };
    case FETCH_USER_FOLLOWERS:
      const followerDetails = action.response.data.results;
      return {
        ...state,
        followers: followerDetails.followers,
        isFollower: followerDetails.isFollower,
        hasRequested: followerDetails.hasRequested
      };
    case FETCH_USER_FOLLOWERS_IN_PROGRESS:
      return { ...state, userMixes: USER_MIXES_LOADING};
    case FETCH_USER_FOLLOWERS_ERROR:
      return { ...state, userMixes: USER_MIXES_ERROR};
    case FETCH_USER_MIXES:
      return { ...state, userMixes: action.response.data.results };
    case INITIALISE_PROFILE_STATE:
      return initialState;
  }

  return state;
}