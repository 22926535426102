/**
 * Created by anirudh on 28/10/17.
 */

import {WsEvents} from '../constants/ws.events';
import {READ_NOTIFICATION} from '../actions/notification.types';
import {FETCH_USER_NOTI} from '../actions/notification.types';
import {FETCH_USER_NOTI_IN_PROGRESS} from '../actions/notification.types';
import {FETCH_USER_NOTI_ERROR} from '../actions/notification.types';

const initialState = {
  notificationList: [],
  areNotificationsLoading: false,
  offset: 0,
  hasMore: true
};

export default function(state = initialState, action) {
  let oldNotificationList = state.notificationList.slice();

  switch(action.type) {
    case FETCH_USER_NOTI:
      let notificationList = action.response.data.notificationList;
      notificationList = state.notificationList.concat(notificationList);

      console.log("all notifications ", notificationList);
      
      return {
        ...state,
        notificationList,
        areNotificationsLoading: false,
        hasMore: action.response.data.notificationList.length === 10,
        offset: notificationList.length
      };
    case FETCH_USER_NOTI_IN_PROGRESS:
      return {
        ...state,
        areNotificationsLoading: true
      };
    case FETCH_USER_NOTI_ERROR:
      return {
        ...state,
        areNotificationsLoading: false
      };
    case WsEvents.SERVER_NOTIFICATION:
      console.log('Notification Reducer: ', action);
      oldNotificationList.unshift(action.payload);

      return {
        ...state,
        notificationList: oldNotificationList
      };
    case READ_NOTIFICATION:
      console.log('Notification Reducer: ', action);

      let newNotificationList = oldNotificationList.map(noti => {
        if(noti.id === action.payload.notificationId)
          noti.read = true;
        return noti;
      });

      return {
        ...state,
        newNotificationList
      };
  }

  return state;
}