const ROOM_NO_SINGLE_PLAYING_DEVICE_SESSION = 'room-no-single-playing-device';

const ONLY_ONE_PLAYING_DEVICE = {
  CURRENT: 'current',
  OTHER: 'other',
  NONE: 'none'
};

export const RoomConstants = {
  ROOM_NO_SINGLE_PLAYING_DEVICE_SESSION,
  ONLY_ONE_PLAYING_DEVICE
};