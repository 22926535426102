import {
  AUTH_USER_BASIC,
  AUTH_USER_FB,
  UNAUTH_USER,
  AUTH_ERROR,
  FB_AUTH_SDK_LOADED,
  AUTH_TYPE_NONE,
  AUTH_TYPE_BASIC,
  AUTH_TYPE_FB,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN,
  LOGGING_IN_TO_DF,
  DONE_LOGGING_IN_TO_DF, AUTH_USER_GOOGLE, AUTH_TYPE_GOOGLE, GOOGLE_AUTH_SDK_LOADED
} from '../actions/types';
import {FETCH_USER_BASIC_PROFILE} from "../actions/current-user.types";

const initialState = {
  error: '',
  authenticated: AUTH_TYPE_NONE,
  displayName: '',
  fbId: '',
  googleId: '',
  dfId: '',
  profilePicUrl: '',
  fbSdkLoaded: false,
  googleSdkLoaded: false,
  refreshTokenPromise: null,
  loginToDfPromise: null,
};

export default function(state = initialState, action) {
  // console.log('Auth Reducer: ', action);

  switch(action.type) {
    case AUTH_USER_BASIC:
      console.log('auth reducer basic', action.payload);
      return {
        ...state,
        error: '',
        authenticated: AUTH_TYPE_BASIC,
        fbId: '',
        displayName: '',
        dfId: ''
      };
    case AUTH_USER_FB:
      console.log('auth reducer fb', action.payload);
      return {
        ...state,
        error: '',
        authenticated: AUTH_TYPE_FB,
        fbId: action.payload.fbId,
        displayName: action.payload.displayName,
        dfId: action.payload.dfId
      };
    case AUTH_USER_GOOGLE:
      console.log('auth reducer google', action.payload);
      return {
        ...state,
        error: '',
        authenticated: AUTH_TYPE_GOOGLE,
        googleId: action.payload.googleId,
        displayName: action.payload.displayName,
        dfId: action.payload.dfId
      };
    case UNAUTH_USER:
      console.log('auth reducer unauth', action.payload);
      return { 
        ...state, 
        authenticated: AUTH_TYPE_NONE, 
        loginToDfPromise: null,
        refreshTokenPromise: null,
        profilePicUrl: ''
      };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case GOOGLE_AUTH_SDK_LOADED:
      return { ...state, error: '', googleSdkLoaded: true };
    case FB_AUTH_SDK_LOADED:
      return { ...state, error: '', fbSdkLoaded: true };
    case DONE_REFRESHING_TOKEN:
      return { ...state, refreshTokenPromise: null };
    case REFRESHING_TOKEN:
      return { ...state, refreshTokenPromise: action.refreshTokenPromise };
    case DONE_LOGGING_IN_TO_DF:
      return { ...state, loginToDfPromise: null };
    case LOGGING_IN_TO_DF:
      return { ...state, loginToDfPromise: action.loginToDfPromise };
    case FETCH_USER_BASIC_PROFILE:
      const userBasicDetails = action.response.data.results;
      return { ...state, profilePicUrl: userBasicDetails.profilePicUrl };
  }

  return state;
}