import * as roomActions from '../actions/room.actions';
import {WsEvents} from '../constants/ws.events';
import {ROOM_LOAD_MIX_SUCCESS, ROOM_UPDATE_VISIBILITY} from "../actions/room.types";

export default store => next => action => {
  const roomName = store.getState().currentRoom.roomName;

  switch(action.type) {
    // Websocket events
    case WsEvents.SERVER_CLEAR_TRACKS:
      store.dispatch(roomActions.clearTracks(action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_PLAY_NEXT:
      store.dispatch(roomActions.playNext(action.payload.nextTrackIndex, action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_QUEUE_TRACK:
      store.dispatch(roomActions.queueTrack(
        action.payload.trackInfo,
        action.payload.trackUserMeta,
        action.payload.triggeredBy
      ));
      break;
    case WsEvents.SERVER_QUEUE_NEXT_TRACK_AND_PLAY:
      store.dispatch(
        roomActions
          .queueNextTrackAndPlay(
            action.payload.trackInfo,
            action.payload.trackUserMeta,
            action.payload.lastPlayingTrack,
            action.payload.triggeredBy
          )
      );
      break;
    case WsEvents.SERVER_QUEUE_NEXT_TRACK:
      store.dispatch(
        roomActions
          .queueNextTrack(
            action.payload.trackInfo,
            action.payload.trackUserMeta,
            action.payload.lastPlayingTrack,
            action.payload.triggeredBy
          )
      );
      break;
    case WsEvents.SERVER_REMOVE_TRACK:
      store.dispatch(roomActions.removeTrack(action.payload.trackIndex, action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_REMOVE_TRACK_SUGGESTION_RESULTS:
      store.dispatch(roomActions.removeTrackFromSuggestions(action.payload.trackInfo));
      break;
    case WsEvents.SERVER_UPVOTE_TRACK:
      store.dispatch(roomActions.upvoteTrack(action.payload.trackInfo));
      break;
    case WsEvents.SERVER_DOWNVOTE_TRACK:
      store.dispatch(roomActions.downvoteTrack(action.payload.trackInfo));
      break;
    case WsEvents.SERVER_CLOSE_ROOM:
      store.dispatch(roomActions.closeRoomByEvent());
      break;
    case WsEvents.SERVER_CURRENT_TRACK_UPDATED:
      store.dispatch(roomActions.updateCurrentTrackIndex(action.payload.currentTrackIndex, action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_ROOM_PROPS_UPDATED:
      store.dispatch(roomActions.loginOverSocket(roomName));
      break;
    case WsEvents.SERVER_USER_ONLINE:
      store.dispatch(roomActions.roomUserIsOnline(action.payload.userOnlineStatusInfo));
      break;
    case WsEvents.SERVER_USER_OFFLINE:
      store.dispatch(roomActions.roomUserIsOffline(action.payload.userOnlineStatusInfo));
      break;
    case WsEvents.SERVER_ADD_REACTION:
      store.dispatch(roomActions.addReaction(
        action.payload.userDfId,
        action.payload.trackIndex,
        action.payload.reactionCode,
      ));
      break;
    case WsEvents.SERVER_REMOVE_REACTION:
      store.dispatch(roomActions.removeReaction(
        action.payload.userDfId,
        action.payload.trackIndex,
      ));
      break;
    case WsEvents.SERVER_PAUSE_ROOM_PLAYBACK:
      store.dispatch(roomActions.pauseRoomPlayback(action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_RESUME_ROOM_PLAYBACK:
      store.dispatch(roomActions.resumeRoomPlayback(action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_SEEK_AT:
      store.dispatch(roomActions.setSeekAt(action.payload.seekPosInSecs, action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_SET_VOLUME:
      store.dispatch(roomActions.setVolume(action.payload.volume, action.payload.triggeredBy));
      break;
    case WsEvents.SERVER_CURRENT_TRACK_PLAYBACK_INFO:
      store.dispatch(roomActions.updateCurrentTrackPlayback(action.payload.currentTrackPlaybackInfo));
      break;
    case WsEvents.SERVER_LOGIN_SUCCESS:
      store.dispatch(roomActions.loginSucceeded(action.payload.roomData));
      break;
    case WsEvents.SERVER_LOGIN_FAILURE:
      store.dispatch(
        roomActions
          .loginFailed(
            action.payload.errorCode,
            action.payload.message
          )
      );
      break;
    case ROOM_LOAD_MIX_SUCCESS:
      store.dispatch(roomActions.emitBroadcastResync());
      break;
    case ROOM_UPDATE_VISIBILITY:
      //store.dispatch(roomActions.loginOverSocket(roomName));
      store.dispatch(roomActions.emitBroadcastResync());
      break;
  }

  // console.log('Pass through room middleware', action);
  return next(action);
}