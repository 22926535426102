const REACTION_1 = 1;
const REACTION_2 = 2;
const REACTION_3 = 3;
const REACTION_4 = 4;
const REACTION_5 = 5;

export const ReactionConstants = {
  REACTION_1,
  REACTION_2,
  REACTION_3,
  REACTION_4,
  REACTION_5,
};