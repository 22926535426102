import {HEADER_HIDE} from '../actions/header.type';
import {HEADER_SHOW} from '../actions/header.type';
import {HEADER_MAKE_TRANSPARENT} from '../actions/header.type';
import {HEADER_MAKE_OPAQUE} from '../actions/header.type';
import {HEADER_TYPES} from "../actions/header.type";
import {HEADER_SET_CONFIG} from "../actions/header.type";

/*
  config = {
    type: string,
    data: Object
  }
  
  All properties are required
 */

const initialState = {
  isTransparent: false,
  isHidden: false,
  config: {
    type: HEADER_TYPES.BASIC,
    data: {}
  }
};

export default function(state = initialState, action) {
  // console.log('Header Reducer: ', action);

  switch(action.type) {
    case HEADER_HIDE:
      return { ...state, isHidden: true };
    case HEADER_SHOW:
      return { ...state, isHidden: false };
    case HEADER_MAKE_TRANSPARENT:
      return { ...state, isTransparent: true };
    case HEADER_MAKE_OPAQUE:
      return { ...state, isTransparent: false };
    case HEADER_SET_CONFIG:
      return { ...state, config: action.config };
  }

  return state;
}