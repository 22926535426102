// Search rooms
export const SEARCH_CLEAR_ALL_LISTS = 'search-clear-all-lists';
export const SEARCH_FETCH_PRIVATE_LIST = 'search-fetch-private-list';
export const SEARCH_FETCH_PRIVATE_LIST_IN_PROGRESS = 'search-fetch-private-list-in-progress';
export const SEARCH_FETCH_PRIVATE_LIST_ERROR = 'search-fetch-private-list-error';
export const SEARCH_FETCH_PUBLIC_LIST = 'search-fetch-public-list';
export const SEARCH_FETCH_PUBLIC_LIST_IN_PROGRESS = 'search-fetch-public-list-in-progress';
export const SEARCH_FETCH_PUBLIC_LIST_ERROR = 'search-fetch-public-list-error';
export const SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST = 'search-fetch-public-accessible-list';
export const SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS = 'search-fetch-public-accessible-list-in-progress';
export const SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST_ERROR = 'search-fetch-public-accessible-list-error';
export const SEARCH_FETCH_SOLO_LIST = 'search-fetch-solo-list';
export const SEARCH_FETCH_SOLO_LIST_IN_PROGRESS = 'search-fetch-solo-list-in-progress';
export const SEARCH_FETCH_SOLO_LIST_ERROR = 'search-fetch-solo-list-error';

// Search Tracks
export const SEARCH_TRACKS = 'search-tracks';
export const SEARCH_TRACKS_IN_PROGRESS = 'search-tracks-in-progress';
export const SEARCH_TRACKS_CLEAR = 'search-tracks-clear';
export const SEARCH_TRACKS_ERROR = 'search-tracks-error';