/**
 * Created by anirudh on 04/11/17.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withRouter} from 'react-router';

import { Menu, MenuItem } from 'react-foundation-components/lib/menu';
import {NavLink, RouteComponentProps } from 'react-router-dom';

const styles = require('./_style.scss');
import * as notificationActions from '../../../actions/notification.actions';

const emptyContainer = (
  <div className={styles.empty}>
    No notifications yet
  </div>
);

interface Props {
  notificationList: any;
  notificationActions: any;
}

class NotificationList extends Component<Props & RouteComponentProps, any> {
  constructor(props)
  {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleNotificationClick(notiId) {
    this.props.notificationActions.emitNotificationRead(notiId)
  }

  render() {
    console.log("rerendering notification list");

    let notificationList = this.props.notificationList.map(notification => {
      let itemStyle = styles.noti_item + ' ' + (notification.read ? styles.noti_item_read : styles.noti_item_unread);

      return(
        <>
          <NavLink
            to={notification.url}
            key={notification.id}
          >
            <MenuItem
              className={itemStyle}
              onClick={() => this.handleNotificationClick(notification.id)}
            >
              <div className={styles.notificationList_container}>
                <div className={styles.notificationThumbnailContainer}>
                  <img
                    className={styles.notificationThumbnail}
                    src={notification.thumbnail || require('../../../../client/assets/img/user_filled_icon.png')}/>
                </div>

                <div className={styles.notificationContentContainer}>
                  <div className={styles.notificationText}>
                    {notification.text}
                  </div>

                  <div className={styles.notificationTimestamp}>
                    {notification.timestampAsText}
                  </div>
                </div>
              </div>
            </MenuItem>
          </NavLink>
        </>
      )
    });

    // console.log(notificationList);

    return (
      <Menu vertical className={styles.menu}>
        {
          (
            !notificationList.length &&
            emptyContainer
          )
          || notificationList
        }
      </Menu>
    )
  }
}

function mapStateToProps(state) {
  return {
    notificationList: state.notifications.notificationList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(withRouter(NotificationList));
// export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(NotificationList);

