import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'react-foundation-components/lib/button';
import {Reveal} from 'react-foundation-components/lib/reveal';
import {CloseButton} from 'react-foundation-components/lib/close-button';
import {Row} from 'react-foundation-components/lib/grid';
import {Column} from 'react-foundation-components/lib/grid';

import * as authActions from '../../../actions/auth.actions';

const styles = require('./_styles.scss');

class GoogleLoginMessageDialog extends Component<any, any> {
  static defaultProps = {
    show: true,
    onHide: () => {}
  };

  onGoogleLoginClick = () => {
    this.props.authActions.loginUser();
    this.props.onHide();
  }

  render(){
    return(
      <Reveal
        revealClassName={styles.dialog}
        onHide={this.props.onHide}
        show={this.props.show} >
        <CloseButton
          onClick={this.props.onHide}
        />
        <Row>
          <Column>
            <h4>
              Please continue login with Google
            </h4>
            <p className={styles.desc}>
              This is to help us remember the rooms you created or joined.
            </p>
          </Column>
        </Row>
        <Row className={styles.buttonRow}>
          <Column>
            <Button onClick={this.onGoogleLoginClick}>Login with Google</Button>
          </Column>
        </Row>
      </Reveal>
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions as any, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(GoogleLoginMessageDialog);
