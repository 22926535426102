import {ReactionConstants} from '../constants/reaction.constants';

const getEmoticonSrc = (code) => {
  switch (code) {
    case ReactionConstants.REACTION_1:
      return require('../../client/assets/img/reactions/emotions-1.png');
    case ReactionConstants.REACTION_2:
      return require('../../client/assets/img/reactions/emotions-2.png');
    case ReactionConstants.REACTION_3:
      return require('../../client/assets/img/reactions/emotions-3.png');
    case ReactionConstants.REACTION_4:
      return require('../../client/assets/img/reactions/emotions-4.png');
    case ReactionConstants.REACTION_5:
      return require('../../client/assets/img/reactions/emotions-5.png');
  }
};

export default {
  getEmoticonSrc,
};
