/**
 * Created by swapnil on 14/05/17.
 */
import { combineReducers } from 'redux';
//import { reducer as formReducer } from 'redux-form';

import authReducer from './auth.reducer';
import dialogReducer from './dialog.reducer';
import roomListReducer from './room-list.reducer';
import currentRoomReducer from './current-room.reducer';
import currentUserReducer from './current-user.reducer';
import searchReducer from './search.reducer';
import headerReducer from './header.reducer';
import globalReducer from './global.reducer';
import notificationReducer from './notification.reducer';
import preferencesReducer from './preferences.reducer';
import wsReducer from './ws.reducer';

const rootReducer = combineReducers({
  //form: formReducer,
  auth: authReducer,
  dialog: dialogReducer,
  roomsList: roomListReducer,
  currentRoom: currentRoomReducer,
  currentUser: currentUserReducer,
  searchStore: searchReducer,
  header: headerReducer,
  global: globalReducer,
  notifications: notificationReducer,
  prefs: preferencesReducer,
  ws: wsReducer,
});

export default rootReducer;