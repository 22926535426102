const suffixes = new Map();
suffixes.set(3, 'K');
suffixes.set(6, 'M');
suffixes.set(9, 'B');
suffixes.set(12, 'T');

const millify = (ugly: number) => {
  // Figure out how many digits in the integer
  const digits = Math.floor(Math.log10(Math.abs(ugly))) + 1;

  // Figure out the appropriate unit for the number
  const units = ((num, zeroes) => {
    for (let z of suffixes.keys()) if (num > z) zeroes = z;
    return { suffix: suffixes.get(zeroes), zeroes }
  })(digits, null);

  const pretty = ugly/Math.pow(10, units.zeroes);

  if (-1000 < ugly && ugly < 1000) return ugly;
  return `${parseFloat(pretty.toPrecision(1))}${units.suffix}`
};

const duration = (ugly: number): string => {
  const hours = Math.floor(ugly/3600);
  const mins = Math.floor(ugly/60) - 60 * hours;
  const secs = ugly % 60;

  let formatted = hours > 0 ? `${hours}:` : '';

  if (mins > 9) {
    formatted += `${mins}:`;
  } else if (mins > 0) {
    formatted += `0${mins}:`;
  } else {
    formatted += '00:';
  }

  if (secs > 9) {
    formatted += `${secs}`;
  } else if (secs > 0) {
    formatted += `0${secs}`;
  } else {
    formatted += '00';
  }

  return formatted;
};

export default {
  millify,
  duration
};