import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from 'rc-slider'
import {Button} from 'react-foundation-components/lib/button';
import {MenuItem} from 'react-foundation-components/lib/menu';
import {Link } from 'react-router-dom';

import OutsideClickAlerter from '../../../../components/hoc/outside-click-alerter/OutsideClickAlerter';
import DropdownBox from '../../../../components/hoc/dropdown-box/component';

const styles = require('./_styles.scss');

import * as roomActions from '../../../../actions/room.actions';
import {RoomConstants} from "../../../../constants/room.constants";
import {AuthConstants} from "../../../../constants/auth";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TooltipSlider = createSliderWithTooltip(Slider);

class DesktopVolumeControl extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      dropdownShown: false
    };
  }

  onHamburgerClick = () => {
    this.setState({dropdownShown: !this.state.dropdownShown});
  };

  handleClickOutside = () => {
    this.setState({dropdownShown: false});
  };

  handleOnChange = (value) => {
    if(this.props.volume !== value) {
      this.props.roomActions.setVolume(value);

      if(
        this.props.onlyOnePlayingDevice.status !== RoomConstants.ONLY_ONE_PLAYING_DEVICE.NONE &&
        this.props.accessLevel >= AuthConstants.SuggesterAccess
      ) {
        this.props.roomActions.emitSetVolume(value);
      }
    }
  };

  renderDropdown = () => {
    return (
      <div className={styles.roomInfoBox}>
        <TooltipSlider
          value={this.props.volume}
          min={0}
          max={100}
          vertical={true}
          trackStyle={{
            backgroundColor: 'rgb(33, 153, 232)',
            width: 8
          }}
          railStyle={{
            backgroundColor: '#e6e6e6',
            width: 8
          }}
          handleStyle={[{
            width: 20,
            height: 20,
            borderRadius: 30,
            backgroundColor: 'rgb(33, 153, 232)',
            border: 'none',
            transform: 'translate3d(-5%, 20%, 0)',
          }]}

          tipProps={{ overlayClassName: styles.tooltip, placement: 'right' }}

          onChange={this.handleOnChange}
        />
      </div>
    );
  };

  render() {
    const {dropdownShown} = this.state;
    const dropDownElem = this.renderDropdown();

    return (
      <div
        className={styles.wrap}
      >
        <Button
          className={styles.volumeBtn}
          onClick={this.onHamburgerClick}
        >
          <img
            src={require('../../../../../client/assets/img/volume.png')}/>
        </Button>


        <DropdownBox
          className={styles.dropdown}
          isShown={dropdownShown}
          hide={this.handleClickOutside}
        >
          {dropDownElem}
        </DropdownBox>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    volume: state.currentRoom.volume,
    accessLevel: state.currentRoom.accessLevel,
    onlyOnePlayingDevice: state.currentRoom.onlyOnePlayingDevice,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(roomActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopVolumeControl);
