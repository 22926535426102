import {SOCKET_CONNECT} from './types';
import {SOCKET_DISCONNECT} from './types';
import {SOCKET_SEND_EVENT} from './types';
import {SOCKET_SEND_AUTHENTICATED_EVENT} from './types';
import {SOCKET_STATUS_CONNECTED} from './types';
import {SOCKET_STATUS_CONNECTING} from './types';
import {SOCKET_STATUS_DISCONNECTED} from './types';
import {WsEvents} from '../constants/ws.events';

import { getAccessToken } from '../middleware/helper/auth-helper';
import {IAuthInfo} from '../middleware/helper/auth.type';
import {SOCKET_FLUSH_PENDING_MESSAGES} from './types';
import {SOCKET_FLUSH_PENDING_MESSAGES_ADD} from './types';
import {IWsClientEvent} from '../middleware/ws.type';
import {SOCKET_FLUSH_PENDING_MESSAGES_REMOVE} from './types';
import {SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS} from './types';
import {SOCKET_PENDING_MESSAGES_FLUSHED} from './types';

export function connected() {
  return { type: SOCKET_STATUS_CONNECTED };
}

export function disconnected() {
  return { type: SOCKET_STATUS_DISCONNECTED };
}

export function connecting() {
  return { type: SOCKET_STATUS_CONNECTING };
}

export function messageReceived(messageData) {
  return {
    type: messageData.eventType,
    payload: messageData.payload
  };
}

export function connect() {
  // Retrieve token here as thunk
  return { type: SOCKET_CONNECT };
}

export function disconnect() {
  return { type: SOCKET_DISCONNECT };
}

export function flushPendingMessages() {
  return { type: SOCKET_FLUSH_PENDING_MESSAGES};
}

export function pendingMessagesFlushInProgress() {
  return { type: SOCKET_PENDING_MESSAGES_FLUSH_IN_PROGRESS};
}

export function pendingMessagesFlushComplete() {
  return { type: SOCKET_PENDING_MESSAGES_FLUSHED};
}

export function addPendingMessages(wsMessage: IWsClientEvent) {
  return {
    type: SOCKET_FLUSH_PENDING_MESSAGES_ADD,
    wsMessage,
  };
}

export function removePendingMessages(wsMessage: IWsClientEvent) {
  return {
    type: SOCKET_FLUSH_PENDING_MESSAGES_REMOVE,
    wsMessage,
  };
}

export function loginOverSocket() {
  return (dispatch, getState) => {
    dispatch(sendAuthenticated({
      eventType: WsEvents.CLIENT_LOGIN
    }));
  }
}

export function sendAuthenticated(evt) {
  return (dispatch, getState) => {
    console.log('*** Event thunk ', evt.eventType);

    const accessTokenPromise = getAccessToken(dispatch, getState);
    accessTokenPromise.then((authInfo: IAuthInfo) => {
      dispatch({
        type: SOCKET_SEND_AUTHENTICATED_EVENT,
        accessToken: authInfo.token.accessToken,
        eventType: evt.eventType,
        eventData: evt.eventData
      });
    });
  }
}

export function sendUnauthenticated(evt) {
  return {
    type: SOCKET_SEND_EVENT,
    eventType: evt.eventType,
    eventData: evt.eventData
  };
}