import uuidV4 from 'uuid/v4';

import {RoomStatics} from './component.statics';
import YoutubeWrapper from '../../components/youtube-wrapper/component';

const baseStyles = require('./_style.scss');

export interface IPlaybackEvent {
  persistent: boolean;
  error: boolean;
  val: string;
  messageId: string;
  triggeredBy?: {
    dfId: string;
    displayName: string;
  }
}

export interface ISocketConnStatus {
  persistent: boolean;
  error: boolean;
  val: string;
  messageId: string;
  className?: string;
}

const getNextPlayerIndex = (currentPlayerIndex) => {
  let nextPlayerIndex = currentPlayerIndex+1;
  nextPlayerIndex = nextPlayerIndex%(RoomStatics.noPlayers.length);
  return nextPlayerIndex;
};

const getUpdatedTrackQueue = (
  currentTrackIndex,
  nextTrackIndex,
  currentPlayerIndex,
  playlist
  ) => {
  let playerIndexIter = currentPlayerIndex;
  let counter = 0;
  let trackQueue = RoomStatics.noPlayers.map(() => null);

  while(counter != RoomStatics.noPlayers.length) {
    if(playerIndexIter == currentPlayerIndex) {
      trackQueue[playerIndexIter] = playlist[currentTrackIndex] ? playlist[currentTrackIndex] : null;
    } else {
      trackQueue[playerIndexIter] = playlist[nextTrackIndex] ? playlist[nextTrackIndex] : null;
      nextTrackIndex++;
    }

    playerIndexIter = getNextPlayerIndex(playerIndexIter);
    counter++;
  }

  // console.log("Generating track queue for video "+currentTrackIndex+" player "+currentPlayerIndex);
  // console.log(trackQueue);

  return trackQueue;
};

const getUpdatedPlayerStates = (
  currentPlayerState,
  nextPlayerState,
  currentPlayerIndex,
  oldTrackQueue,
  updatedTrackQueue,
  oldPlayerStates
  ) => {
  let i;
  let nextPlayerIndex = getNextPlayerIndex(currentPlayerIndex);
  let newPlayerStates = RoomStatics.noPlayers.map(() => YoutubeWrapper.playbackStates.UNSTARTED);

  for(i=0; i < RoomStatics.noPlayers.length; i++) {
    if(i == currentPlayerIndex) {
      newPlayerStates[i] = currentPlayerState;
    }
    else if (oldPlayerStates[i] == YoutubeWrapper.playbackStates.BUFFERED
      && oldTrackQueue[i] != null
      && updatedTrackQueue[i] != null
      && oldTrackQueue[i].id == updatedTrackQueue[i].id) {

      if(i != nextPlayerIndex || nextPlayerState == YoutubeWrapper.playbackStates.UNSTARTED) {
        newPlayerStates[i] = YoutubeWrapper.playbackStates.BUFFERED;
      } else {
        newPlayerStates[i] = nextPlayerState;
      }
    } else if(i == nextPlayerIndex) {
      newPlayerStates[i] = nextPlayerState;
    }
  }

  /*
  console.log("Old track queue:");
  console.log(oldTrackQueue);
  console.log("New track queue:");
  console.log(updatedTrackQueue);
  console.log("Old player states:");
  console.log(oldPlayerStates);
  console.log("New player states:");
  console.log(newPlayerStates);*/

  return newPlayerStates;
};

const isPlaylistEqual = (list1, list2) => {
  let isSame = true;

  if(list1.length == list2.length) {

    for(let i=0; i<list1.length && isSame; i++) {
      if(list1[i].id != list2[i].id) {
        isSame = false;
      }
    }
  } else {
    isSame = false;
  }

  return isSame;
};

const getCurrentPlayingVideoInfo = (currentTrackIndex, currentPlaylist) => {

  if(currentPlaylist.length <= 0 || currentTrackIndex < 0 || currentTrackIndex >= currentPlaylist.length) {
    return undefined;
  } else {
    return currentPlaylist[currentTrackIndex];
  }
};

const getPlaybackEventMessage = (event, triggeredBy) => {
  const playbackEvent: IPlaybackEvent = {
    persistent: false,
    error: false,
    val: '',
    messageId: ''
  };

  switch (event) {
    case RoomStatics.playbackEvents.QUEUE:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Queued Song';
      break;
    case RoomStatics.playbackEvents.QUEUENEXT:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Queued Next';
      break;
    case RoomStatics.playbackEvents.PLAYNEXT:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Playing Next';
      break;
    case RoomStatics.playbackEvents.ADDANDPLAY:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Queued Next And Playing';
      break;
    case RoomStatics.playbackEvents.CLEAR:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Cleared Playlist';
      break;
    case RoomStatics.playbackEvents.FADING:
      playbackEvent.persistent = true;
      playbackEvent.val = 'Fading in next song';
      break;
    case RoomStatics.playbackEvents.FADECOMPLETE:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Enjoy!';
      break;
    case RoomStatics.playbackEvents.REMOVED:
      playbackEvent.persistent = false;
      playbackEvent.val = 'Removed Track!';
      break;
  }

  playbackEvent.messageId = uuidV4();
  playbackEvent.triggeredBy = triggeredBy;

  return playbackEvent;
};

const getSocketConnectionStateMessage = (connState): ISocketConnStatus => {
  const socketStatus: ISocketConnStatus = {
    persistent: false,
    error: false,
    val: '',
    messageId: ''
  };

  switch (connState) {
    case RoomStatics.socketStates.DISCONNECTED:
      socketStatus.persistent = true;
      socketStatus.error = true;
      socketStatus.val = 'Disconnected';
      break;
    case RoomStatics.socketStates.CONNECTED:
      socketStatus.persistent = true;
      socketStatus.error = false;
      socketStatus.val = 'Connecting...';
      break;
    case RoomStatics.socketStates.LOGGINGIN:
      socketStatus.persistent = true;
      socketStatus.error = false;
      socketStatus.className = baseStyles.connectionState__syncing;
      socketStatus.val = 'Syncing...';
      break;
    case RoomStatics.socketStates.LOGGEDIN:
      socketStatus.persistent = false;
      socketStatus.error = false;
      socketStatus.className = baseStyles.connectionState__synced;
      socketStatus.val = 'All set!';
      break;
    case RoomStatics.socketStates.SERVERERROR:
      socketStatus.persistent = true;
      socketStatus.error = true;
      socketStatus.val = 'Something went wrong, please try again';
      break;
    case RoomStatics.socketStates.UNAUTHORISED:
      socketStatus.persistent = true;
      socketStatus.error = true;
      socketStatus.val = 'You dont have access to this room';
      break;
    case RoomStatics.socketStates.INVALIDROOMNAME:
      socketStatus.persistent = true;
      socketStatus.error = true;
      socketStatus.val = 'Invalid Room Name';
      break;
  }

  socketStatus.messageId = uuidV4();

  return socketStatus;
};

const isRoomInTransition = (roomState) => {
  return roomState === RoomStatics.states.TRACK_WAITING_FOR_TRANSITION
    || roomState === RoomStatics.states.TRACK_CROSS_TRANSITION
    || roomState === RoomStatics.states.TRACK_DIRECT_TRANSITION
};

const getUpdatedMessagesByPlaybackEvent = (messages, event, triggeredBy) => {
  return {
    ...messages,
    playbackEvent: getPlaybackEventMessage(event, triggeredBy)
  };
};

const getUpdatedMessagesByConnectionState = (messages, socketConnState) => {
  return {
    ...messages,
    socketStatus: getSocketConnectionStateMessage(socketConnState)
  };
};

export default {
  isPlaylistEqual: isPlaylistEqual,
  isRoomInTransition: isRoomInTransition,
  getNextPlayerIndex: getNextPlayerIndex,
  getUpdatedTrackQueue: getUpdatedTrackQueue,
  getUpdatedPlayerStates: getUpdatedPlayerStates,
  getCurrentPlayingVideoInfo: getCurrentPlayingVideoInfo,
  getPlaybackEventMessage: getPlaybackEventMessage,
  getSocketConnectionStateMessage: getSocketConnectionStateMessage,
  getUpdatedMessagesByPlaybackEvent,
  getUpdatedMessagesByConnectionState
};