import React, { Component } from 'react';
import ReactYoutube from 'react-youtube';
import uuidV4 from 'uuid/v4';

import {NO_SEEK} from "../../containers/room/component.state";
import {getCurrentSystemTime} from "../../utils/DateTimeUtil";

const styles = require('./_style.scss');

let YTPlayerStates = ReactYoutube.PlayerState;

class YoutubeWrapper extends Component<any, any> {
  static scheduleTransitionPercentage = 90;
  static bufferedRequirementInSeconds = 0.1;

  static fadeSpeeds = {
    FADE_IN: 47,
    FADE_OUT: 43,
    FADE_OUT_BULK: 43 * 100
  };

  static playbackStates = {
    UNSTARTED: 'UNSTARTED',
    BUFFERED: 'BUFFERED',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    PAUSED_BY_USER: 'PAUSED_BY_USER',
    ENDED: 'ENDED',
    FADING_IN: 'FADING_IN',
    FADING_OUT: 'FADING_OUT',
    FADE_OUT_COMPLETE: 'FADE_OUT_COMPLETE',
    FADE_IN_COMPLETE: 'FADE_IN_COMPLETE'
  };

  static defaultProps = {
    opts: {},
    videoId:  null,
    videoTitle: 'NoVideo',
    playBackStateToBeIn: YoutubeWrapper.playbackStates.PAUSED,
    volume: 100,
    seekAt: NO_SEEK,
    onReady: () => {},
    onError: () => {},
    onPlay: () => {},
    onPause: () => {},
    onEnd: () => {},
    onShouldPlayNext: () => {},
    onPlayBackStateChange: () => {},
    onInnerPlayerPlayBackStateChange: () => {},
    onSeekConsumed: () => {},
    onFadeOutComplete: () => {},
    onFadeInComplete: () => {},
    onPlaybackRateChange: () => {},
    onPlaybackQualityChange: () => {},
  };

  static getKeyByValue = (obj, val) => {
    for( var prop in obj ) {
      if( obj.hasOwnProperty( prop ) ) {
        if( obj[ prop ] === val )
          return prop;
      }
    }
  };

  static translatePlaybackState = (val) => {
    return YoutubeWrapper.getKeyByValue(YoutubeWrapper.playbackStates, val);
  };

  static translateYoutubePlayerState = (val) => {
    return YoutubeWrapper.getKeyByValue(YTPlayerStates, val);
  };

  constructor(props) {
    super(props);

    this.state = {
      player: null,
      playerVolume: 0,
      oldVideoStillLoaded: true,
      isFadingOut: false,
      isFadingIn: false,
      stopFade: false,
      playerState: YTPlayerStates.ENDED,
      playbackState: YoutubeWrapper.playbackStates.UNSTARTED,
      transitionScheduler: {
        uid: '',
        timeoutId: '',
      },
    }
  }

  checkSomethingUpdated(nextProps, nextState) {
    return nextProps.videoId !== this.props.videoId
      || nextProps.volume !== this.props.volume
      || nextProps.playBackStateToBeIn !== this.props.playBackStateToBeIn
      || nextState.playbackState !== this.state.playbackState
      || nextState.oldVideoStillLoaded !== this.state.oldVideoStillLoaded
      || nextState.playerState !== this.state.playerState
      || nextState.stopFade !== this.state.stopFade
      || nextState.isFadingOut !== this.state.isFadingOut
      || nextState.isFadingIn !== this.state.isFadingIn;
  }

  componentWillUnmount() {
    this.setState({player: null});
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.checkSomethingUpdated(nextProps, nextState) ||
      nextState.playerVolume !== this.state.playerVolume ||
      nextProps.seekAt !== this.props.seekAt ||
      nextState.transitionScheduler !== this.state.transitionScheduler
    );
  }

  componentDidUpdate(prevProps, prevState) {

    this.log("CompUpdating: from"
      + " playBackStateToBeIn: " + prevProps.playBackStateToBeIn
      + " to "+this.props.playBackStateToBeIn
      + " playbackState: " + prevState.playbackState
      + " to "+this.state.playbackState
      );

    /*console.log(' PL-'+this.props.playerId, {
      prev: prevState,
      current: this.state
    });*/

    if(prevProps.videoId !== this.props.videoId) {
      this.resetPlayback();
    } else if(this.checkSomethingUpdated(prevProps, prevState)){
      this.handleUpdate(prevProps, prevState);
    }

    if(this.state.playbackState !== prevState.playbackState) {
      this.log('Notifying playback stae change: From '+prevState.playbackState+' To: '+this.state.playbackState);
      this.props.onPlayBackStateChange(this.state.playbackState);
      this._onInnerPlayerPlayBackStateChange(this.state.playerState);
    }

    if(this.state.playerVolume !== prevState.playerVolume) {
      this.setPlayerRealVolume(this.state.playerVolume);
    }

    if(this.props.seekAt !== NO_SEEK) {
      this.seekTrackTo(this.props.seekAt);
      this.props.onSeekConsumed();
    }

    if(prevState.transitionScheduler.uid !== this.state.transitionScheduler.uid) {
      // this.log(`TESTTTTTT >>> CHANGED ${prevState.transitionScheduler.uid} ${this.state.transitionScheduler.uid} `);
      this.cancelPreviousTransition(prevState);

      if(this.state.transitionScheduler.uid !== '') {
        this.scheduleTransition();
      }
    }
  }

  scheduleTransition() {
    const duration = this.state.player.getDuration(); //In seconds
    const scheduleTime = duration * YoutubeWrapper.scheduleTransitionPercentage * 10 - this.state.player.getCurrentTime() * 1000; //In millis

    // this.log("TESTTTTTT >>> Scheduling transition in "+scheduleTime+" Duration: "+duration);

    const timeoutId = setTimeout(this.props.onShouldPlayNext.bind(this), scheduleTime);
    this.setState({
      transitionScheduler: {
        uid: this.state.transitionScheduler.uid,
        timeoutId,
      }
    });
  }

  cancelPreviousTransition(prevState) {
    if(prevState.transitionScheduler.timeoutId != null) {
      // this.log('TESTTTTTT >>> kill ' + prevState.transitionScheduler.timeoutId);

      clearTimeout(prevState.transitionScheduler.timeoutId);
    }
  }

  setNewTransitionScheduler = () => {
    const newSchedulerId = uuidV4();

    // this.log('TESTTTTTT > set new scheduler '+newSchedulerId);
    this.setState({
      transitionScheduler: {
        uid: newSchedulerId,
        timeoutId: this.state.transitionScheduler.timeoutId,
      }
    });
  };

  clearOldTransitionScheduler = () => {
    // this.log('TESTTTTTT clear old scheduler');

    this.setState({
      transitionScheduler: {
        uid: '',
        timeoutId: this.state.transitionScheduler.timeoutId,
      }
    });
  };

  isVideoBufferLoaded() {
    const loadedFraction = this.state.player.getVideoLoadedFraction();
    const durationInSeconds = this.state.player.getDuration();

    this.log("Fraction " + loadedFraction * durationInSeconds);

    return (
      loadedFraction > 0 &&
      loadedFraction * durationInSeconds > YoutubeWrapper.bufferedRequirementInSeconds
    );
  }

  handleUnstartedCondition(prevProps, prevState) {
    if(this.getPlayerVolume() > 0) {
      this.setPlayerVolume(0);
    }

    this.log('Handling unstarted: volume '+this.getPlayerVolume());

    if(this.isVideoPaused(this.state.playerState)) {
      this.startPlayingVideo(0);
    } else if(this.state.oldVideoStillLoaded) {
      if(
        prevState.playerState !== YTPlayerStates.PLAYING &&
        (
          this.state.playerState === YTPlayerStates.BUFFERING ||
          this.state.playerState === YTPlayerStates.PLAYING
        )
      ) {
        this.setState({oldVideoStillLoaded: false});
      }
    } else if(this.isVideoBufferLoaded()) {
      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});
    } else {
      this.checkAndStopFadeTransition();
    }
  }

  handleUpdate(prevProps, prevState) {
    //console.log("Handling state as "+this.props.playState+" for "+this.props.playerId);

    if(this.state.player === null) {
      this.log("Player not ready");
      return;
    }

    // We simply clear transition and let it be
    // set again if its in playing state
    this.clearOldTransitionScheduler();

    switch (this.props.playBackStateToBeIn) {
      case YoutubeWrapper.playbackStates.UNSTARTED:
        this.checkForUnstartedPlayback(prevProps, prevState);
        break;
      case YoutubeWrapper.playbackStates.BUFFERED:
        this.checkForBufferredPlayback(prevProps, prevState);
        break;
      case YoutubeWrapper.playbackStates.PAUSED:
        this.checkForPausedPlayback(prevProps, prevState);
        break;
      case YoutubeWrapper.playbackStates.PLAYING:
        this.checkForPlayingPlayback(prevProps, prevState);
        break;
      case YoutubeWrapper.playbackStates.FADING_IN:
        this.checkForFadingInPlayback(prevProps, prevState);
        break;
      case YoutubeWrapper.playbackStates.FADING_OUT:
        this.checkForFadingOutPlayback(prevProps, prevState);
        break;
    }
  }

  checkForUnstartedPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.checkAndStopFadeTransition();

      if(this.state.playerState === YTPlayerStates.PLAYING) {
        this.pausePlayingVideo();
      }

      //Might get called multiple times
      //this.props.onBuffered();

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    }
  }

  checkForBufferredPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.checkAndStopFadeTransition();

      if(this.state.playerState === YTPlayerStates.PLAYING) {
        this.pausePlayingVideo();
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      //this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});
      console.error('Invalid state');

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.BUFFERED});

    }
  }

  checkForPausedPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.checkAndStopFadeTransition();

      if(this.state.playerState === YTPlayerStates.PLAYING) {
        this.pausePlayingVideo();
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED});

    }
  }

  checkForPlayingPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      if(this.state.playerState === YTPlayerStates.PLAYING) {
        this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      //this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});
      //Emit an event

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.checkAndStopFadeTransition();

      if(this.state.playerState === YTPlayerStates.PAUSED) {
        if(prevState.playerState === YTPlayerStates.PLAYING) {
          this.setState({playbackState: YoutubeWrapper.playbackStates.PAUSED_BY_USER});
        } else {
          this.startPlayingVideo();
        }

      } else {
        this.log('Playing Volume check '+this.getPlayerVolume());

        if(this.getPlayerVolume() !== this.props.volume) {
          this.setPlayerVolume(this.props.volume);
        }
      }

      if(this.state.playerState === YTPlayerStates.PLAYING) {
        this.setNewTransitionScheduler();
      } else {
        this.clearOldTransitionScheduler();
      }

      if(this.state.playerState === YTPlayerStates.ENDED) {
        this.setState({playbackState: YoutubeWrapper.playbackStates.ENDED});
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.PLAYING});

    }
  }

  checkForFadingInPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      //this.setState({playbackState: YoutubeWrapper.playbackStates.UNSTARTED});
      //console.err('Invalid state');

      this.setState({
        playbackState: YoutubeWrapper.playbackStates.UNSTARTED,
        oldVideoStillLoaded: true
      });

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      if(this.state.playerState === YTPlayerStates.PAUSED) {
        this.startPlayingVideo(0);
      } else {
        if(!this.state.isFadingIn) {
          if(this.getPlayerVolume() > 0) {
            this.setPlayerVolume(0);
          }

          this.fadeIn();
        }
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_IN});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      //this.props.onFadeInComplete();

    }
  }

  checkForFadingOutPlayback (prevProps, prevState){
    if(this.state.playbackState === YoutubeWrapper.playbackStates.UNSTARTED) {
      this.handleUnstartedCondition(prevProps, prevState);

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.BUFFERED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PAUSED_BY_USER) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.ENDED) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.PLAYING) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_IN) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADING_OUT) {

      if(this.state.playerState === YTPlayerStates.PAUSED) {
        this.startPlayingVideo();
      } else if(this.state.playerState === YTPlayerStates.ENDED) {
        this.bulkFadeOut();
      } else {
        if(!this.state.isFadingOut) {
          if(this.getPlayerVolume() < this.props.volume) {
            this.setPlayerVolume(this.props.volume);
          }

          this.fadeOut();
        }
      }

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE) {

      //this.props.onFadeOutComplete();

    } else if(this.state.playbackState === YoutubeWrapper.playbackStates.FADE_IN_COMPLETE) {

      this.setState({playbackState: YoutubeWrapper.playbackStates.FADING_OUT});

    }
  }

  bulkFadeOut() {
    let that = this;

    setTimeout(() => {
      that.setState({playbackState: YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE});
    }, YoutubeWrapper.fadeSpeeds.FADE_OUT_BULK);
  }

  fadeOut() {
    let that = this;

    this.setState({isFadingOut: true, isFadingIn: false, stopFade: false});
    this.log("Fading out video");

    var fadeOuter = () => {
      let newVolume = that.getPlayerVolume()-1;

      //this.log("****Fading out volume: "+newVolume);

      that.setPlayerVolume(newVolume);

      if(!this.state.stopFade) {
        if (newVolume > 0) {
          setTimeout(fadeOuter, YoutubeWrapper.fadeSpeeds.FADE_IN);
        } else {
          this.fadeOutComplete();
        }
      } else {
        this.setState({isFadingOut: false, isFadingIn: false, stopFade: false});
      }
    };

    fadeOuter();
  }

  fadeOutComplete() {
    this.setState({isFadingOut: false, playbackState: YoutubeWrapper.playbackStates.FADE_OUT_COMPLETE});
  }

  fadeIn() {
    let that = this;

    this.setState({isFadingOut: false, isFadingIn: true, stopFade: false});
    this.log("Fading in video buffered fraction "+this.state.player.getVideoLoadedFraction());

    var fadeIner = () => {
      let newVolume = that.getPlayerVolume()+1;
      that.setPlayerVolume(newVolume);

      if(!this.state.stopFade) {
        if(newVolume < this.props.volume) {
          setTimeout(fadeIner, YoutubeWrapper.fadeSpeeds.FADE_IN);
        } else {
          this.fadeInComplete();
        }
      } else {
        this.setState({isFadingOut: false, isFadingIn: false, stopFade: false});
      }
    };

    fadeIner();
  }

  fadeInComplete() {
    this.setState({isFadingIn: false, playbackState: YoutubeWrapper.playbackStates.FADE_IN_COMPLETE});
  }

  resetPlayback() {
    if(this.isFadeTransitioning()) {
      this.stopFadeTransition();
    }

    this.clearOldTransitionScheduler();
    this.setState({
      playbackState: YoutubeWrapper.playbackStates.UNSTARTED,
      oldVideoStillLoaded: true,
      playerVolume: 0
    });
  }

  _onReady = (event) => {
    const player = event.target;

    this.setState({player});
    this.setPlayerRealVolume(this.state.playerVolume);

    this.log("I am ready: state as "+YoutubeWrapper.translatePlaybackState(this.state.playbackState));
    this.props.onReady(event);
  };

  _onPlayerStateChange = (event) => {
    this.log(">> on youtube state change as "
      +YoutubeWrapper.translateYoutubePlayerState(event.data)
      +" from "+YoutubeWrapper.translateYoutubePlayerState(this.state.playerState)
      +'\n '+this.props.videoTitle);

    this.setState({playerState: event.data});

    this._onInnerPlayerPlayBackStateChange(event.data);
  };

  _onInnerPlayerPlayBackStateChange = (playerState) => {
    const player = this.state.player;

    this.props.onInnerPlayerPlayBackStateChange({
      status: playerState,
      trackElapsed: player.getCurrentTime(),
      trackDuration: player.getDuration()
    });
  };

  startPlayingVideo(volume = this.props.volume) {
    this.log("Volume: "+volume);

    if (process.env.NODE_ENV !== 'production') {
      this.log('Playing smaller video in development!');
      this.state.player.setPlaybackQuality('small');
    }

    this.setPlayerVolume(volume);
    this.state.player.playVideo();
  }

  pausePlayingVideo() {
    this.log('Pausing video!');
    this.state.player.pauseVideo();
    this.clearOldTransitionScheduler();
  }

  stopPlayingVideo() {
    this.setPlayerVolume(0);
    this.state.player.pauseVideo();
    this.clearOldTransitionScheduler();
  }

  isVideoPaused(playerState) {
    return playerState === YTPlayerStates.PAUSED || playerState === YTPlayerStates.CUED
  }

  setPlayerVolume(val) {
    this.setState({playerVolume: val});
  }

  getPlayerVolume() {
    return this.state.playerVolume;
  }

  setPlayerRealVolume(val) {
    //console.error(' PL-'+this.props.playerId+'Setting volume ' + val)
    this.state.player.setVolume(val);
  }

  getPlayerRealVolume() {
    return this.state.player.getVolume();
  }

  seekTrackTo = (timeInSecs) => {
    this.state.player.seekTo(timeInSecs);

    setTimeout(() => {
      this._onInnerPlayerPlayBackStateChange(this.state.playerState);
    }, 300)
  };

  checkAndStopFadeTransition() {
    if(this.isFadeTransitioning()) {
      this.stopFadeTransition();
    }
  }

  isFadeTransitioning() {
    return this.state.isFadingIn || this.state.isFadingOut;
  }

  stopFadeTransition() {
    this.setState({isFadingOut: false, isFadingIn: false, stopFade: true});

    this.log('Stopping fade transition!');
  }

  log(message) {
    console.log(getCurrentSystemTime()+' PL-'+this.props.playerId+'= ', message);
  }

  render() {
    return (
      <div className={styles.reactYoutubeSpanFix}>
        <ReactYoutube
          className={this.props.className}
          videoId={this.props.videoId}
          opts={this.props.opts}
          onReady={this._onReady}
          onStateChange={this._onPlayerStateChange}
        />
      </div>
    );
  }
}

export default YoutubeWrapper