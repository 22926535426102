import React, { PureComponent } from 'react';
import ReactImageFallback from "react-image-fallback";
import {Scrollbars} from 'react-custom-scrollbars';

import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';

import ReactionUtil from '../../../../utils/reaction.util';

const styles = require('./_styles.scss');

class ReactionUserScrollList extends PureComponent<any, any> {
  static defaultProps = {
    reactionList: []
  };

  scrollContElement;

  constructor(props) {
    super(props);

    this.state = {
      scrollContainerHeight: 0
    };
  }

  componentDidMount() {
    const scrollContainerHeight =
      this.scrollContElement ?
        this.scrollContElement.clientHeight :
        this.state.scrollContainerHeight;

    this.setState({
      scrollContainerHeight
    });
  }

  render() {
    return (
      <div
        className={styles.scrollCont}
        ref={(elem) => this.scrollContElement = elem}
      >
        {
          this.state.scrollContainerHeight > 0 &&

          <Scrollbars
            autoHeight={true}
            autoHeightMax={this.state.scrollContainerHeight}
          >
            {this.renderList()}
          </Scrollbars>
        }
      </div>
    );
  }

  renderList = () => {
    return (
      <div className={styles.listWrap}>
        {
          this.props.reactionList.map(item => {
            return (
              <FlexRow className={styles.listItem}>
                <FlexColumn
                  className={styles.listThumb}
                  large={3}
                  small={3}
                >
                  <ReactImageFallback
                    src={item.profilePicUrl}
                    fallbackImage={require("../../../../../client/assets/img/track-empty-thumb.png")}
                    initialImage={require("../../../../../client/assets/img/track-empty-thumb.png")}
                    className={styles.listThumbImg}
                  />
                </FlexColumn>

                <FlexColumn
                  className={styles.listUsername}
                  large={7}
                  small={7}
                >
                  {item.displayName}
                </FlexColumn>

                <FlexColumn
                  className={styles.listReaction}
                  large={2}
                  small={2}
                >
                  <img src={ReactionUtil.getEmoticonSrc(item.code)} />
                </FlexColumn>
              </FlexRow>
            );
          })
        }
      </div>
    );
  };
}

export default ReactionUserScrollList;
