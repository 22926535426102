import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';

// Foundation Components
import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';
import {Button} from 'react-foundation-components/lib/button';
import Reveal from 'react-foundation-components/lib/reveal';
import CloseButton from 'react-foundation-components/lib/close-button';

import InviteInput from './invite-input.component';
import ConsumeInviteState from '../../../common/consume-invite.component.state';
import {INVITE_CODE_CONSUME_LOADING} from "../../../reducers/room-list.reducer";
import {INVITE_CODE_CONSUME_NONE} from "../../../reducers/room-list.reducer";
import {INVITE_CODE_CONSUME_FAILURE} from "../../../reducers/room-list.reducer";
import {INVITE_CODE_CONSUME_SUCCESS} from "../../../reducers/room-list.reducer";

import * as roomActions from '../../../actions/room.actions';
import * as headerActions from '../../../actions/header.actions';
import * as dialogActions from '../../../actions/dialog.actions';

import {AUTH_TYPE_GOOGLE} from '../../../actions/types';
import {DIALOG_TYPES} from '../../../actions/dialog.type';

const styles = require('./_style.scss');
const loadingSpinnerStyles = require('../../../common/loading-spinner.scss');

class JoinRoom extends ConsumeInviteState<any, any> {
  static defaultProps = {
    onHide: () => {},
    show: false
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      inviteCodePart1: '',
      inviteCodePart2: '',

      joinRoomClickBlockedByAuthInfo: null,
    }
  }

  inviteCodeChangeHandler(inviteCodePart1, inviteCodePart2) {
    this.setState({
      joinRoomClickBlockedByAuthInfo: null,
      inviteCodePart1,
      inviteCodePart2
    });

    if(this.props.inviteCodeConsume.status === INVITE_CODE_CONSUME_FAILURE) {
      this.props.roomActions.resetConsumeInviteCode();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkAndRedirect(prevProps, prevState, 1500);

    if(
      this.state.initiatedRequest &&
      (
        this.props.inviteCodeConsume.status === INVITE_CODE_CONSUME_FAILURE ||
        this.props.inviteCodeConsume.status === INVITE_CODE_CONSUME_NONE
      )
    ) {
      this.setState({
        initiatedRequest: false
      })
    }
    
    if(prevProps.show && !this.props.show) {
      // Cleanup
      this.props.roomActions.resetConsumeInviteCode();

      this.setState({joinRoomClickBlockedByAuthInfo: null});
    }

    if(!prevProps.show && this.props.show) {
      // Cleanup
      this.setState({
        inviteCodePart1: '',
        inviteCodePart2: ''
      })
    }

    if(prevProps.authenticated !== this.props.authenticated && this.props.authenticated === AUTH_TYPE_GOOGLE) {
      // User now logged in
      const joinRoomClickBlockedByAuthInfo = this.state.joinRoomClickBlockedByAuthInfo;

      if(joinRoomClickBlockedByAuthInfo !== null) {
        this.setState({joinRoomClickBlockedByAuthInfo: null});

        const code = this.getInviteCode(
          joinRoomClickBlockedByAuthInfo.inviteCodePart1,
          joinRoomClickBlockedByAuthInfo.inviteCodePart2
        );
        this.submitInviteCode(code);
      }
    }
  }

  getInviteCode = (inviteCodePart1, inviteCodePart2) => {
    return inviteCodePart1 + '-' + inviteCodePart2;
  };

  submitInviteCode = (fullInviteCode) => {
    this.checkAndSendRequest(fullInviteCode);
  };

  onUserSubmit = () => {
    if(this.props.authenticated !== AUTH_TYPE_GOOGLE) {
      this.setState({
        joinRoomClickBlockedByAuthInfo: {
          inviteCodePart1: this.state.inviteCodePart1,
          inviteCodePart2: this.state.inviteCodePart2
        }
      });

      this.openLoginDialog();
    } else {
      const code = this.getInviteCode(
        this.state.inviteCodePart1,
        this.state.inviteCodePart2
      );

      this.submitInviteCode(code)
    }
  };

  openLoginDialog = () => {
    this.props.dialogActions.showDialog(DIALOG_TYPES.FB_LOGIN);
  };

  onClose() {
    this.props.onHide();
    this.setState({
      inviteCode: ''
    })
  }

  renderLoadingElem = () => {
    return (
      <div>
        <div className={loadingSpinnerStyles.loadingSpinnerWrap}>
          <div
            className={loadingSpinnerStyles.loadingSpinner} >
          </div>
        </div>
      </div>
    );
  };

  renderForm = (errorMessage) => {
    return (
      <div className={styles.formCont}>
          <InviteInput
            inviteCodePart1={this.state.inviteCodePart1}
            inviteCodePart2={this.state.inviteCodePart2}
            inviteCodeChangeHandler={this.inviteCodeChangeHandler.bind(this)}
            onSubmit={this.onUserSubmit}
            />

          {
            errorMessage &&
            <FlexRow horizontalAlignment="center">
              <FlexColumn className={styles.errorMessage}>
                {errorMessage}
              </FlexColumn>
            </FlexRow>
          }

          <FlexRow className={styles.formRow} horizontalAlignment="center">
            <FlexColumn>
              <Button
                className={styles.fullButton}
                onClick={this.onUserSubmit}>
                Enter
              </Button>
            </FlexColumn>
          </FlexRow>
      </div>
    );
  };

  renderContentElem = (inviteCodeConsume) => {
    switch (inviteCodeConsume.status) {
      case INVITE_CODE_CONSUME_LOADING:
        return this.renderLoadingElem();
      case INVITE_CODE_CONSUME_FAILURE:
        return this.renderForm('Invalid or expired invite code');
      default:
        return this.renderForm(undefined);
    }
  };

  renderDialog = (inviteCodeConsume) => {
    const contentlem = this.renderContentElem(inviteCodeConsume);
    const formElem = (
      <FlexRow className={styles.flexRowFull + ' ' + styles.dialogMinHeight} horizontalAlignment="center">
        <FlexColumn
          small={10} medium={8} large={8}
        >
          <FlexRow className={styles.formRow}>
            <FlexColumn>
              <h4 className={styles.dialogHead}>Enter Invite Code</h4>
            </FlexColumn>
          </FlexRow>

          {contentlem}

        </FlexColumn>
      </FlexRow>
    );
    const successElem = (
      <div className={styles.successMessage + ' ' + styles.dialogMinHeight}>
        <h4 className={styles.dialogHead}>Success! Redirecting you...</h4>
      </div>
    );

    if(inviteCodeConsume.status === INVITE_CODE_CONSUME_SUCCESS) {
      return successElem;
    } else {
      return formElem;
    }
  };

  render() {
    const dialogElem = this.renderDialog(this.props.inviteCodeConsume);

    return(
      <Reveal
        show={this.props.show}
        revealClassName={styles.joinRoomDialog}
        onHide={this.onClose.bind(this)}>
        <CloseButton
          onClick={this.onClose.bind(this)} />

        <div className={styles.dialogBox}>
          {dialogElem}
        </div>

      </Reveal>
    )
  }
}

function mapStateToProps(state) {
  return {
    inviteCodeConsume: state.roomsList.inviteCodeConsume,
    authenticated: state.auth.authenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(roomActions, dispatch),
    headerActions: bindActionCreators(headerActions, dispatch),
    dialogActions: bindActionCreators(dialogActions, dispatch)
  };
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(withRouter(JoinRoom));