import {IUserMix} from '../../../../../server/services/mixService.type';

export enum MIX_ACTIONS {
  ADD_MIX = "ADD_MIX"
}

interface IUserFeedbackMap {
  [key: string] : MIX_ACTIONS;
}

export interface ILoadMixListProps {
  mixes: Array<IUserMix>;
  onMixAction: (actionType: MIX_ACTIONS, index: number) => void,
}

export interface ILoadMixListState {
  scrollContainerHeight: number;
  userFeedback: IUserFeedbackMap;
}