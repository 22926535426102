import React from 'react';
import {Component} from 'react';

import {INVITE_CODE_CONSUME_SUCCESS} from "../reducers/room-list.reducer";
import {AUTH_TYPE_GOOGLE} from "../actions/types";
import {IConsumeInviteProps} from './consumeInvite.type';
import {IConsumeInviteState} from './consumeInvite.type';

class ConsumeInviteState<T extends IConsumeInviteProps, U extends IConsumeInviteState> extends Component<T, U> {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setState({
      initiatedRequest: false
    });
  }

  checkAndRedirect(prevProps, prevState, waitTime) {
    if(
      this.state.initiatedRequest &&
      prevProps.inviteCodeConsume.status !== this.props.inviteCodeConsume.status &&
      this.props.inviteCodeConsume.status === INVITE_CODE_CONSUME_SUCCESS
    ) {
      setTimeout(() => {
        this.redirectToInvitedRoom();
      }, waitTime);
    }
  }

  redirectToInvitedRoom() {
    const roomName = this.props.inviteCodeConsume.resolvedRoomName;
    console.log("routing to: ", roomName);

    if(this.props.onHide) {
      this.props.onHide();
    }

    this.props.history.push('/room/' + roomName);
  }

  checkAndSendRequest(inviteCode) {
    if(
      !this.state.initiatedRequest &&
      inviteCode &&
      this.props.authenticated === AUTH_TYPE_GOOGLE
    ) {
      this.setState({initiatedRequest: true});
      this.props.roomActions.consumeInviteCode(inviteCode);
    }
  }

  componentWillUnmount() {
    this.props.roomActions.resetConsumeInviteCode();
  }
}

export default ConsumeInviteState;