export const ROOM_FETCH_LIMIT = 10;
export const SEARCH_FETCH_LIMIT = 10;
export const UNINITIALIZED_LIST = [];

export const getInitialStateForRoomList = () => {
  return {
    hasMore: true,
    offset: 0,
    isLoadingMore: false,
    fetchRequestId: null,
    rooms: UNINITIALIZED_LIST
  }
};

const getUpdatedRoomList = (prevState, data, requestId, fetchLimit) => {
  if(requestId == prevState.fetchRequestId) {
    const rooms = [...(prevState.rooms), ...(data.results)];
    const offset = rooms.length;
    const hasMore = data.results.length >= fetchLimit;

    return {
      hasMore,
      offset,
      isLoadingMore: false,
      fetchRequestId: null,
      rooms
    }
  } else {
    return prevState
  }
};

export const getUpdatedFetchRoomList = (prevState, data, requestId) => {
  return getUpdatedRoomList(prevState, data, requestId, ROOM_FETCH_LIMIT);
};

export const getUpdatedSearchRoomList = (prevState, data, requestId) => {
  return getUpdatedRoomList(prevState, data, requestId, SEARCH_FETCH_LIMIT);
};

export const setLoadingForList = (prevState, payload) => {
  return {...prevState, isLoadingMore: true, fetchRequestId: payload.requestId}
};