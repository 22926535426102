import React from 'react';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Reveal} from 'react-foundation-components/lib/reveal';
import {CloseButton} from 'react-foundation-components/lib/close-button';
import {Row} from 'react-foundation-components/lib/grid';
import {Column} from 'react-foundation-components/lib/grid';
import {Button} from 'react-foundation-components/lib/button';

import * as roomActions from '../../../actions/room.actions';

import {INVITE_CODE_LOADING} from '../../../reducers/current-room.reducer';
import {INVITE_CODE_FAILURE} from '../../../reducers/current-room.reducer';
import ViewportUtil from '../../../utils/viewportUtil';

const styles = require('./_style.scss');
const loadingSpinnerStyles = require('../../../common/loading-spinner.scss');

const COPY_TRANSITION_PERIOD = 3000;

class InviteToRoom extends Component<any, any> {
  linkInputRef;

  static defaultProps = {
    show: false,
    onHide: () => {}
  };

  static propTypes = {
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showVoterCode: true,

      showCopiedStatus: false,
      showCopiedTransitionScheduler: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.show != this.props.show && this.props.show && this.props.roomId !== '' && this.props.roomVisibility !== '') {
      this.props.getInviteCode(this.props.roomId, this.props.roomVisibility);
    }
  }

  copy = () => {
    var input = this.linkInputRef;

    if (ViewportUtil.isIos()) {

      var editable = input.contentEditable;
      var readOnly = input.readOnly;

      input.contentEditable = true;
      input.readOnly = false;

      var range = document.createRange();
      range.selectNodeContents(input);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      input.setSelectionRange(0, 999999);
      input.contentEditable = editable;
      input.readOnly = readOnly;

    } else {
      input.select();
    }

    document.execCommand('copy');
    this.scheduleStatusBackToCopyTransition();
  };

  scheduleStatusBackToCopyTransition = () => {
    this.cancelStatusBackToCopyTransition();

    const scheduler = setTimeout(() => {
      this.setState({showCopiedStatus: false});
      this.cancelStatusBackToCopyTransition();
    }, COPY_TRANSITION_PERIOD);

    this.setState({
      showCopiedStatus: true,
      showCopiedTransitionScheduler: scheduler,
    });
  };

  cancelStatusBackToCopyTransition = () => {
    const transition = this.state.showCopiedTransitionScheduler;

    clearTimeout(transition);

    this.setState({showCopiedTransitionScheduler: null})
  };

  handleVoterToggleChange = () => {
    this.setState({showVoterCode: true});
  };

  handleAdminToggleChange = () => {
    this.setState({showVoterCode: false});
  };

  getContentElem(inviteCode) {
    const adminAccessTypeBtnStyle = styles.inviteAccessAdminType + ' ' +
      (
        this.state.showVoterCode ?
          '' :
          styles.selectedAccessType
      );
    const voterAccessTypeBtnStyle = styles.inviteAccessVoteOnlyType + ' ' +
      (
        this.state.showVoterCode ?
          styles.selectedAccessType :
          ''
      );

    switch(inviteCode) {
      case INVITE_CODE_LOADING:
        return (
          <div className={styles.loaderBox}>
            <div className={loadingSpinnerStyles.loadingSpinner}>
            </div>
          </div>
        );
      case INVITE_CODE_FAILURE:
        return (
          <div>
            Error loading invite code. Try again!
          </div>
        );
      default:
        return (
          <div className={styles.contentBox}>
            <h4 className={styles.inviteCodeText}>
              {inviteCode}
            </h4>

            <div className={styles.inviteAccessTypeWrap}>
              <span>Access Type</span>
              <div className={styles.inviteAccessTypeCont}>
                <div
                  className={adminAccessTypeBtnStyle}
                  onClick={this.handleAdminToggleChange}
                >
                  Admin
                </div>
                <div
                  className={voterAccessTypeBtnStyle}
                  onClick={this.handleVoterToggleChange}
                >
                  Vote only
                </div>
              </div>
            </div>

            <input
              ref={(myRef) => { this.linkInputRef = myRef }}
              readOnly={true}
              value={`${window.location.origin}/invite?code=${inviteCode}`}
              className={styles.inviteLinkWrap} />

            {
              !this.state.showCopiedStatus &&
              <Button
                className={styles.copyButton}
                onClick={this.copy}
              >
                Copy
              </Button>
            }

            {
              this.state.showCopiedStatus &&
              <span
                className={styles.copiedText}
              >
                Copied!
              </span>
            }
          </div>
        );
    }
  }

  render(){
    const inviteCode = this.state.showVoterCode ? this.props.suggesterCode : this.props.inviteCode;
    const contentElem = this.getContentElem(inviteCode);

    return (
      <Reveal
        onHide={this.props.onHide}
        show={this.props.show}
        revealClassName={styles.dialog}
        >
        <CloseButton onClick={this.props.onHide} />

        <Row>
          <Column>
            <h4 className={styles.title}>Invite your friends</h4>
          </Column>
        </Row>
        <Row>
          <Column>
            <div className={styles.contentWrap}>
              {contentElem}
            </div>
          </Column>
        </Row>
      </Reveal>
    )
  }
}

function mapStateToProps(state) {
  return {
    inviteCode: state.currentRoom.inviteCode,
    suggesterCode: state.currentRoom.suggesterCode
  };
}

export default connect(mapStateToProps, roomActions)(InviteToRoom);