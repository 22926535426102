import React, { Component } from 'react';

import {Button} from 'react-foundation-components/lib/button';

import OutsideClickAlerter from '../outside-click-alerter/OutsideClickAlerter';

const styles = require('./_styles.scss');

class DropdownBox extends Component<any, any> {
  static defaultProps = {
    isShown: false,
    arrowTipPos: 0, //in em
    className: '',
    hide: () => {}
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <OutsideClickAlerter
        onOutsideClick={this.props.hide}
        disableCheck={!this.props.isShown}>

        {
          this.props.isShown &&
          <div className={styles.dropdown + ' ' + this.props.className}>
            {this.props.children}
          </div>
        }

      </OutsideClickAlerter>
    )
  }
}

export default DropdownBox;
