import {DIALOG_SHOW} from '../actions/dialog.type';
import {DIALOG_HIDE} from '../actions/dialog.type';
import {BLUR_MAIN_CONTENT} from '../actions/dialog.type';
import {UNBLUR_MAIN_CONTENT} from '../actions/dialog.type';
import {DIALOG_REGISTER_FOR_PAGE} from '../actions/dialog.type';
import {DIALOG_TYPES} from '../actions/dialog.type';

const initialState = {
  registeredDialogs: [],
  isContentBlurred: false
};

const showHideDialogType = (state, dialogType, show, dialogData) => {
  const updatedDialogs = state.registeredDialogs.map((dialog) => {
    if(dialog.type === dialogType) {
      dialog.show = show;
      dialog.data = dialogData;
    }

    return dialog;
  });

  return { ...state, registeredDialogs: updatedDialogs };
};

const registerDialogs = (state, dialogTypes) => {
  const updatedDialogs = dialogTypes.map((dialogType) => {
    return {
      type: dialogType,
      // show: dialogType === DIALOG_TYPES.LOAD_MIX,
      show: false,
      data: {}
    };
  });

  return { ...state, registeredDialogs: updatedDialogs };
};

export default function(state = initialState, action) {
  // console.log('Dialog Reducer: ', action);

  switch(action.type) {
    case DIALOG_REGISTER_FOR_PAGE:
      return registerDialogs(state, action.payload.dialogTypes);
    case DIALOG_SHOW:
      return showHideDialogType(state, action.payload.dialogType, true, action.payload.dialogData);
    case DIALOG_HIDE:
      return showHideDialogType(state, action.payload.dialogType, false, {});
    case BLUR_MAIN_CONTENT:
      return { ...state, isContentBlurred: true };
    case UNBLUR_MAIN_CONTENT:
      return { ...state, isContentBlurred: false };
  }

  return state;
}