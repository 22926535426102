import React, { Component } from 'react';
import uuidV4 from 'uuid/v4';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import * as globalActions from '../../../actions/global.actions';
import {OUTSIDE_CLICK_STATUS} from '../../../reducers/global.reducer';

class OutsideClickAlerter extends Component<any, any> {
  wrapperRef: any;

  static defaultProps = {
    onOutsideClick: () => {},
    disableCheck: false
  };

  constructor(props) {
    super(props);

    this.state = {
      containerId: uuidV4()
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    if(!this.props.disableCheck) {
      this.addListener();
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.disableCheck != this.props.disableCheck) {
      if(this.props.disableCheck) {
        this.removeListener();
      } else {
        this.addListener();
      }
    }

    if(prevProps.containerIdsForOutsideClickAlert != this.props.containerIdsForOutsideClickAlert) {
      const containerInfo = this.props.containerIdsForOutsideClickAlert.find(
        cont => cont.containerId === this.state.containerId
      );

      if(containerInfo && containerInfo.status === OUTSIDE_CLICK_STATUS.CAUGHT) {

        this.handleClickOutside();
      }
    }
  }

  addListener = () => {
    this.props.globalActions.setOutsideClickContainerId(this.state.containerId);
  };

  removeListener = () => {
    this.props.globalActions.removeOutsideClickContainerId(this.state.containerId);
  };

  handleClickOutside = () => {
    this.props.onOutsideClick();
  };

  oldAddListener() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    document.addEventListener('touchstart', this.handleClickOutside, true);
  }

  oldRemoveListener() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    document.removeEventListener('touchstart', this.handleClickOutside, true);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  oldHandleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
      this.props.onOutsideClick();
    }
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={this.props.className} id={this.state.containerId}>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    containerIdsForOutsideClickAlert: state.global.containerIdsForOutsideClickAlert
  };
}

function mapDispatchToProps(dispatch) {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
  };
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(OutsideClickAlerter);