import * as React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';

// Import routing components
import {Route, BrowserRouter } from 'react-router-dom';

import Main from '../shared/containers/main/component';

import * as actions from '../shared/actions/auth.actions';

import reducers from '../shared/reducers';

import apiMiddleware from '../shared/middleware/api.middleware';
import wsMiddleware from '../shared/middleware/ws.middleware';
import roomMiddleware from '../shared/middleware/room.middleware';
import globalUtilMiddleware from '../shared/middleware/global-util.middleware';

//SCSS
require("../client/assets/css/reset.scss");

const createStoreWithMiddleware =
  applyMiddleware(
    reduxThunk,
    apiMiddleware,
    wsMiddleware,
    roomMiddleware,
    globalUtilMiddleware
  )(createStore);
const store = createStoreWithMiddleware(reducers);

//store.dispatch(actions.initFbSdk());
// @ts-ignore
store.dispatch(actions.initGoogleSdk());
store.dispatch(actions.checkAuth());

render(
  <Provider store={store}>
    <BrowserRouter>
      <Route component={Main}>
      </Route>
    </BrowserRouter>
  </Provider>,
  document.getElementById('main-container')
);