export const HEADER_HIDE = 'header-close';
export const HEADER_SHOW = 'header-show';
export const HEADER_MAKE_TRANSPARENT = 'header-make-transparent';
export const HEADER_MAKE_OPAQUE = 'header-make-opaque';
export const HEADER_SET_CONFIG = 'header-set-config';

export const HEADER_TYPES = {
  NONE: 'none-header',
  BASIC: 'basic-header',
  DESKTOP_ROOM: 'desktop-room-header'
};
