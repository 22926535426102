import React, {Component} from 'react'

// Foundation Components
import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';

import {FormField} from 'react-foundation-components/lib/forms';
import {FormFieldInput} from 'react-foundation-components/lib/forms';
import {FormFieldError} from 'react-foundation-components/lib/forms';

const styles = require('./_style.scss');

class InviteInput extends Component<any, any> {
  static defaultProps = {
    inviteCodePart1: '',
    inviteCodePart2: '',
    onSubmit: () => {},
    inviteCodeChangeHandler: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      inviteCodeError: ''
    }
  }

  handleInviteCodePart1Change(event) {
    const code = event.target.value;

    this.props.inviteCodeChangeHandler(code.toLowerCase(), this.props.inviteCodePart2);

    if(code.length == 4) {
      document.getElementById("invite-code-input-2").focus();
    }
  }

  handleInviteCodePart2Change(event) {
    const code = event.target.value;
    
    this.props.inviteCodeChangeHandler(this.props.inviteCodePart1, code.toLowerCase());
  }

  componentDidMount() {
    document.getElementById('invite-code-input-1').focus()
  }

  render() {
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        this.props.onSubmit();
      }}>
        <FlexRow collapse className={styles.formRow} horizontalAlignment="center">
          <FlexColumn small={6} medium={6} large={6}>
            <FormField
              className={styles.codeInputFormFieldLeft}
              error={this.state.inviteCodeError} >
              <FormFieldInput
                id="invite-code-input-1"
                value={this.props.inviteCodePart1}
                maxLength="4"
                autoComplete="off"
                className={styles.codeInput}
                autoCapitalize="none"
                onChange={this.handleInviteCodePart1Change.bind(this)} />
              <FormFieldError>Invalid code!</FormFieldError>
            </FormField>
          </FlexColumn>

          <FlexColumn small={6} medium={6} large={6}>
            <FormField
              className={styles.codeInputFormFieldRight}
              error={this.state.inviteCodeError} >
              <FormFieldInput
                id="invite-code-input-2"
                value={this.props.inviteCodePart2}
                maxLength="4"
                autoComplete="off"
                className={styles.codeInput}
                autoCapitalize="none"
                onChange={this.handleInviteCodePart2Change.bind(this)} />
              <FormFieldError>Invalid code!</FormFieldError>
            </FormField>
          </FlexColumn>
        </FlexRow>


        <input type="submit" value="submitme" className={styles.hidden}/>
      </form>
    )
  }
}

export default InviteInput