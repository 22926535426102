const MobileDetect = require('mobile-detect');
const PlatformDetector = require('platform');
const Detector = new MobileDetect(window.navigator.userAgent);

const getViewportWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

const getViewportHeight = () => {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};

const isMobileDevice = () => {
  // return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

  return Detector.mobile() !== null
};

const isIos = () => {
  return Detector.os() === 'iOS';
};

const isAndroid = () => {
  return Detector.os() === 'AndroidOS';
};

const isChrome = () => {
  return Detector.userAgent() === 'Chrome';
};

const getDevice = () => {
  console.log('DETECTOR', PlatformDetector);

  return PlatformDetector.os.family;
};

const isVideoRenderableDevice = () => {
  // let isIos = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
  // let viewPortWidth = getViewportWidth();

  return true;

  // (
  //   Detector.mobile() == null ||
  //   (
  //     Detector.os() !== 'iOS'
  //   )
  // );
};

export default {
  isVideoRenderableDevice,
  getViewportWidth,
  getViewportHeight,
  isMobileDevice,
  isIos,
  isAndroid,
  isChrome,
  getDevice
};