import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import Reveal from 'react-foundation-components/lib/reveal';
import CloseButton from 'react-foundation-components/lib/close-button';

import * as RoomActions from '../../../actions/room.actions';
import ReactionUtil from '../../../utils/reaction.util';
import {TRACK_REACTION_USER_INFO_LOADING} from "../../../reducers/current-room.reducer";
import ReactionUserScrollList from './scroll-list/component';

const styles = require('./_styles.scss');
const loadingSpinnerStyles = require('../../../common/loading-spinner.scss');

class ReactionUserList extends PureComponent<any, any> {
  static defaultProps = {
    onHide: () => {},
    show: false,
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.show !== this.props.show) {
      if(this.props.show) {
        this.props.roomActions.getTrackReactionsInfo(this.props.uid, this.props.data.trackIndex);
      } else {
        this.props.roomActions.clearGetTrackReactionsInfo();
      }
    }
  }

  renderReactions = () => {
    const countBoxes = [];
    const totalCount = this.props.userListRequest.length;

    const emotionLengthsMap = this.props.userListRequest.reduce((map, reaction) => {
      const code = reaction.code;
      const oldCount = map.has(code) ? map.get(code) : 0;

      map.set(code, oldCount + 1);

      return map;
    }, new Map());

    for (let [key, value] of emotionLengthsMap.entries()) {
      countBoxes.push(
        <div className={styles.countBox} key={key}>
          <img
            className={styles.countBoxImg}
            src={ReactionUtil.getEmoticonSrc(key)}
          />
          <span className={styles.countBoxNumber}>{value}</span>
        </div>
      );
    }

    return (
      <div className={styles.contentElem}>
        <div className={styles.countWrap}>
          <div className={styles.countBox}>
            <img
              className={styles.countBoxImg}
              src={require("../../../../client/assets/img/love-icon.png")}
            />
            <span className={styles.countBoxNumber}>{totalCount}</span>
          </div>

          {countBoxes}
        </div>

        <ReactionUserScrollList
          reactionList={this.props.userListRequest}
        />
      </div>
    );
  };

  renderLoader = () => {
    return (
      <div className={styles.contentElem}>
        <div className={loadingSpinnerStyles.loadingSpinner}>
        </div>
      </div>
    );
  };

  renderEmpty = () => {
    return (
      <div className={styles.noReactions}>
        There are no reactions for this track
      </div>
    );
  };

  render() {
    let contentElem;

    if(this.props.userListRequest === TRACK_REACTION_USER_INFO_LOADING) {
      contentElem = this.renderLoader();
    } else if (this.props.userListRequest.length === 0) {
      contentElem = this.renderEmpty();
    } else {
      contentElem = this.renderReactions();
    }

    return (
      <Reveal
        revealClassName={styles.dialog}
        onHide={this.props.onHide}
        show={this.props.show} >
        <CloseButton
          onClick={this.props.onHide} />


        {contentElem}
      </Reveal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userListRequest: state.currentRoom.trackReactionsUserListRequest,
    uid: state.currentRoom.uid,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    roomActions: bindActionCreators(RoomActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactionUserList);
