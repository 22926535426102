import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

import {
  ILoadMixProps,
  ILoadMixState,
  ILoadMixRouterProps,
  ILoadMixStateFromProps,
  ILoadMixDispatchFromProps
} from "./loadMix.type";

import * as UserActions from "../../../actions/current-user.actions";
import * as RoomActions from "../../../actions/room.actions";

// Foundation Components
import {Row as FlexRow} from 'react-foundation-components/lib/grid-flex';
import {Column as FlexColumn} from 'react-foundation-components/lib/grid-flex';
import {Button} from 'react-foundation-components/lib/button';
import Reveal from 'react-foundation-components/lib/reveal';
import CloseButton from 'react-foundation-components/lib/close-button';
import {bindActionCreators} from "redux";
import {USER_MIXES_LOADING} from '../../../reducers/current-user.reducer';

const styles = require('./_style.scss');
const loadingSpinnerStyles = require('../../../common/loading-spinner.scss');
import MixScrollList from './list/mixScrollList.component';
import {MIX_ACTIONS} from './list/mixScrollList.type';

class LoadMixDialog extends Component<ILoadMixProps, ILoadMixState> {
  constructor(props) {
    super(props)
  };

  onLoadMixClicked = (actionType: MIX_ACTIONS, mixIndex: number) => {
    this.props.roomActions.loadMix(
      this.props.roomName,
      this.props.currentUsersMixes[mixIndex].uid,
    )
  };

  componentDidUpdate(prevProps, prevState) {
    if(this.props.authenticated !== prevProps.authenticated
    || this.props.currentUserDfId !== prevProps.currentUserDfId)
      this.props.userActions.getUsersMixes(this.props.currentUserDfId)
  }

  componentDidMount() {
    this.props.userActions.getUsersMixes(this.props.currentUserDfId)
  }

  renderMixList = () => {
    return (
      <MixScrollList
        mixes={this.props.currentUsersMixes}
        onMixAction={this.onLoadMixClicked}
      />
    );
  };

  renderLoader = () => {
    return (
      <div className={loadingSpinnerStyles.loadingSpinner}>
      </div>
    );
  };

  renderEmpty = () => {
    return (
      <div className={styles.noMixes}>
        There are no mixes available.
      </div>
    );
  };

  render() {
    let contentElem;

    if(this.props.currentUsersMixes === USER_MIXES_LOADING) {
      contentElem = this.renderLoader();
    } else if (this.props.currentUsersMixes.length === 0) {
      contentElem = this.renderEmpty();
    } else {
      contentElem = this.renderMixList();
    }

    return (
      <Reveal
        revealClassName={styles.exportMixDialog}
        onHide={this.props.onHide}
        show={this.props.show} >
        <CloseButton
          onClick={this.props.onHide} />

        <h2 className={styles.head}>Load Mix</h2>

        <div className={styles.content}>
          {contentElem}
        </div>
      </Reveal>
    )
  }
};

function mapStateToProps(state) : ILoadMixStateFromProps {
  return {
    currentUsersMixes: state.currentUser.userMixes,
    currentUserDfId: state.auth.dfId,
    authenticated: state.auth.authenticated,
    roomName: state.currentRoom.roomName,
  }
}

function mapDispatchToProps(dispatch) : ILoadMixDispatchFromProps{
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    roomActions: bindActionCreators(RoomActions, dispatch)
  }
}

// export default connect<ILoadMixStateFromProps, any, ILoadMixRouterProps>(mapStateToProps, mapDispatchToProps)(withRouter<ILoadMixProps>(LoadMixDialog));
export default connect<ILoadMixStateFromProps, any, ILoadMixRouterProps>(mapStateToProps, mapDispatchToProps)(LoadMixDialog);