// Events sent by client
const CLIENT_CONNECT = "connect";
const CLIENT_DISCONNECT = "disconnect";
const CLIENT_QUEUE_TRACK = "client-queue-track";
const CLIENT_QUEUE_NEXT_TRACK_AND_PLAY = "client-queue-next-track-and-play";
const CLIENT_QUEUE_NEXT_TRACK = "client-queue-next-track";
const CLIENT_PLAY_NEXT_TRACK = "client-play-next-track";
const CLIENT_CLEAR_TRACKS = "client-clear-tracks";
const CLIENT_UPDATE_CURRENT_TRACK = "client-update-current-track";
const CLIENT_REMOVE_TRACK = "client-remove-track";
const CLIENT_REMOVE_TRACK_SUGGESTION_RESULTS = "client-remove-track-suggestion-results";
const CLIENT_UPVOTE_TRACK = "client-upvote-track";
const CLIENT_DOWNVOTE_TRACK = "client-downvote-track";
const CLIENT_CLOSE_ROOM = "client-close-room";
const CLIENT_UPDATE_ROOM_PROPS = "client-update-room-props";
const CLIENT_PAUSE_ROOM_PLAYBACK = "client-pause-room-playback";
const CLIENT_RESUME_ROOM_PLAYBACK = "client-resume-room-playback";
const CLIENT_CURRENT_TRACK_PLAYBACK_INFO = "client-current-track-playback-info";
const CLIENT_SEEK_AT = 'client-seek-at';
const CLIENT_SET_VOLUME = 'client-set-volume';
const CLIENT_ROOM_LOGIN = "client-room-login";
const CLIENT_ROOM_LOGOUT = "client-room-logout";
const CLIENT_LOGIN = "client-login";
const CLIENT_ADD_REACTION = 'client-add-reaction';
const CLIENT_REMOVE_REACTION = 'client-remove-reaction';
const CLIENT_READ_NOTIFICATION = 'client-read-notification';
const CLIENT_BROADCAST_RESYNC = 'client-broadcast-resync';
const CLIENT_NOTIFY_TRACK_PLAYBACK_STARTED = 'CLIENT_NOTIFY_TRACK_PLAYBACK_STARTED';

// Events sent by server
const SERVER_ON_MESSAGE = 'server-on-message';
const SERVER_ROOM_ACCESS_ERROR = "server-room-access-error";
const SERVER_LOGIN_SUCCESS = "server-login-success";
const SERVER_LOGIN_FAILURE = "server-login-failure";
const SERVER_JOIN_ROOM_SUCCESS = "server-join-room-success";
const SERVER_JOIN_ROOM_FAILURE = "server-join-room-failure";
const SERVER_QUEUE_TRACK = "server-queue-track";
const SERVER_QUEUE_NEXT_TRACK_AND_PLAY = "server-queue-next-track-and-play";
const SERVER_QUEUE_NEXT_TRACK = "server-queue-next-track";
const SERVER_UPVOTE_TRACK = "server-upvote-track";
const SERVER_DOWNVOTE_TRACK = "server-downvote-track";
const SERVER_CLEAR_TRACKS = "server-clear-tracks";
const SERVER_PLAY_NEXT = "server-play-next";
const SERVER_REMOVE_TRACK = "server-remove-track";
const SERVER_REMOVE_TRACK_SUGGESTION_RESULTS = "server-remove-track-suggestion-results";
const SERVER_CURRENT_TRACK_UPDATED = "server-current-track-updated";
const SERVER_CLOSE_ROOM = "server-close-room";
const SERVER_USER_ONLINE = "server-user-online";
const SERVER_USER_OFFLINE = "server-user-offline";
const SERVER_PAUSE_ROOM_PLAYBACK = "server-pause-room-playback";
const SERVER_RESUME_ROOM_PLAYBACK = "server-resume-room-playback";
const SERVER_ROOM_PROPS_UPDATED = "server-room-props-updated";
const SERVER_CURRENT_TRACK_PLAYBACK_INFO = "server-current-track-playback-info";
const SERVER_SEEK_AT = 'server-seek-at';
const SERVER_SET_VOLUME = 'server-set-volume';
const SERVER_NOTIFICATION = 'server-notification';
const SERVER_ADD_REACTION = 'server-add-reaction';
const SERVER_REMOVE_REACTION = 'server-remove-reaction';
const SERVER_READ_NOTIFICATION = 'server-read-notification';

export const WsEvents = {
  CLIENT_CONNECT,
  CLIENT_DISCONNECT,

  CLIENT_QUEUE_TRACK,
  CLIENT_QUEUE_NEXT_TRACK_AND_PLAY,
  CLIENT_QUEUE_NEXT_TRACK,
  CLIENT_CLEAR_TRACKS,
  CLIENT_PLAY_NEXT_TRACK,
  CLIENT_REMOVE_TRACK,
  CLIENT_REMOVE_TRACK_SUGGESTION_RESULTS,
  CLIENT_UPVOTE_TRACK,
  CLIENT_DOWNVOTE_TRACK,
  CLIENT_ROOM_LOGIN,
  CLIENT_ROOM_LOGOUT,
  CLIENT_LOGIN,
  CLIENT_CLOSE_ROOM,
  CLIENT_UPDATE_ROOM_PROPS,
  CLIENT_UPDATE_CURRENT_TRACK,
  CLIENT_PAUSE_ROOM_PLAYBACK,
  CLIENT_RESUME_ROOM_PLAYBACK,
  CLIENT_CURRENT_TRACK_PLAYBACK_INFO,
  CLIENT_SEEK_AT,
  CLIENT_SET_VOLUME,
  CLIENT_ADD_REACTION,
  CLIENT_REMOVE_REACTION,
  CLIENT_READ_NOTIFICATION,
  CLIENT_BROADCAST_RESYNC,
  CLIENT_NOTIFY_TRACK_PLAYBACK_STARTED,

  SERVER_ROOM_ACCESS_ERROR,
  SERVER_LOGIN_SUCCESS,
  SERVER_LOGIN_FAILURE,
  SERVER_ON_MESSAGE,
  SERVER_JOIN_ROOM_SUCCESS,
  SERVER_JOIN_ROOM_FAILURE,
  SERVER_QUEUE_TRACK,
  SERVER_QUEUE_NEXT_TRACK_AND_PLAY,
  SERVER_QUEUE_NEXT_TRACK,
  SERVER_UPVOTE_TRACK,
  SERVER_DOWNVOTE_TRACK,
  SERVER_CLEAR_TRACKS,
  SERVER_PLAY_NEXT,
  SERVER_REMOVE_TRACK,
  SERVER_REMOVE_TRACK_SUGGESTION_RESULTS,
  SERVER_CLOSE_ROOM,
  SERVER_ROOM_PROPS_UPDATED,
  SERVER_CURRENT_TRACK_UPDATED,
  SERVER_USER_ONLINE,
  SERVER_USER_OFFLINE,
  SERVER_PAUSE_ROOM_PLAYBACK,
  SERVER_RESUME_ROOM_PLAYBACK,
  SERVER_CURRENT_TRACK_PLAYBACK_INFO,
  SERVER_SEEK_AT,
  SERVER_SET_VOLUME,
  SERVER_NOTIFICATION,
  SERVER_ADD_REACTION,
  SERVER_REMOVE_REACTION,
  SERVER_READ_NOTIFICATION
};