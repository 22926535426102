import React, { PureComponent } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Reveal} from 'react-foundation-components/lib/reveal';
import {Button} from 'react-foundation-components/lib/button';

const styles = require('./_styles.scss');

import * as PrefActions from '../../../../actions/preferences.actions';
import {PREF_KEY} from '../../../../actions/preferences.types';

/*
  Generic tutorial dialog component for desktop
 */
class TutorialDesktopDialog extends PureComponent<any, any> {
  static defaultProps = {
    show: true,
    onHide: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleClick = () => {
    this.props.onHide();
    this.props.prefActions.setPref(
      PREF_KEY.TUTORIAL_DESKTOP_ONBOARDING,
      this.props.tutorialOnboardingDialogSeenCount - 1
    );
  };

  render(){
    return (
      <Reveal
        show={this.props.show}
        revealClassName={styles.dialog}
        overlayClassName={styles.dialogOverlay}
      >
        <div className={styles.carousel}>
          <div className={styles.carousel_item}>
            <img
              className={styles.carousel_itemImg}
              src={require('../../../../../client/assets/img/tutorial/dekstop-panes-400-opt.gif')}
            />
            <h3 className={styles.carousel_itemHead}>Move mouse to left or right</h3>
            <p className={styles.carousel_itemDesc}>
              Playlist can be accessed by moving your mouse pointer to left, while you can add new track by
              moving your mouse pointer to right.
            </p>
          </div>
        </div>
        <Button
          className={styles.submitBtn}
          onClick={this.handleClick}
        >
          Got it!
        </Button>
      </Reveal>
    );
  }
}

function mapStateToProps(state) {
  return {
    tutorialOnboardingDialogSeenCount: state.prefs[PREF_KEY.TUTORIAL_DESKTOP_ONBOARDING],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    prefActions: bindActionCreators(PrefActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialDesktopDialog);