import {SEARCH_TRACKS} from '../actions/search.types';
import {SEARCH_TRACKS_CLEAR} from '../actions/search.types';
import {SEARCH_CLEAR_ALL_LISTS} from '../actions/search.types';
import {SEARCH_FETCH_PRIVATE_LIST} from '../actions/search.types';
import {SEARCH_FETCH_PRIVATE_LIST_IN_PROGRESS} from '../actions/search.types';
import {SEARCH_FETCH_PRIVATE_LIST_ERROR} from '../actions/search.types';
import {SEARCH_FETCH_PUBLIC_LIST} from '../actions/search.types';
import {SEARCH_FETCH_PUBLIC_LIST_IN_PROGRESS} from '../actions/search.types';
import {SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST} from '../actions/search.types';
import {SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS} from '../actions/search.types';
import {getInitialStateForRoomList} from "./helper/room-list.helper";
import {setLoadingForList} from "./helper/room-list.helper";
import {getUpdatedSearchRoomList} from "./helper/room-list.helper";
import {SEARCH_TRACKS_IN_PROGRESS} from "../actions/search.types";

export const SEARCH_TRACKS_LOADING = [];

const initialState = {
  // Search tracks
  tracks: [],

  // Search Rooms
  allPrivate: getInitialStateForRoomList(),
  // allSolo: getInitialStateForRoomList(),
  allPublic: getInitialStateForRoomList(),
  allAccessiblePublic: getInitialStateForRoomList(),
};

export default function(state = initialState, action) {
  switch(action.type) {
    case SEARCH_TRACKS_IN_PROGRESS:
      console.log('Search Reducer: ', action);
      return {
        ...state,
        tracks: SEARCH_TRACKS_LOADING
      };
    case SEARCH_TRACKS:
      console.log('Search Reducer: ', action);
      return {
        ...state,
        tracks: action.response.data.results
      };
    case SEARCH_TRACKS_CLEAR:
      console.log('Search Reducer: ', action);
      return {
        ...state,
        tracks: []
      };

    case SEARCH_CLEAR_ALL_LISTS:
      return {
        ...state,
        allPrivate: getInitialStateForRoomList(),
        allPublic: getInitialStateForRoomList(),
        allAccessiblePublic: getInitialStateForRoomList(),
        // allSolo: getInitialStateForRoomList()
      };

    //Room list fetch in progress
    case SEARCH_FETCH_PRIVATE_LIST_IN_PROGRESS:
      return {
        ...state,
        allPrivate: setLoadingForList(state.allPrivate, action.payload)
      };
    case SEARCH_FETCH_PUBLIC_LIST_IN_PROGRESS:
      return {
        ...state,
        allPublic: setLoadingForList(state.allPublic, action.payload)
      };
    case SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST_IN_PROGRESS:
      return {
        ...state,
        allAccessiblePublic: setLoadingForList(state.allAccessiblePublic, action.payload)
      };
    // case SEARCH_FETCH_SOLO_LIST_IN_PROGRESS:
    //   return {
    //     ...state,
    //     allSolo: setLoadingForList(state.allSolo, action.payload)
    //   };

    //Room list fetch complete
    case SEARCH_FETCH_PRIVATE_LIST:
      return {
        ...state,
        allPrivate: getUpdatedSearchRoomList(state.allPrivate, action.response.data, action.requestId)
      };
    case SEARCH_FETCH_PUBLIC_LIST:
      return {
        ...state,
        allPublic: getUpdatedSearchRoomList(state.allPublic, action.response.data, action.requestId)
      };
    case SEARCH_FETCH_PUBLIC_ACCESSIBLE_LIST:
      return {
        ...state,
        allAccessiblePublic: getUpdatedSearchRoomList(state.allAccessiblePublic, action.response.data, action.requestId)
      };
    // case SEARCH_FETCH_SOLO_LIST:
    //   return {
    //     ...state,
    //     allSolo: getUpdatedSearchRoomList(state.allSolo, action.response.data, action.requestId)
    //   };

    //Room list fetch error
    case SEARCH_FETCH_PRIVATE_LIST_ERROR:
      return {
        ...state,
        allPrivate: getUpdatedSearchRoomList(state.allPrivate, {results: []}, action.requestId)
      };
  }

  return state;
}