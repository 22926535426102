/**
 * Created by anirudh on 29/10/17.
 */

import {WsEvents} from '../constants/ws.events';
import * as WsActions from './ws.actions';
import {READ_NOTIFICATION} from './notification.types';
import {FETCH_USER_NOTI} from './notification.types'
import {FETCH_USER_NOTI_ERROR} from './notification.types'
import {FETCH_USER_NOTI_IN_PROGRESS} from './notification.types'
import {CALL_API} from './types';

export function fetchUserNotifications(offset=0, limit=10) {
  return(dispatch, getState) => {

    const areNotificationsLoading = getState().notifications.areNotificationsLoading;
    const offset = getState().notifications.offset;

    if(!areNotificationsLoading) {
      const params = {
        offset,
        limit
      };

      let requestConfig = {
        url: process.env.API_URL+'/notification/',
        params
      };

      dispatch({
                 type: CALL_API,
                 payload: {
                   requestConfig: requestConfig,
                   authenticated: true,
                   types: [ FETCH_USER_NOTI_IN_PROGRESS, FETCH_USER_NOTI, FETCH_USER_NOTI_ERROR ]
                 }
               })
    }
  }
}

export function markNotificationAsRead(notificationId) {
  return {
    type: READ_NOTIFICATION,
    payload: {
      notificationId
    }
  }
}

export function emitNotificationRead(notificationId) {
  return(dispatch, getState) => {
    dispatch(markNotificationAsRead(notificationId));
    dispatch(WsActions.sendAuthenticated({
       eventType: WsEvents.CLIENT_READ_NOTIFICATION,
       eventData: {
         notificationId
       }
     }))
  };
}