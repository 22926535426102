import {PREFS_SET} from "./preferences.types";

export function setPref(prefKey, prefVal) {
  return {
    type: PREFS_SET,
    payload: {
      prefKey,
      prefVal
    }
  }
}
