import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {TitleBar} from 'react-foundation-components/lib/title-bar';
import {TitleBarItem} from 'react-foundation-components/lib/title-bar';
import {TitleBarTitle} from 'react-foundation-components/lib/title-bar';
import {TitleBarMenuIcon} from 'react-foundation-components/lib/title-bar';
import {Button} from 'react-foundation-components/lib/button';
import {Menu} from 'react-foundation-components/lib/menu';
import {MenuItem} from 'react-foundation-components/lib/menu';
import {HideForScreenSize, ShowForScreenSize} from 'react-foundation-components/lib/visibility';
import {Link } from 'react-router-dom';

import HeaderAuth from '../../header_auth/header_auth.component';
import {DIALOG_TYPES} from '../../../actions/dialog.type';

import * as dialogActions from '../../../actions/dialog.actions';

const globalStyles = require('../../../../client/assets/css/global.scss');
const styles = require('./_style.scss');

class BasicHeader extends Component<any, any> {

  static defaultProps = {
    onToggleOffCanvas: () => {}
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.dialogActions.registerDialogs([
      DIALOG_TYPES.CREATE_ROOM,
      DIALOG_TYPES.JOIN_ROOM,
      DIALOG_TYPES.FB_LOGIN
    ]);
  }

  handleToggleRight = () => {
    this.props.onToggleOffCanvas('right');
  };

  render() {
    const titleBarStyle = styles.title_bar + ' ' +
      (
        this.props.isHeaderHidden ?
          styles.title_bar__hidden :
          ''
      ) + ' ' +
      (
        this.props.isHeaderTransparent ?
          styles.title_bar__transparent :
          ''
      );
    const desktopMenuItemStyle = styles.titleBarDesktopMenuItem + ' ' +
          styles.titleBarDesktopMenuItem__dark

    return(
      <TitleBar className={titleBarStyle}>
        <div className={globalStyles.wrap}>
          <TitleBarItem position="left">
            <TitleBarTitle className={styles.title_bar_title}>
              <Link to="/">
                <img
                  className={styles.title_bar_logoIcon}
                  src={require('../../../../client/assets/img/logo-icon.png')}
                />
                <img
                  className={styles.title_bar_logoText}
                  src={require('../../../../client/assets/img/logo.png')}
                />
              </Link>
            </TitleBarTitle>
          </TitleBarItem>
          <TitleBarItem position="right">
            <HeaderAuth
              isButtonTransparent={false}
            />
          </TitleBarItem>
        </div>
      </TitleBar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dialogActions: bindActionCreators(dialogActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(BasicHeader);
