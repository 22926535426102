import ViewportUtil from '../../utils/viewportUtil';

const noPlayers = (
  ViewportUtil.isMobileDevice() &&
  ViewportUtil.isIos()
) ?
  ["yt1"] :
  ["yt1", "yt2"];

export const RoomStatics = {
  noPlayers: noPlayers,
  heightWidthRatio: 480/854,
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    playsinline: 1,
    fs: 0,
    rel: 0,
    showinfo: 0,
    controls: 0,
    modestbranding: 1,
    iv_load_policy: 3
  },
  socketStates: {
    DISCONNECTED: 'DISCONNECTED',
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    LOGGINGIN: 'LOGGINGIN',
    LOGGEDIN: 'LOGGEDIN',
    SERVERERROR: 'SERVERERROR',
    UNAUTHORISED: 'UNAUTHORISED',
    INVALIDROOMNAME: 'INVALIDROOMNAME',
    NONE: 8
  },
  playbackEvents: {
    QUEUE: 0,
    QUEUENEXT: 1,
    PLAYNEXT: 2,
    ADDANDPLAY: 3,
    CLEAR: 4,
    FADING: 5,
    FADECOMPLETE: 6,
    REMOVED: 7,
    NONE: 8
  },
  states: {
    PLAYERS_NOT_READY: 'PLAYERS_NOT_READY',
    TRACK_WAITING_FOR_TAP_TO_START: 'TRACK_WAITING_FOR_TAP_TO_START',
    TRACK_PLAYING: 'TRACK_PLAYING',
    TRACK_ENDED: 'TRACK_ENDED',
    TRACK_PAUSED: 'TRACK_PAUSED',
    TRACK_PAUSED_BY_USER: 'TRACK_PAUSED_BY_USER',
    TRACK_CROSS_TRANSITION: 'TRACK_CROSS_TRANSITION',
    TRACK_DIRECT_TRANSITION: 'TRACK_DIRECT_TRANSITION',
    TRACK_WAITING_FOR_TRANSITION: 'TRACK_WAITING_FOR_TRANSITION',
    TRACK_PAUSED_AND_WAITING_FOR_TRANSITION: 'TRACK_PAUSED_AND_WAITING_FOR_TRANSITION',
    TRACK_TRANSITION_COMPLETE: 'TRACK_TRANSITION_COMPLETE',
    TRACK_EMPTY_LIST: 'TRACK_EMPTY_LIST',
    CLOSED_BY_REQUEST: 'CLOSED_BY_REQUEST',
    CLOSED_BY_EVENT: 'CLOSED_BY_EVENT'
  }
};